<div class="gp-page-header">
    <div class="gp-container gp-container-extra-large">
        <breadcrumb></breadcrumb>
        <div class="gp-page-header-container">
            <h1 class="gp-page-header-title">{{ "DATASET_LIST.TITLE" | translate }}</h1>
            <div class="gp-page-header-actions">
                <div class="btn-group">
                    <button class="btn btn-default btn-back" (click)="back()">
                        {{ "NAVIGATION.BACK" | translate }}
                    </button>
                    <gp-button-primary
                        (click)="edit()"
                        [isDisabled]="isSelectedItemModifiable()"
                        translation="DATASET_LIST.BUTTON.SHOW"
                    ></gp-button-primary>
                </div>
                @if (currentIgCheckExecution) {
                    <div>
                        <gp-spinner [visible]="true"></gp-spinner>
                        {{ "MESSAGE.CHECK" | translate }}
                    </div>
                }
                @if (downloadingGPKG) {
                    <div>
                        <gp-spinner [visible]="true"></gp-spinner>
                        {{ "MESSAGE.DOWNLOAD" | translate }}
                    </div>
                }
            </div>
        </div>
    </div>
</div>
<div class="gp-container gp-container-extra-large">
    <dx-data-grid
        class="hide-overflow w-100"
        [height]="gridHeight"
        [showBorders]="true"
        [columnAutoWidth]="true"
        [allowColumnReordering]="true"
        [allowColumnResizing]="true"
        columnResizingMode="widget"
        [rowAlternationEnabled]="true"
        (onRowDblClick)="onRowDblClick($event)"
        (onContentReady)="onContentReady($event)"
        [columnChooser]="columnChooserOptions"
        (onEditorPreparing)="onEditorPreparing($event)"
        (onCellPrepared)="onCellPrepared($event)"
    >
        <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-sorting mode="multiple" [showSortIndexes]="true"></dxo-sorting>
        <dxo-selection showCheckBoxesMode="never" mode="single"></dxo-selection>
        <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
        <dxo-state-storing [enabled]="true" type="custom" [customLoad]="loadState" [customSave]="saveState" [savingTimeout]="250"></dxo-state-storing>
        <dxo-toolbar>
            <dxi-item>
                <dx-button
                    (onClick)="toggleColumnChooser()"
                    [type]="hasHiddenColumns() ? 'danger' : 'normal'"
                    icon="column-chooser"
                    [hint]="getDevExpressTranslation('dxDataGrid-columnChooserTitle')"
                ></dx-button>
            </dxi-item>
            @if (isAdmin) {
                <dxi-item>
                    <dx-button (onClick)="resetGridSettings()" type="normal" icon="revert" [hint]="'GRID.REVERT_SETTINGS' | translate"></dx-button>
                </dxi-item>
            }
        </dxo-toolbar>
        @for (column of dataGridColumns; track column) {
            <dxi-column
                [dataField]="column.dataField"
                [caption]="column.caption"
                [visible]="column.visible"
                [width]="column.width"
                [sortOrder]="column.sortOrder"
                [dataType]="column.dataType"
                [format]="column.format"
                [cellTemplate]="column.cellTemplate"
                [alignment]="column.alignment"
                [headerFilter]="column.headerFilter"
                [showInColumnChooser]="column.showInColumnChooser"
                [lookup]="column.lookup"
                [allowHeaderFiltering]="column.allowHeaderFiltering"
                [allowFiltering]="column.allowFiltering"
            >
                @if (
                    column.dataField === "technicalcheck" || column.dataField === "extendedTechnicalCheckStatus" || column.dataField === "expertcheck"
                ) {
                    <dxo-lookup valueExpr="text" displayExpr="value" [dataSource]="column.dataSource"> </dxo-lookup>
                }
            </dxi-column>
        }
        <div *dxTemplate="let cell of 'statusCellTemplate'">
            <span [class]="evaluateStyleClasses(cell.data)" [title]="addCustomTooltip(cell.data)"> </span>
        </div>
    </dx-data-grid>
    <div class="gp-margin-top-big flex-start-container grid-controls">
        <div>
            <button class="btn btn-default" (click)="showProtocol()">
                {{ "DATASET_LIST.BUTTON.PROTOCOL" | translate }}
            </button>
            <button class="btn btn-default" (click)="download()" [disabled]="isDownloadSelectedItemLocked()">
                {{ "DATASET_LIST.BUTTON.DOWNLOAD" | translate }}
            </button>
        </div>
        <div>
            <button class="btn btn-default" (click)="checkin()" [disabled]="isCheckInLocked()">
                {{ "DATASET_LIST.BUTTON.CHECKIN" | translate }}
            </button>
            <button class="btn btn-default" (click)="checkout()" [disabled]="isCheckoutLocked()" title="{{ checkoutmessage }}">
                {{ "DATASET_LIST.BUTTON.CHECKOUT" | translate }}
            </button>
        </div>
    </div>
</div>
