import { Injectable } from "@angular/core";
import { MyGeoportalHeaderExtension } from "@geolib/geoappbase-client";
import { LoginPanelComponent } from "./login-panel.component";

@Injectable({ providedIn: "root" })
export class MyGeoportalLoginPanelExtension extends MyGeoportalHeaderExtension<LoginPanelComponent> {

    public isEnabled(): boolean {
        return true;
    }

    public get component(): typeof LoginPanelComponent {
        return LoginPanelComponent;
    }
}
