import { Injectable } from "@angular/core";


const DONT_KNOW_WHY_THIS_IS_NEEDED = 80;
const DONT_KNOW_WHY_THIS_IS_NEEDED_WITH_CONTROLS = 95;

@Injectable({ providedIn: "root" })
export default class GridService {

    public calculateGridHeight(element: HTMLElement, hasGridControls: boolean = false): number {
        return this.getHeightOrZero(element.closest<HTMLElement>("content")) -
            this.getHeightOrZero(element.querySelector<HTMLElement>(".grid-controls")) -
            this.getHeightOrZero(element.querySelector<HTMLElement>(".gp-page-header")) - 10 - this.getCssIsMagicHeightToSubtract(hasGridControls);

    }

    private getCssIsMagicHeightToSubtract(hasGridControls: boolean = false): number {
        return hasGridControls ? DONT_KNOW_WHY_THIS_IS_NEEDED_WITH_CONTROLS : DONT_KNOW_WHY_THIS_IS_NEEDED;
    }

    private getHeightOrZero(element?: HTMLElement | null): number {
        return element?.offsetHeight || 0;
    }

}
