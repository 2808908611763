import { Injectable } from "@angular/core";
import GeobusNavigationExtension from "./geobus-navigation.extension";


@Injectable({ providedIn: "root" })
export default class GeobusTrackingExtension extends GeobusNavigationExtension {

    public get category(): string {
        return "general";
    }

    public get state(): { name: string } {
        return { name: "app.trackings" };
    }

    public get label(): string {
        return "SIDE_MENU.GENERAL.TRACKINGS";
    }

    public get icon(): string {
        return "object-list";
    }

}
