export default interface IModel {
    name: string;
    version: string;
    modelFileId: string | null;
    topicId: string;
    guidelinesFileId: string | null;
    fromdate?: Date;
    isSiaModel: boolean;
    canModify: boolean;
    hasVisualization?: boolean;
    mapId?: number | null;
    hasExtendedTechnicalCheck: boolean;
    hasGeoportalPublication: boolean;
    hasOerebPublication: boolean;
    format: string;
    interlisFormat: string | null;
    datastockId: number | null;
    cantonId: string;
    restrictedFields: readonly string[];
}

export const restrictedFields = Object.freeze([
    "hasVisualization",
    "mapId",
    "hasGeoportalPublication",
    "datastockId",
    "hasOerebPublication"
]);
