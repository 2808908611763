import { Injectable } from "@angular/core";
import { MyGeoportalNavigationCategoryExtension } from "@geolib/geoappbase-client";

@Injectable({ providedIn: "root" })
export default class GeobusGeneralExtension extends MyGeoportalNavigationCategoryExtension {

    public isEnabled(): boolean {
        return true;
    }

    public get name(): string {
        return "general";
    }

    public get label(): string {
        return "SIDE_MENU.GENERAL.CATEGORY";
    }

}
