import { Injectable } from "@angular/core";
import GeobusNavigationExtension from "./geobus-navigation.extension";


@Injectable({ providedIn: "root" })
export default class GeobusFTPServerExtension extends GeobusNavigationExtension {

    public get category(): string {
        return "data";
    }

    public get state(): { name: string } {
        return { name: "app.ftpServer" };
    }

    public get label(): string {
        return "SIDE_MENU.DATA.FTP_SERVER";
    }

    public get icon(): string {
        return "binder";
    }

}
