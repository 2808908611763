import { Component, Input } from "@angular/core";
import { NotificationService, StateService } from "@geolib/geolib-client";
import { IdentityService } from "../account/IdentityService";
import { AuthenticationService } from "../account/AuthenticationService";


@Component({
    selector: "geobus-login",
    templateUrl: "./login.html",
})
export default class LoginController {

    @Input() public extensionData: unknown;

    public username: string;
    public password: string;
    public rememberme: boolean;
    public loggedIn: boolean;

    public constructor(
        private readonly identityService: IdentityService,
        private readonly notificationService: NotificationService,
        private readonly authenticationService: AuthenticationService,
        private readonly stateService: StateService,
    ) {
        this.username = "";
        this.password = "";
        this.rememberme = true;
        this.loggedIn = false;

        this.authenticationService.getUser().subscribe(() => {
            this.stateService.go("app.main");
        });
    }

    public isAuthenticated(): boolean {
        return this.identityService.isAuthenticated();
    }

    public login(): void {
        this.authenticationService.authenticateUser(this.username, this.password, this.rememberme).subscribe({
            next: () => {
                this.notificationService.notify("Erfolgreich angemeldet");
                this.stateService.go("app.main");
                this.loggedIn = true;
            },
            error: () => {
                this.notificationService.error("Anmeldung fehlgeschlagen");
            },
        });
    }
}
