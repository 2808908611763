<div class="gp-page-header">
    <div class="gp-container gp-container-extra-large">
        <breadcrumb></breadcrumb>
        <div class="gp-page-header-container">
            <h1 class="gp-page-header-title">{{ "SIDE_MENU.DATA.COMMUNITY" | translate }}</h1>
            <div class="gp-page-header-actions">
                <gp-button-primary (click)="edit()" [isDisabled]="!hasSelectedItem()" translation="GEMEINDE.EDIT"></gp-button-primary>
            </div>
        </div>
    </div>
</div>
<div class="gp-container gp-container-extra-large">
    <dx-data-grid
        class="hide-overflow w-100"
        [height]="gridHeight"
        [showBorders]="true"
        [allowColumnReordering]="true"
        [allowColumnResizing]="true"
        columnResizingMode="widget"
        [rowAlternationEnabled]="true"
        (onRowDblClick)="onRowDblClick()"
        (onContentReady)="onContentReady($event)"
        [columnChooser]="columnChooserOptions"
        (onEditorPreparing)="onEditorPreparing($event)"
    >
        <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-sorting mode="multiple" [showSortIndexes]="true"></dxo-sorting>
        <dxo-selection showCheckBoxesMode="never" mode="single"></dxo-selection>
        <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
        <dxo-state-storing [enabled]="true" type="custom" [customLoad]="loadState" [customSave]="saveState" [savingTimeout]="250"></dxo-state-storing>
        <dxo-toolbar>
            <dxi-item>
                <dx-button
                    (onClick)="toggleColumnChooser()"
                    [type]="hasHiddenColumns() ? 'danger' : 'normal'"
                    icon="column-chooser"
                    [hint]="getDevExpressTranslation('dxDataGrid-columnChooserTitle')"
                ></dx-button>
            </dxi-item>
            @if (isAdmin) {
                <dxi-item>
                    <dx-button (onClick)="resetGridSettings()" type="normal" icon="revert" [hint]="'GRID.REVERT_SETTINGS' | translate"></dx-button>
                </dxi-item>
            }
        </dxo-toolbar>
        @for (column of dataGridColumns; track column) {
            <dxi-column
                [dataField]="column.dataField"
                [caption]="column.caption"
                [visible]="column.visible"
                [width]="column.width"
                [sortOrder]="column.sortOrder"
            ></dxi-column>
        }
    </dx-data-grid>
    <div class="gp-margin-top-big flex-start-container float-right grid-controls">
        <div>
            <button class="btn btn-default" (click)="delete()" [disabled]="!hasSelectedItem()">
                {{ "GEMEINDE.DELETE" | translate }}
            </button>
            <button class="btn btn-default" (click)="create()">
                {{ "GEMEINDE.CREATE" | translate }}
            </button>
        </div>
    </div>
</div>
