import { TrackingService } from "./TrackingService";
import { IdentityService } from "../account/IdentityService";
import { ModelService } from "../model/ModelService";
import { RoleName } from "../user/IRole";
import { GemeindeService } from "../gemeinde/GemeindeService";
import { forkJoin, lastValueFrom } from "rxjs";
import { ITrackingDashboard, ITrackingSpecificationList } from "../../../common/tracking/ITrackingSpecificationList";
import IModelDto from "../model/IModelDto";
import IGemeinde from "../gemeinde/IGemeinde";
import { ChangeDetectorRef, Component, ElementRef, OnInit } from "@angular/core";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { ContentReadyEvent, RowDblClickEvent } from "devextreme/ui/data_grid";
import { MessageBox, NotificationService, StateService } from "@geolib/geolib-client";
import { IGridColumn } from "../../../common/IGridColumn";
import { TranslateService } from "@ngx-translate/core";
import GridController from "../common/GridController";
import GridService from "../common/GridService";
import { UserSettingsService } from "@geolib/geoappbase-client";
import { NGXLogger } from "ngx-logger";
import IDataset from "./IDataset";
import StatusMapping from "../common/StatusMapping";

interface ITrackingSpecificationListDto extends ITrackingSpecificationList {
    gemeinde?: IGemeinde;
    model?: IModelDto;
    checkoutDate?: null | Date;
    integrationFileName?: string;
}

@Component({
    selector: "tracking-list",
    templateUrl: "./tracking-list.html",
    styleUrls: ["../common/status.scss"],
})
export default class TrackingListController extends GridController implements OnInit {

    public trackingSpecifications: ITrackingSpecificationListDto[];
    public dataSource: DataSource<ITrackingSpecificationListDto, string>;
    public dataGridColumns: IGridColumn[] = [];

    // eslint-disable-next-line max-params
    public constructor(
        private readonly stateService: StateService,
        private readonly trackingService: TrackingService,
        private readonly messageBox: MessageBox,
        private readonly modelService: ModelService,
        private readonly gemeindeService: GemeindeService,
        private readonly notificationService: NotificationService,
        private readonly translateService: TranslateService,
        private readonly statusMapping: StatusMapping,
        elementRef: ElementRef<HTMLElement>,
        gridService: GridService,
        userSettingsService: UserSettingsService,
        logger: NGXLogger,
        changeDetectorRef: ChangeDetectorRef,
        identityService: IdentityService,
    ) {
        super(elementRef.nativeElement, gridService, userSettingsService, logger, changeDetectorRef, identityService);
    }

    protected get settingsKey(): string {
        return "geobus.trackingList.grid";
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.dataGridColumns = this.getColumns();
        this.dataSource = new DataSource<ITrackingSpecificationListDto, string>({
            store: new CustomStore({
                key: "id",
                load: (): Promise<ITrackingSpecificationListDto[]>  => {
                    return lastValueFrom(forkJoin([
                        this.modelService.findAll(),
                        this.trackingService.findPending(),
                        this.trackingService.findAllDtoList(),
                        this.gemeindeService.findAll(),
                    ])).then(([models, datasets, trackingSpecifications, communities]) => {
                        this.trackingSpecifications = trackingSpecifications;
                        this.prepareTrackingsForUI(trackingSpecifications, datasets, new Map(models.map((model) => [model.id!, model])),
                            new Map(communities.map((community) => [community.id!, community])));
                        this.afterGridDataLoaded();

                        return this.trackingSpecifications;
                    });
                },
            }),
        });
    }

    private fetchData(): void {
        this.dxDataGrid.instance.refresh();
    }

    private prepareTrackingsForUI(trackingSpecifications: ITrackingSpecificationListDto[], pendingDataset: ITrackingDashboard[],
        models: Map<string, IModelDto>, communities: Map<string, IGemeinde>): void {

        for (const trackingSpecification of trackingSpecifications) {
            this.addCheckoutInfosToTheTracking(trackingSpecification, pendingDataset);
            this.addCommunityAndModelToTheTracking(trackingSpecification, models, communities);
            this.addIntegrationFilenameToTheTracking(trackingSpecification);
        }
    }

    private addCheckoutInfosToTheTracking(trackingSpecification: ITrackingSpecificationListDto, pendingDataset: ITrackingDashboard[]): void {
        trackingSpecification.checkoutDate = null;

        const matchingPending = pendingDataset.find(({ id }) => id === trackingSpecification.id);
        if (matchingPending) {
            trackingSpecification.checkoutDate = matchingPending.checkoutDate;
        }

        const maxCheckoutPeriod = trackingSpecification.maxCheckoutPeriod;
        if (maxCheckoutPeriod) {
            trackingSpecification.maxCheckoutPeriod = `${maxCheckoutPeriod} Tag${(maxCheckoutPeriod === 1 ? "" : "e")}`;
        }
    }

    private addCommunityAndModelToTheTracking(trackingSpecification: ITrackingSpecificationListDto, models: Map<string, IModelDto>,
        communities: Map<string, IGemeinde>): void {

        const model = models.get(trackingSpecification.modelId!);
        const gemeinde = communities.get(trackingSpecification.gemeindeId!);

        trackingSpecification.model = model;
        trackingSpecification.gemeinde = gemeinde;
    }

    private addIntegrationFilenameToTheTracking(trackingSpecification: ITrackingSpecificationListDto): void {
        const { model, gemeinde } = trackingSpecification;

        if (model?.hasVisualization && gemeinde?.bfsnr && model?.name) {
            trackingSpecification.integrationFileName = `GEOBUS_${gemeinde.bfsnr}_${model.name}`;
        } else {
            trackingSpecification.integrationFileName = "Keine Visualisierung";
        }
    }

    public onRowDblClick(event: RowDblClickEvent<ITrackingDashboard, string>): void {
        this.showDatasets(event.key);
    }

    public onContentReady(event: ContentReadyEvent): void {
        if (!event.component.getDataSource()) {
            event.component.option("dataSource", this.dataSource);
        }
    }

    public showLastDatasets(): void {
        const selectedItem = this.getSelectedItem();
        if (selectedItem) {
            this.showDatasets(selectedItem.id!);
        }
    }

    private showDatasets(id: string): void {
        this.stateService.go("app.trackings.datasets", { trackingSpecificationId: id });
    }

    public create(): void {
        this.stateService.go("app.trackings.edit", { id: "create" });
    }

    public delete(): void {
        const selectedItem = this.getSelectedItem();

        if (!selectedItem) {
            return;
        }

        const name = this.trackingService.getEditTitle({ name: selectedItem.topic }, selectedItem.gemeinde);
        this.messageBox.confirmDelete({ translationKey: "SYSTEM.DELETE_CONFIRMATION", name }).subscribe(() => {
            this.trackingService.deleteTracking(selectedItem.id!).subscribe(() => {
                this.notificationService.notify("Erfolgreich gelöscht");
                this.fetchData();
            }, () => {
                this.notificationService.error("Löschen fehlgeschlagen");
            });

            this.dxDataGrid.instance.deselectAll();
        });
    }

    private getSelectedItem(): ITrackingSpecificationListDto | null {
        return this.dxDataGrid?.instance.getSelectedRowsData()[0];
    }

    public edit(): void {
        const selectedItem = this.getSelectedItem();

        if (!selectedItem) {
            return;
        }

        this.stateService.go("app.trackings.edit", { id: selectedItem.id });
    }

    public isSelectedItemModifiable(): boolean {
        return !this.getSelectedItem();
    }

    public isEditSelectedItemNotAllowed(): boolean {
        const selectedItem = this.getSelectedItem();

        if (!selectedItem) {
            return false;
        }

        const isAuthorized = this.identityService.isAuthorized(RoleName.FS, RoleName.TP, RoleName.NS);
        return !(this.getSelectedItem() && (isAuthorized || this.isInAllowedOrganisations(selectedItem)));
    }

    private isInAllowedOrganisations(selectedItem: ITrackingSpecificationListDto): void {
        selectedItem.zsList?.find((zs) => zs.id === this.identityService.currentUser?.organisation?.id);
    }

    public userHasEditPermissions(): boolean {
        return this.identityService.isAuthorized(RoleName.FS, RoleName.ZS, RoleName.TP);
    }

    public userHasCreateAndDropPermissions(): boolean {
        return this.identityService.isAuthorized(RoleName.FS);
    }

    public editDistribution(): void {
        const selectedItem = this.getSelectedItem();

        if (!selectedItem) {
            return;
        }

        this.stateService.go("app.trackings.distribution", { id: selectedItem.id });
    }

    private getColumns(): IGridColumn[] {
        return [{
            dataField: "lastDataset.status",
            dataType: "string",
            caption: this.translateService.instant("DATASET_LIST.GRID.STATUS"),
            cellTemplate: "statusCellTemplate",
            alignment: "center",
            headerFilter: {
                dataSource: this.statusMapping.getStatusDataSource("lastDataset"),
                width: 350,
            },
            lookup: {
                dataSource: this.statusMapping.getStatusDataSource("lastDataset"),
                displayExpr: "text",
            },
            allowFiltering: false,
            allowHeaderFiltering: true,
        }, {
            dataField: "topic",
            dataType: "string",
            caption: this.translateService.instant("TRACKING.GRID.TOPIC"),
            sortOrder: "asc",
        }, {
            dataField: "model.name",
            dataType: "string",
            caption: this.translateService.instant("TRACKING.GRID.MODEL_NAME"),
        }, {
            dataField: "model.version",
            dataType: "string",
            caption: this.translateService.instant("TRACKING.GRID.MODEL_VERSION"),
        }, {
            dataField: "gemeinde.name",
            dataType: "string",
            caption: this.translateService.instant("TRACKING.GRID.GEMEINDE_NAME"),
        }, {
            dataField: "zs",
            dataType: "string",
            caption: this.translateService.instant("TRACKING.GRID.ZS"),
        }, {
            dataField: "ns",
            dataType: "string",
            caption: this.translateService.instant("TRACKING.GRID.NS"),
        }, {
            dataField: "tp",
            dataType: "string",
            caption: this.translateService.instant("TRACKING.GRID.TP"),
        }, {
            dataField: "checkoutDate",
            dataType: "date",
            caption: this.translateService.instant("TRACKING.GRID.CHECKOUT_DATE"),
            format: "dd.MM.yyyy",
        }, {
            dataField: "maxCheckoutPeriod",
            dataType: "number",
            caption: this.translateService.instant("TRACKING.GRID.MAX_CHECKOUT_PERIOD"),
        }, {
            dataField: "integrationFileName",
            dataType: "string",
            caption: this.translateService.instant("TRACKING.GRID.INTEGRATION_FILENAME"),
        }, {
            dataField: "lastDataset.expertcheck",
            dataType: "string",
            caption: this.translateService.instant("DATASET_LIST.GRID.FP"),
            visible: false,
            showInColumnChooser: false,
        }, {
            dataField: "lastDataset.fileId",
            dataType: "string",
            visible: false,
            showInColumnChooser: false,
        }, {
            dataField: "lastDataset.hasbeenrejected",
            dataType: "boolean",
            visible: false,
            showInColumnChooser: false,
        }, {
            dataField: "lastDataset.approved",
            dataType: "boolean",
            visible: false,
            showInColumnChooser: false,
        }, {
            dataField: "lastDataset.geoportalFtpUploadSucceeded",
            dataType: "boolean",
            visible: false,
            showInColumnChooser: false,
        }, {
            dataField: "lastDataset.manualApproveDate",
            dataType: "date",
            visible: false,
            showInColumnChooser: false,
        }];
    }

    public evaluateStyleClasses(data: IDataset): string {
        return this.statusMapping.evaluateStyleClasses(data);
    }

    public addCustomTooltip(data: IDataset): string {
        return this.statusMapping.addCustomTooltip(data);
    }

}
