import { Injectable } from "@angular/core";
import { NGXLogger } from "ngx-logger";
import { PromiseHelper } from "../common/PromiseHelper";
import { isTruthy } from "@geolib/geolib-types";
import { NullableMappedPosition, RawSourceMap, SourceMapConsumerConstructor } from "source-map";

@Injectable({
    providedIn: "root",
})
export class LoggingService {

    public constructor(
        private readonly logger: NGXLogger,
    ) {
    }

    public logError(error: { message: string; stack?: string }): void {
        if (typeof error.stack === "string" && error.stack.includes("\n")) {
            try {
                const sourceMapFiles = this.getSourceMapFiles(error.stack);
                PromiseHelper.sequential(sourceMapFiles, (item) => {
                    return this.mapStackTrace(error.stack as string, item).then((mappedStack) => {
                        error.stack = mappedStack;
                    });
                }).then(() => {
                    this.tryLog(error);
                });
            } catch {
                // do nothing
            }
        } else {
            this.tryLog(error);
        }
    }

    private tryLog(error: { message: string; stack?: string }): void {
        try {
            this.logger.error(error);
        } catch (error) {
            this.logger.warn(error);
        }
    }

    private getSourceMapFiles(stack: string): string[] {
        return [...new Set(stack.split("\n").map((line) => {
            const match = line.match(/(.+)\((.+):(\d+):(\d+)\)/);
            if (!match) {
                return null;
            }

            const [, , file] = match;
            return `${file}.map`.trim();
        }).filter(isTruthy))];
    }

    private async mapStackTrace(stack: string, sourceMapUrl: string): Promise<string> {
        const response = await fetch(sourceMapUrl);
        const sourceMap = await response.json() as RawSourceMap;
        const SourceMap = (window as unknown as Window & { sourceMap: { SourceMapConsumer: SourceMapConsumerConstructor } }).sourceMap;
        const consumer = await new SourceMap.SourceMapConsumer(sourceMap);

        const mappedStack = stack.split("\n").map((line) => {
            const match = line.match(/(.+)\((.+):(\d+):(\d+)\)/);
            if (!match) {
                return line;
            }

            const [, method, file, lineNum, columnNum] = match;
            const originalPosition = consumer.originalPositionFor({
                line: parseInt(lineNum, 10),
                column: parseInt(columnNum, 10),
            });

            if (!this.hasOriginalPosition(originalPosition) || file.includes("webpack://")) {
                return line;
            }

            return `${method} (${originalPosition.source}:${originalPosition.line}:${originalPosition.column})`;
        }).join("\n");

        consumer.destroy();
        return mappedStack;
    }

    private hasOriginalPosition(originalPosition: NullableMappedPosition): boolean {
        return Boolean(originalPosition.source && originalPosition.line && originalPosition.column);
    }
}
