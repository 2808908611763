export class PromiseHelper {

    public static sequential<T>(list: T[], iterator: (item: T, index: number) => Promise<any>,
        catcher: (((error: any, index: number) => Promise<any>) | null) = null): Promise<any> {
        return list.reduce((promise, item, index): Promise<any> => {
            promise = promise.then((): any => iterator(item, index));

            if (catcher) {
                return promise.catch((error): any => catcher(error, index));
            }

            return promise;
        }, Promise.resolve());
    }

}
