<distribution-edit
    [trackingSpecificationId]="dataset.specificationId"
    [manualDownload]="true"
    [selectionChanged]="selectionChanged"
    (form)="form = $event"
></distribution-edit>
<div class="alert alert-danger">
    {{ "DOWNLOAD.ALERT_1" | translate }} &nbsp;<a href="/documents/Nutzungsbedingung.pdf" target="_blank">{{ "DOWNLOAD.TERMS" | translate }}</a>
    {{ "DOWNLOAD.ALERT_2" | translate }}
</div>
<div class="form-submit dialog-form-submit text-right">
    <div class="btn-group">
        <div class="btn-group">
            <button class="btn btn-default" type="button" (click)="cancel()">{{ "NAVIGATION.ABORT" | translate }}</button>
        </div>
        @if (isCantonMergeAvailable()) {
            <div class="btn-group">
                <button class="btn btn-primary" type="button" (click)="cantonMerge()">{{ "DOWNLOAD.CANTON_MERGE" | translate }}</button>
            </div>
        }
        @if (isSelectedItemValid()) {
            <div class="btn-group">
                <button class="btn btn-primary" type="button" (click)="ok()" [disabled]="!isTargetValid()">
                    {{ "DOWNLOAD.ACCEPT" | translate }}
                </button>
            </div>
        }
        @if (!isSelectedItemValid()) {
            <div class="btn-group">
                <button class="btn btn-primary" type="button" (click)="ok()" [disabled]="!isTargetValid()">
                    {{ "DOWNLOAD.DOWNLOAD" | translate }}
                </button>
            </div>
        }
    </div>
</div>
