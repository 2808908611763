import IFtpServer from "./IFtpServer";
import IFtpServerType from "./IFtpServerType";
import { BackendService } from "@geolib/geolib-client";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";


@Injectable({ providedIn: "root" })
export class FtpServerService extends BackendService<IFtpServer, string> {

    public constructor(
        http: HttpClient,
    ) {
        super("/api/ftpServer", http);
    }

    public findAllTypes(): Observable<IFtpServerType[]> {
        return this.http.get<IFtpServerType[]>("/api/ftpServer/types");
    }

    public createOne(): IFtpServer {
        return {
            type: null,
            name: null,
            server: null,
            port: null,
            username: null,
            password: null,
            directory: null,
            ssh: null,
            id: Math.random().toString(),
        };
    }

    public serverTypeDefinition: Array<{ key: string; value: string }> =
        [
            { key: "kkgeo", value: "KKGEO" },
            { key: "geoportal", value: "Geoportal" },
            { key: "gis_fachstelle", value: "GIS-Fachstelle" },
        ];

}

