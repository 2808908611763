import { Injectable } from "@angular/core";
import { FooterLinkExtension } from "@geolib/geoappbase-client";

@Injectable({ providedIn: "root" })
export default class GeobusTermsOfUseExtension extends FooterLinkExtension {

    public isEnabled(): boolean {
        return true;
    }

    public get title(): string {
        return "STARTPAGE.FOOTER.TERMS_OF_USE";
    }

    public get linkUrl(): string {
        return "https://www.geoportal.ch/services/file/Nutzungsbedingungen.pdf";
    }
}
