<li class="login-panel">
    <dx-drop-down-button
        class="nav-item accountName dx-button-with-gp-icon"
        icon="gp-icon gp-icon-logged-in-user"
        [showArrowIcon]="false"
        [dropDownOptions]="{ width: 320, wrapperAttr: { class: 'gp-header-navigation-dropdown-menu' } }"
        [hint]="fullTitle | translate"
        stylingMode="text"
        displayExpr="text"
    >
        <dxi-item [text]="'HEADER.USER_PROFILE' | translate" uiSref="app.profile">
            <span>{{ "HEADER.USER_PROFILE" | translate }}</span>
        </dxi-item>
        <dxi-item [text]="'HEADER.NOTIFICATIONS' | translate" uiSref="app.profileEvents">
            <span>{{ "HEADER.NOTIFICATIONS" | translate }}</span>
        </dxi-item>
        <dxi-item>
            <div class="gp-delemiter"></div>
        </dxi-item>
        <dxi-item [text]="'HEADER.LOGOUT' | translate" class="fullsize btn btn-block btn-primary" (click)="logout()">
            <span>{{ "HEADER.LOGOUT" | translate }}</span>
        </dxi-item>
    </dx-drop-down-button>
</li>
