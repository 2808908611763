import { MessageBox } from "@geolib/geolib-client";
import { TrackingService } from "../tracking/TrackingService";
import IDataset from "../tracking/IDataset";
import ITrackingSpecification from "../tracking/ITrackingSpecification";
import IDistribution from "../tracking/IDistribution";
import { RoleName } from "../user/IRole";
import { IdentityService } from "../account/IdentityService";
import { GeopackageService } from "../tracking/GeopackageService";
import { Component, Input, OnInit } from "@angular/core";
import { IFtpJson } from "../distribution/IFtpInfo";
import { NgForm } from "@angular/forms";


@Component({
    selector: "download-file-modal",
    templateUrl: "./DownloadFileModal.html",
})
export default class DownloadFileModalController implements OnInit {

    @Input() public dataset: IDataset;
    @Input() public onClose: (datasetId: number, selectedDistribution: IDistribution) => void;

    public trackingSpecification: ITrackingSpecification;
    public selected: IDistribution | undefined;
    private latestDataset: IDataset;
    public form: NgForm;

    public constructor(
        private readonly messageBox: MessageBox,
        private readonly trackingService: TrackingService,
        private readonly identityService: IdentityService,
        private readonly geopackageService: GeopackageService,
    ) {
        this.selectionChanged = this.selectionChanged.bind(this);
    }

    public ngOnInit(): void {
        this.trackingService.getLatestApprovedDataset(this.dataset.specificationId).subscribe((latest) => {
            this.latestDataset = latest;
        });
    }

    public selectionChanged(selected: IDistribution): void {
        this.selected = selected;
    }

    public ok(): void {
        this.onClose(this.dataset.id!, this.selected!);
        this.cancel();
    }

    public cancel(): void {
        this.messageBox.hideAll();
        this.selected = undefined;
    }

    public isSelectedItemValid(): boolean {
        return this.dataset && this.dataset.approved === true;
    }

    public isTargetValid(): boolean {
        if (!this.selected?.target) {
            return false;
        }

        return Boolean(this.form?.valid);
    }

    public cantonMerge(): void {
        const distribution = this.findDistributionForCantonMerge();
        this.geopackageService.cantonMerge(this.dataset, distribution!);
    }

    public isCantonMergeAvailable(): boolean {
        if (this.selected) {
            const isFs = this.identityService.isAuthorized(RoleName.FS);
            const distribution = this.findDistributionForCantonMerge();
            return Boolean(distribution && isFs && this.isDatasetValidForCantonMerge());
        }

        return false;
    }

    private isDatasetValidForCantonMerge(): boolean {
        return this.isSelectedItemValid() && (this.dataset.id === this.latestDataset.id);
    }

    private findDistributionForCantonMerge(): IDistribution | undefined {
        const selected = [this.selected].filter(Boolean);

        return selected.find((distribution) => {
            if (["mail", "direct"].includes(distribution?.type as string)) {
                return false;
            }

            const target = distribution?.target && JSON.parse(distribution.target) as IFtpJson;
            return target && target.ftpServerType === "gis_fachstelle" && target.format === "geopackage";
        });
    }

}
