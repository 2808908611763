import { TrackingService } from "./TrackingService";
import { GemeindeService } from "../gemeinde/GemeindeService";
import { ModelService } from "../model/ModelService";
import { OrganisationService } from "../organisation/OrganisationService";
import { TopicService } from "../topic/TopicService";
import { IdentityService } from "../account/IdentityService";
import { RoleName } from "../user/IRole";
import ITrackingSpecification from "./ITrackingSpecification";
import IModelDto from "../model/IModelDto";
import ITopic from "../topic/ITopic";
import IOrganisation from "../organisation/IOrganisation";
import IGemeinde from "../gemeinde/IGemeinde";
import { forkJoin } from "rxjs";
import { StateService } from "@geolib/geolib-client";
import { NotificationService } from "@geolib/geolib-client";
import { Component } from "@angular/core";
import ITrackingClearance from "./ITrackingClearance";
import DataSource from "devextreme/data/data_source";
import ArrayStore from "devextreme/data/array_store";
import CustomStore from "devextreme/data/custom_store";
import { isNumber } from "../common/IsNumber";
import { HttpErrorResponse } from "@angular/common/http";


@Component({
    selector: "tracking-edit",
    templateUrl: "./tracking-edit.html",
    styleUrls: ["./tracking-edit.scss"],
})
export default class TrackingEditController {

    private allModels: IModelDto[];
    public trackingSpecification: ITrackingSpecification;
    public models: IModelDto[];
    public topics: ITopic[];
    public gemeinden: IGemeinde[];
    public organisations: IOrganisation[];
    public editTitle: string;
    public topicsDataSource: DataSource<ITopic, number>;
    public modelsDataSource: DataSource<IModelDto, number>;
    public gemeindenDateSource: DataSource<IModelDto, number>;
    public organisationsDataSource: DataSource<IModelDto, number>;

    public constructor(
        private readonly trackingService: TrackingService,
        private readonly organisationService: OrganisationService,
        private readonly topicService: TopicService,
        private readonly modelService: ModelService,
        private readonly gemeindeService: GemeindeService,
        private readonly stateService: StateService,
        private readonly identityService: IdentityService,
        private readonly notificationService: NotificationService,
    ) {
        this.editTitle = "";
        const id = stateService.getParameter("id");

        forkJoin([
            this.trackingService.findOne(id ? id : "create"),
            this.topicService.findAll(),
            this.modelService.findAll(),
            this.gemeindeService.findAll(),
            this.organisationService.findAll(),
        ]).subscribe(([trackingSpecification, topics, models, gemeinden, organisations]) => {
            this.trackingSpecification = trackingSpecification;
            this.allModels = models;

            this.topics = topics.sort((a, b) => a.name!.localeCompare(b.name!));
            this.models = this.allModels.slice().sort((a, b) => a.name.localeCompare(b.name));
            this.gemeinden = gemeinden.sort((a, b) => a.name!.localeCompare(b.name!));
            this.organisations = organisations.sort((a, b) => a.name!.localeCompare(b.name!));

            this.topicsDataSource = new DataSource<ITopic, number>({
                store: new ArrayStore({
                    data: this.topics,
                }),
            });

            this.modelsDataSource = new DataSource<IModelDto, number>({
                store: new CustomStore({
                    load: () => this.models,
                }),
            });

            this.gemeindenDateSource = new DataSource<IModelDto, number>({
                store: new ArrayStore({
                    data: this.gemeinden,
                }),
            });

            this.organisationsDataSource = new DataSource<IModelDto, number>({
                store: new ArrayStore({
                    data: this.organisations,
                }),
            });

            this.updateEditTitle();
            this.topicChanged();
        });
    }

    private updateEditTitle(): void {
        const topic = this.topics.filter((topic) => topic.id === this.trackingSpecification.topicId).pop();
        const gemeinde = this.gemeinden.filter((gemeinde) => gemeinde.id === this.trackingSpecification.gemeindeId).pop();
        this.editTitle = this.trackingService.getEditTitle(topic, gemeinde);
    }

    public save(): void {
        if (isNumber(this.trackingSpecification.id)) {
            delete this.trackingSpecification.id;
        }

        this.trackingService.save(this.trackingSpecification).subscribe({
            next: () => {
                this.notificationService.notify("Erfolgreich gespeichert");
                this.abort();
            },
            error: (error: HttpErrorResponse) => {
                if (error.error.key === "unique") {
                    this.notificationService.error("Es existiert bereits eine Nachführung mit gleichem Thema, Modell und Gebiet. " +
                        "Bitte treffen Sie eine andere Auswahl.");
                } else {
                    this.notificationService.error("Speichern fehlgeschlagen");
                }
            },
        });
    }

    public abort(): void {
        this.stateService.go("app.trackings.list");
    }

    public isOnlyNsEnabled(): boolean {
        return !this.identityService.isAuthorized(RoleName.FS) && this.identityService.isAuthorized(RoleName.ZS);
    }

    public isExistingSpecification(): boolean {
        return !isNumber(this.trackingSpecification.id);
    }

    public addNs(): void {
        if (this.trackingSpecification.ns) {
            this.trackingSpecification.ns.push({});
        } else {
            this.trackingSpecification.ns = [{}];
        }
    }

    public deleteNs(ns: ITrackingClearance): void {
        this.trackingSpecification.ns?.splice(this.trackingSpecification.ns.indexOf(ns), 1);
    }

    public topicChanged(): void {
        this.models.length = 0;
        this.models.push(...this.allModels.filter((model) => model.topicId === this.trackingSpecification.topicId));
        this.modelsDataSource.reload();
    }

    public modelChanged(): void {
        const topic = this.allModels.find(({ id }) => id === this.trackingSpecification.modelId);
        this.trackingSpecification.topicId = topic ? topic.topicId : null;
    }

}
