import { Injectable } from "@angular/core";
import { FooterLinkExtension } from "@geolib/geoappbase-client";

@Injectable({ providedIn: "root" })
export default class FooterHelpExtension extends FooterLinkExtension {

    public isEnabled(): boolean {
        return true;
    }

    public get title(): string {
        return "HELP";
    }

    public get linkUrl(): string {
        return "https://help.geoportal.ch/GEOBUS";
    }
}
