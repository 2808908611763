import {
    CourseLinkExtension,
    ExtensionRegistry,
    FooterLinkExtension,
    FullScreenExtension, HeaderExtension,
    HomeContentExtension,
    HomePageLogoExtension,
    LogoExtension,
    MoreNavigationActionExtension,
    MoreNavigationDialogExtension,
    MoreNavigationLinkExtension,
    MyGeoportalHeaderExtension,
    MyGeoportalNavigationCategoryExtension,
    MyGeoportalNavigationItemExtension,
    TermsOfUseLinkExtension,
} from "@geolib/geoappbase-client";
import { Injector } from "@angular/core";
import { GeobusLogoExtension } from "./logo/geobus-logo.extension";
import GeobusHelpExtension from "./moreNavigation/geobus-help.extension";
import { GeobusAboutExtension } from "./moreNavigation/GeobusAboutExtension";
import { GeobusStartPageExtension } from "./home/geobus-start-page.extension";
import { GeobusStartPageLogoExtension } from "./home/geobus-start-page-logo.extension";
import GeobusUserOrganisationExtension from "./menu/geobus-user-organisation.extension";
import GeobusUserAdministrationExtension from "./menu/geobus-user-admin.extension";
import FooterHelpExtension from "./home/start-page-footer-help.extension";
import GeobusTermsOfUseExtension from "./home/geobus-footer-term-of-use.extension";
import GeobusDataExtension from "./menu/geobus-data.extension";
import GeobusTopicExtension from "./menu/geobus-topic.extension";
import GeobusModelExtension from "./menu/geobus-model.extension";
import GeobusCommunityExtension from "./menu/geobus-community.extension";
import GeobusFTPServerExtension from "./menu/geobus-ftp-server.extension";
import GeobusOrganisationExtension from "./menu/geobus-organisation.extension";
import GeobusGeneralExtension from "./menu/geobus-general.extension";
import GeobusToDoExtension from "./menu/geobus-todo.extension";
import GeobusTrackingExtension from "./menu/geobus-tracking.extension";
import { MyGeoportalMoreNavigationExtension } from "./moreNavigation/mygeoportal-more-navigation.extension";
import { MyGeoportalFeedbackHeaderExtension } from "./nav/mygeoportal-feedback-header.extension";
import { MyGeoportalLoginPanelExtension } from "./login/mygeoportal-login-panel.extension";
import LoginPanelGeobusExtension from "./login/login-panel-geobus.extension";
import { GeobusHeaderInfoExtension } from "./headerInfo/geobus-header-info.extension";

// eslint-disable-next-line max-statements
export const registerExtensions = (extensionRegistry: ExtensionRegistry, injector: Injector): void => {

    extensionRegistry.registerExtensionInterface(HomeContentExtension);
    extensionRegistry.register(injector.get(GeobusStartPageExtension));

    extensionRegistry.registerExtensionInterface(HomePageLogoExtension);
    extensionRegistry.register(injector.get(GeobusStartPageLogoExtension));

    extensionRegistry.registerExtensionInterface(FooterLinkExtension);
    extensionRegistry.register(injector.get(FooterHelpExtension));
    extensionRegistry.register(injector.get(GeobusTermsOfUseExtension));

    extensionRegistry.registerExtensionInterface(MoreNavigationDialogExtension);
    extensionRegistry.register(injector.get(GeobusAboutExtension));

    extensionRegistry.registerExtensionInterface(MoreNavigationActionExtension);
    extensionRegistry.register(injector.get(FullScreenExtension));

    extensionRegistry.registerExtensionInterface(MoreNavigationLinkExtension);
    extensionRegistry.register(injector.get(GeobusHelpExtension));
    extensionRegistry.register(injector.get(CourseLinkExtension));
    extensionRegistry.register(injector.get(TermsOfUseLinkExtension));

    extensionRegistry.registerExtensionInterface(LogoExtension);
    extensionRegistry.register(injector.get(GeobusLogoExtension));
    extensionRegistry.register(injector.get(GeobusHeaderInfoExtension));

    extensionRegistry.registerExtensionInterface(HeaderExtension);
    extensionRegistry.register(injector.get(LoginPanelGeobusExtension));

    extensionRegistry.registerExtensionInterface(MyGeoportalHeaderExtension);
    extensionRegistry.register(injector.get(MyGeoportalFeedbackHeaderExtension));
    extensionRegistry.register(injector.get(MyGeoportalLoginPanelExtension));
    extensionRegistry.register(injector.get(MyGeoportalMoreNavigationExtension));

    extensionRegistry.registerExtensionInterface(MyGeoportalNavigationCategoryExtension);
    extensionRegistry.register(injector.get(GeobusGeneralExtension));
    extensionRegistry.register(injector.get(GeobusUserOrganisationExtension));
    extensionRegistry.register(injector.get(GeobusDataExtension));

    extensionRegistry.registerExtensionInterface(MyGeoportalNavigationItemExtension);
    extensionRegistry.register(injector.get(GeobusToDoExtension));
    extensionRegistry.register(injector.get(GeobusTrackingExtension));
    extensionRegistry.register(injector.get(GeobusUserAdministrationExtension));
    extensionRegistry.register(injector.get(GeobusOrganisationExtension));
    extensionRegistry.register(injector.get(GeobusTopicExtension));
    extensionRegistry.register(injector.get(GeobusModelExtension));
    extensionRegistry.register(injector.get(GeobusCommunityExtension));
    extensionRegistry.register(injector.get(GeobusFTPServerExtension));
};
