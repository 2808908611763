import { Injectable } from "@angular/core";
import { ColorThemeSwitcher, DEFAULT_GEOINFO_PRIMARY_COLOR } from "@geolib/geolib-client";


@Injectable({ providedIn: "root" })
export class AppColorThemeSwitcher {

    public constructor() {
        ColorThemeSwitcher.setPrimaryColor(DEFAULT_GEOINFO_PRIMARY_COLOR);
    }

}
