<div [ngClass]="{ 'modal-container': manualDownload }">
    <div class="gp-page-header">
        <div class="gp-container-normal">
            <breadcrumb></breadcrumb>
            @if (!manualDownload) {
                <div class="gp-page-header-container">
                    @if (editTitle) {
                        <h1 class="gp-page-header-title">{{ editTitle }}</h1>
                    }
                    @if (!editTitle) {
                        <h1 class="gp-page-header-title">{{ "DISTRIBUTION_EDIT.TITLE_NEW" | translate }}</h1>
                    }
                    <div class="gp-page-header-actions">
                        <div class="btn-group">
                            <button class="btn btn-default btn-back" (click)="abort()">
                                {{ "NAVIGATION.BACK" | translate }}
                            </button>
                            <gp-button-primary
                                (click)="save()"
                                [isDisabled]="!isFormValid || !isDistributionValid"
                                translation="NAVIGATION.SAVE"
                            ></gp-button-primary>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>
    <div class="gp-container-normal">
        <form (ngSubmit)="save()" #distributionEditForm="ngForm">
            <div class="row" [ngClass]="{ 'flex-column': manualDownload }">
                <div class="col">
                    <label class="col-md-12">
                        <div class="col-form-label">{{ "DISTRIBUTION_EDIT.TYPE" | translate }}</div>
                        <dx-button-group
                            [items]="distributionTypeOptions"
                            keyExpr="key"
                            stylingMode="text"
                            [selectedItemKeys]="[distribution?.type]"
                            (onSelectionChanged)="distributionTypChanged($event)"
                        ></dx-button-group>
                    </label>
                    @if (!manualDownload) {
                        <div class="row">
                            <label class="col-md-6">
                                <div class="col-form-label">{{ "DISTRIBUTION_EDIT.INTERVAL_TYPE" | translate }}</div>
                                <dx-button-group
                                    #intervalTypeButtonGroup
                                    [items]="intervalTypeOptions"
                                    keyExpr="key"
                                    stylingMode="text"
                                    [selectedItemKeys]="[distribution.intervalType]"
                                    (onItemClick)="intervalTypeClick($event)"
                                    (onSelectionChanged)="intervalTypeChanged($event)"
                                ></dx-button-group>
                            </label>
                            <label class="col-md-6">
                                <div class="col-form-label">{{ "DISTRIBUTION_EDIT.INTERVAL_NUMBER" | translate }}</div>
                                <input
                                    class="form-control"
                                    [(ngModel)]="distribution.interval"
                                    type="number"
                                    step="1"
                                    min="1"
                                    max="999"
                                    [required]="distribution.intervalType"
                                    (ngModelChange)="intervalChanged()"
                                    [disabled]="!distribution.intervalType"
                                    name="distributionInterval"
                                />
                            </label>
                        </div>
                    }
                    @if (distribution?.interval && !manualDownload) {
                        <div class="col-md-12">
                            <div class="col-form-label">{{ "DISTRIBUTION_EDIT.NEXT_EXECUTION" | translate }}</div>
                            <dx-date-box
                                [(ngModel)]="distribution.nextExecution"
                                applyValueMode="instantly"
                                name="nextexec"
                                displayFormat="dd.MM.yyyy"
                                type="date"
                                [inputAttr]="{ 'aria-label': 'Date' }"
                                [required]="distribution.interval || distribution.intervalType"
                            ></dx-date-box>
                            @if (!dateBox?.text) {
                                <ul class="gp-form-control-error">
                                    <li>Es muss ein Datum gewählt werden.</li>
                                </ul>
                            }
                        </div>
                    }
                    @if (!manualDownload) {
                        <label class="col-md-12">
                            <div class="col-form-label">{{ "DISTRIBUTION_EDIT.END" | translate }}</div>
                            <div class="btn-group">
                                <dx-switch
                                    [(ngModel)]="distribution.executeOnChange"
                                    switchedOnText="Ja"
                                    switchedOffText="Nein"
                                    name="executeOnChange"
                                ></dx-switch>
                            </div>
                        </label>
                    }
                </div>
                <div class="col" [ngClass]="{ row: manualDownload }">
                    @if (isEmail()) {
                        <label class="col-md-{{ manualDownload ? '6' : '12' }}" [class.has-error]="targetEmail.invalid">
                            <div class="col-form-label">{{ "DISTRIBUTION_EDIT.EMAIL" | translate }}</div>
                            <input
                                class="form-control"
                                #targetEmail="ngModel"
                                [required]="true"
                                [(ngModel)]="distribution.target"
                                type="email"
                                autocomplete="email"
                                name="email"
                                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                            />
                            @if (targetEmail.invalid) {
                                <ul class="gp-form-control-error">
                                    <li>{{ "VALIDATION.EMAIL_NOTVALID" | translate }}</li>
                                </ul>
                            }
                        </label>
                    }
                    @if (isFtpOrSftp()) {
                        <div class="col-md-{{ manualDownload ? '6' : '12' }} select-box-label">
                            <div class="col-form-label">{{ "FTP_SERVER_EDIT.SERVER_TYPE" | translate }}</div>
                            <dx-select-box
                                name="ftpServerType"
                                [items]="ftpServerTypes"
                                [(ngModel)]="ftpinfo.ftpServerType"
                                (ngModelChange)="ftpServerTypeChanged()"
                                displayExpr="name"
                                [required]="true"
                                [searchEnabled]="true"
                            ></dx-select-box>
                        </div>
                    }
                    @if (isFtpOrSftp() && isCustomFtpServer()) {
                        <label class="col-md-{{ manualDownload ? '6' : '12' }}">
                            <div class="col-form-label">{{ "FTP_SERVER_EDIT.SERVER" | translate }}</div>
                            <input class="form-control" [required]="true" [(ngModel)]="ftpinfo.ftpserver" name="ftpserver" />
                        </label>
                    }
                    @if (isFtpOrSftp() && isCustomFtpServer()) {
                        <label class="col-md-{{ manualDownload ? '6' : '12' }}">
                            <div class="col-form-label">{{ "FTP_SERVER_EDIT.PORT" | translate }}</div>
                            <input class="form-control" [required]="true" [(ngModel)]="ftpinfo.ftpport" type="number" name="ftpport" />
                        </label>
                    }
                    @if (isFtpOrSftp() && isCustomFtpServer()) {
                        <label class="col-md-{{ manualDownload ? '6' : '12' }}">
                            <div class="col-form-label">{{ "FTP_SERVER_EDIT.USER_NAME" | translate }}</div>
                            <input class="form-control" [required]="true" [(ngModel)]="ftpinfo.ftpuser" name="ftpuser" />
                        </label>
                    }
                    @if (isFtpOrSftp() && isCustomFtpServer()) {
                        <label class="col-md-{{ manualDownload ? '6' : '12' }}">
                            <div class="col-form-label">{{ "FTP_SERVER_EDIT.PASSWORD" | translate }}</div>
                            <input class="form-control" [required]="true" [(ngModel)]="ftpinfo.ftppassword" type="password" name="ftppassword" />
                        </label>
                    }
                    @if (isFtpOrSftp()) {
                        <label class="col-md-{{ manualDownload ? '6' : '12' }}">
                            <div class="col-form-label">{{ "FTP_SERVER_EDIT.DIRECTORY" | translate }}</div>
                            <input class="form-control" [(ngModel)]="ftpinfo.ftpdirectory" (ngModelChange)="saveTemporary()" name="ftpdirectory" />
                        </label>
                    }
                    @if (isFtpOrSftp()) {
                        <div class="col-md-{{ manualDownload ? '6' : '12' }} select-box-label">
                            <div class="col-form-label">{{ "DISTRIBUTION_EDIT.EXPORT" | translate }}</div>
                            <dx-select-box
                                name="format"
                                [items]="formats"
                                [(ngModel)]="ftpinfo.format"
                                (ngModelChange)="formatChanged(); saveTemporary()"
                                valueExpr="value"
                                displayExpr="label"
                                [required]="true"
                                [searchEnabled]="true"
                            ></dx-select-box>
                        </div>
                    }
                    @if (isFtpOrSftp() && ftpinfo.format?.includes("interlis")) {
                        <div class="col-md-{{ manualDownload ? '6' : '12' }} select-box-label">
                            <div class="col-form-label">{{ "DISTRIBUTION_EDIT.MODEL" | translate }}</div>
                            <dx-select-box
                                name="interlisFormat"
                                [items]="interlisFormats"
                                [(ngModel)]="ftpinfo.interlisFormat"
                                (ngModelChange)="saveTemporary()"
                                valueExpr="value"
                                displayExpr="label"
                                [required]="true"
                                [searchEnabled]="true"
                            ></dx-select-box>
                        </div>
                    }
                </div>
            </div>
        </form>
    </div>
</div>
