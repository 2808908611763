import { UserService } from "./UserService";
import { MessageBox, NotificationService, StateService } from "@geolib/geolib-client";
import { ChangeDetectorRef, Component, ElementRef, OnInit } from "@angular/core";
import IUser from "./IUser";
import { IGridColumn } from "../../../common/IGridColumn";
import { TranslateService } from "@ngx-translate/core";
import { ContentReadyEvent, RowPreparedEvent } from "devextreme/ui/data_grid";
import GridController from "../common/GridController";
import GridService from "../common/GridService";
import { UserSettingsService } from "@geolib/geoappbase-client";
import { NGXLogger } from "ngx-logger";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { IdentityService } from "../account/IdentityService";
import { lastValueFrom } from "rxjs";


@Component({
    selector: "user-list",
    templateUrl: "./user-list.html",
})
export default class UserListController extends GridController implements OnInit {

    public showDeleted: boolean = false;
    public users: IUser[] = [];
    public dataGridColumns: IGridColumn[] = [];
    private activeColumn: IGridColumn;
    private dataSource: DataSource<IUser, string>;

    // eslint-disable-next-line max-params
    public constructor(
        private readonly stateService: StateService,
        private readonly userService: UserService,
        private readonly translateService: TranslateService,
        private readonly messageBox: MessageBox,
        private readonly notificationService: NotificationService,
        elementRef: ElementRef<HTMLElement>,
        gridService: GridService,
        userSettingsService: UserSettingsService,
        logger: NGXLogger,
        changeDetectorRef: ChangeDetectorRef,
        identityService: IdentityService,
    ) {
        super(elementRef.nativeElement, gridService, userSettingsService, logger, changeDetectorRef, identityService);
    }

    protected get settingsKey(): string {
        return "geobus.userList.grid";
    }

    public ngOnInit(): void {
        this.dataGridColumns = this.getColumns();
        this.dataSource = new DataSource<IUser, string>({
            store: new CustomStore({
                key: "id",
                load: async (): Promise<IUser[]> => {
                    const users = await this.loadGridData();
                    this.afterGridDataLoaded();
                    return users;
                },
            }),
        });
    }

    private async loadGridData(): Promise<IUser[]> {
        if (this.showDeleted) {
            this.activeColumn.visible = true;
            return this.fetchAllInactiveUsers();
        } else {
            this.activeColumn.visible = false;
            return this.fetchActiveUsers();
        }
    }

    public onRowDblClick(): void {
        this.edit();
    }

    public onRowPrepared(event: RowPreparedEvent<IUser>): void {
        if (event.rowType === "data") {
            if (!event.data.active) {
                event.rowElement.classList.add("inactive");
            }
        }
    }

    public onContentReady(event: ContentReadyEvent): void {
        if (!event.component.getDataSource()) {
            event.component.option("dataSource", this.dataSource);
        }
    }

    public create(): void {
        this.stateService.go("app.users.edit", { id: "create" });
    }

    public delete(): void {
        const selectedItem = this.getSelectedItem();

        if (!selectedItem) {
            return;
        }

        this.messageBox.confirmDelete({
            translationKey: "SYSTEM.DELETE_CONFIRMATION",
            name: selectedItem.username,
        }).subscribe(() => {
            this.userService.remove(selectedItem).subscribe({
                next: () => {
                    this.notificationService.notify("SYSTEM.DEFAULT_DELETE_SUCCESS");
                    this.fetchActiveUsers();
                },
                error: () => {
                    this.messageBox.warn("Der Benutzer konnte nicht gelöscht werden.");
                },
            });
        });
    }

    public edit(): void {
        const selectedItem = this.getSelectedItem();

        if (!selectedItem) {
            return;
        }

        this.stateService.go("app.users.edit", { id: selectedItem.id });
    }

    public isSelectedItemModifiable(): boolean {
        return !this.getSelectedItem();
    }

    public toggleActiveInactiveUsers(): void {
        this.dxDataGrid.instance.refresh();
    }

    private async fetchActiveUsers(): Promise<IUser[]> {
        const users = await lastValueFrom(this.userService.findAll());
        this.appendRoleNames(users);
        this.users = users;
        return this.users;
    }

    private async fetchAllInactiveUsers(): Promise<IUser[]> {
        const users = await lastValueFrom(this.userService.findAllActiveAndInactive());
        this.appendRoleNames(users);
        this.users = users;
        return this.users;
    }

    private appendRoleNames(users: IUser[]): void {
        for (const user of users) {
            user.rolenames = user.roles?.map((role) => role.name).join(", ");
        }
    }

    private getSelectedItem(): IUser | undefined {
        return this.dxDataGrid?.instance.getSelectedRowsData()[0];
    }

    private getColumns(): IGridColumn[] {
        this.activeColumn = {
            dataField: "active",
            dataType: "boolean",
            caption: this.translateService.instant("USER.GRID.ACTIVE"),
            groupIndex: 8,
            visible: false,
            trueText: this.translateService.instant("GRID_FILTER.YES"),
            falseText: this.translateService.instant("GRID_FILTER.NO"),
        };

        return [{
            dataField: "salutation",
            caption: this.translateService.instant("USER.GRID.SALUTATION"),
            groupIndex: 0,
        }, {
            dataField: "lastname",
            caption: this.translateService.instant("USER.GRID.LASTNAME"),
            groupIndex: 2,
            sortOrder: "asc",
        }, {
            dataField: "firstname",
            caption: this.translateService.instant("USER.GRID.FIRSTNAME"),
            groupIndex: 2,
        }, {
            dataField: "email",
            caption: this.translateService.instant("USER.GRID.EMAIL"),
            groupIndex: 3,
        }, {
            dataField: "telephone",
            caption: this.translateService.instant("USER.GRID.PHONE"),
            groupIndex: 4,
        }, {
            dataField: "organisation.name",
            caption: this.translateService.instant("USER.GRID.ORGANISATION"),
            groupIndex: 5,
        }, {
            dataField: "username",
            caption: this.translateService.instant("USER.GRID.USERNAME"),
            groupIndex: 6,
        }, {
            dataField: "rolenames",
            caption: this.translateService.instant("USER.GRID.ROLE_NAMES"),
            groupIndex: 7,
        }, this.activeColumn];
    }
}
