import { NGXLoggerServerService } from "ngx-logger";
import { INGXLoggerMetadata } from "ngx-logger/lib/metadata/imetadata";
import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class CustomNGXLoggerServerService extends NGXLoggerServerService {

    protected customiseRequestBody(metadata: INGXLoggerMetadata | INGXLoggerMetadata[]): INGXLoggerMetadata | INGXLoggerMetadata[] {
        return metadata;
    }

}
