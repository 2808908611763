import IToken from "./IToken";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { ITokenRequest } from "../../../common/ITokenRequest";


@Injectable({ providedIn: "root" })
export class GeobusAppService {

    public constructor(
        private readonly httpClient: HttpClient,
    ) {
    }

    public getToken(tokenRequest: ITokenRequest): Observable<IToken> {
        const httpParams = this.getParams(tokenRequest);
        return this.httpClient.get<IToken>(`/api/token`, { params: httpParams });
    }

    private getParams(tokenRequest: ITokenRequest): HttpParams {
        let httpParams = new HttpParams();
        Object.entries(tokenRequest).forEach(([key, value]) => {
            if (value) {
                httpParams = httpParams.append(key, value);
            }
        });

        return httpParams;
    }

}
