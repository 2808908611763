import { TopicService } from "./TopicService";
import ITopic from "./ITopic";
import { Component, OnInit } from "@angular/core";
import { NotificationService } from "@geolib/geolib-client";
import { forkJoin } from "rxjs";


@Component({
    selector: "topic-notification-selector",
    templateUrl: "./topic-notification-selector.html",
    styleUrls: ["./topic-notification-selector.scss"],
})
export default class TopicNotificationSelectorController implements OnInit {

    public topics: { selectedTopics: ITopic[]; availableTopics: ITopic[] };
    private markedTopics: ITopic[];

    public constructor(
        private readonly topicService: TopicService,
        private readonly notificationService: NotificationService,
    ) {

        this.topics = {
            selectedTopics: [],
            availableTopics: [],
        };
    }

    public ngOnInit(): void {
        this.topicService.findAllNotifications().subscribe((topics) => {
            topics.availableTopics = topics.availableTopics.sort((a, b) => a.name!.localeCompare(b.name!));
            topics.selectedTopics = topics.selectedTopics.sort((a, b) => a.name!.localeCompare(b.name!));
            this.topics = topics;
            this.topics.availableTopics = this.removeTopicsFromList(this.topics.availableTopics, this.topics.selectedTopics);
        });

        this.markedTopics = [];
    }

    public selectTopics(): void {
        this.topics.selectedTopics = this.addMarkedlistToTopics(this.topics.selectedTopics);
        this.topics.availableTopics = this.removeTopicsFromList(this.topics.availableTopics, this.markedTopics);
        this.subscribeTopic();

        this.topics.selectedTopics = this.removeAllMarks(this.topics.selectedTopics);
        this.markedTopics = [];
    }

    public deselectTopics(): void {
        this.topics.availableTopics = this.addMarkedlistToTopics(this.topics.availableTopics);
        this.topics.selectedTopics = this.removeTopicsFromList(this.topics.selectedTopics, this.markedTopics);
        this.unsubscribeTopic();

        this.topics.availableTopics = this.removeAllMarks(this.topics.availableTopics);
        this.markedTopics = [];
    }

    public markTopics(topic: ITopic, type: string): void {
        topic.selected = !topic.selected;
        topic.type = type;

        const markedTopic = this.markedTopics.find(({ id }) => id === topic.id);
        if (markedTopic) {
            this.markedTopics.splice(this.markedTopics.indexOf(markedTopic), 1);
        } else {
            this.markedTopics.push(topic);
        }
    }

    private subscribeTopic(): void {
        forkJoin(
            this.markedTopics.map((topic) => this.topicService.subscribeNotifications(topic)),
        ).subscribe(() => {
            this.saveSubscriptionsSucceeded();
        }, () => {
            this.saveSubscriptionsFailed();
        });
    }

    private unsubscribeTopic(): void {
        forkJoin(
            this.markedTopics.map((topic) => this.topicService.unsubscribeNotifications(topic.id!)),
        ).subscribe({
            next: () => {
                this.saveSubscriptionsSucceeded();
            },
            error: () => {
                this.saveSubscriptionsFailed();
            },
        });
    }

    private saveSubscriptionsSucceeded(): void {
        this.notificationService.notify("Speichern von Benachrichtigungseinstellungen erfolgreich");
    }

    private saveSubscriptionsFailed(): void {
        this.notificationService.error("Fehler beim Speichern der Benachrichtigungseinstellungen");
    }

    private removeTopicsFromList(list: ITopic[], topics: ITopic[]): ITopic[] {
        list = list.slice();

        for (const topic of topics) {
            const topicInList = list.find(({ id }) => id === topic.id);
            if (topicInList) {
                list.splice(list.indexOf(topicInList), 1);
            }
        }

        return list;
    }

    private removeAllMarks(list: ITopic[]): ITopic[] {
        for (const listItem of list) {
            listItem.selected = false;
        }
        return list;
    }

    private addMarkedlistToTopics(list: ITopic[]): ITopic[] {
        for (const listItem of this.markedTopics) {
            list.push(listItem);
        }
        return list;
    }

    public hasMarkedTopics(): number {
        return this.markedTopics.length;
    }

    public hasAvailableMarked(): ITopic | undefined {
        return this.markedTopics.find((topic) => topic.type === "available");
    }

    public hasSelectedMarked(): ITopic | undefined {
        return this.markedTopics.find((topic) => topic.type === "selected");
    }

}
