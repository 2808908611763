import { BackendService } from "@geolib/geolib-client";
import IModelDto from "./IModelDto";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { isTruthy } from "@geolib/geolib-types/src/common/PredicateChecks";
import { restrictedFields } from "../../../common/model/IModel";


@Injectable({ providedIn: "root" })
export class ModelService extends BackendService<IModelDto, string> {

    public constructor(
        httpClient: HttpClient,
    ) {
        super("/api/model", httpClient);
    }

    public upload(model: IModelDto, files: File[]) {
        const url = `/api/model/${model.id}`;
        const formData = this.getUploadFormData(model, files);
        return this.http.post(url, formData);
    }

    private getUploadFormData(model: IModelDto, files?: Blob | Blob[]): FormData {
        const blobArray = Array.isArray(files) ? files : [files];
        const blobs = blobArray.filter((file) => file instanceof Blob).filter(isTruthy);
        const data = { model, file: [] as Blob[] };
        data.file = blobs;
        return this.getFormData(data);
    }

    private getFormData(data: object): FormData {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            if (key === "file") {
                const files: File[] = Array.isArray(value) ? value : [value];
                files.forEach((file: File & { key: string }) => formData.append(file.key || key, file, file.name));
            } else {
                formData.append(key, JSON.stringify(value));
            }
        });
        return formData;
    }

    public getAllPublicModels(): Observable<IModelDto[]> {
        return this.http.get<IModelDto[]>(`/download/filelist`);
    }

    public createOne(): IModelDto {
        const newModel: IModelDto = {
            datastockId: null,
            format: "",
            fromdate: undefined,
            guidelinesFileId: null,
            hasExtendedTechnicalCheck: false,
            hasGeoportalPublication: false,
            hasOerebPublication: false,
            interlisFormat: "",
            isSiaModel: false,
            canModify: true,
            modelFileId: null,
            name: "",
            topicId: "",
            version: "",
            cantonId: "",
            id: Math.random().toString(),
            restrictedFields,
        };
        return newModel;
    }

    public formatDefinition: Array<{ key: string; value: string }> =
        [
            { key: "interlis1", value: "Interlis1 (.itf)" },
            { key: "interlis2", value: "Interlis2 (.xtf)" },
            { key: "geopackage", value: "Geopackage (.gpkg)" },
            { key: "other", value: "Andere" },
        ];

    public interlisFormatDefinition: Array<{ key: string; value: string }> =
        [
            { key: "canton", value: "Kantonales Modell" },
            { key: "federal", value: "Bundesmodell" },
        ];
}
