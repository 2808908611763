import { Injectable } from "@angular/core";
import { MoreNavigationController, MyGeoportalHeaderExtension } from "@geolib/geoappbase-client";

@Injectable({ providedIn: "root" })
export class MyGeoportalMoreNavigationExtension extends MyGeoportalHeaderExtension<MoreNavigationController> {

    public isEnabled(): boolean {
        return true;
    }

    public get component(): typeof MoreNavigationController {
        return MoreNavigationController;
    }
}
