import { Component, Input } from "@angular/core";

@Component({
    templateUrl: "./logo.component.html",
    selector: "map-header-logo",
})
export class LogoComponent {

    @Input() public extensionData: unknown;

}
