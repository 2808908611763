<div class="gp-page-header">
    <div class="gp-container-normal">
        <breadcrumb></breadcrumb>
        <div class="gp-page-header-container">
            @if (organisation.name) {
                <h1 class="gp-page-header-title">{{ organisation.name }}</h1>
            }
            @if (!organisation.name) {
                <h1 class="gp-page-header-title">{{ "ORGANISATION_EDIT.TITLE" | translate }}</h1>
            }
            <div class="gp-page-header-actions">
                <div class="btn-group">
                    <button class="btn btn-default btn-back" (click)="abort()">
                        {{ "NAVIGATION.BACK" | translate }}
                    </button>
                    <gp-button-primary
                        (click)="save()"
                        [isDisabled]="!editOrganisationForm.valid || !prmEmail.valid || editOrganisationForm.pristine"
                        translation="NAVIGATION.SAVE"
                    ></gp-button-primary>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="gp-container-normal">
    <form class="panel panel-default" #editOrganisationForm="ngForm" (ngSubmit)="save()">
        <div class="row">
            <label class="col-md-6">
                <div class="col-form-label">{{ "ORGANISATION_EDIT.NAME" | translate }}</div>
                <input class="form-control" [required]="true" [(ngModel)]="organisation.name" autofocus="true" name="name" />
            </label>
            <label class="col-md-6" [class.has-error]="prmEmail.invalid">
                <div class="col-form-label">{{ "ORGANISATION_EDIT.EMAIL" | translate }}</div>
                <input
                    class="form-control"
                    #prmEmail="ngModel"
                    type="email"
                    [required]="true"
                    [(ngModel)]="organisation.email"
                    name="email"
                    pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                />
                @if (prmEmail.invalid) {
                    <ul class="gp-form-control-error">
                        <li>{{ "VALIDATION.EMAIL_NOTVALID" | translate }}</li>
                    </ul>
                }
            </label>
        </div>
        <div class="row">
            <label class="col-md-6">
                <div class="col-form-label">{{ "ORGANISATION_EDIT.STREET" | translate }}</div>
                <input class="form-control" [(ngModel)]="organisation.street" name="street" />
            </label>
            <label class="col-md-6">
                <div class="col-form-label">{{ "ORGANISATION_EDIT.PHONE" | translate }}</div>
                <input class="form-control" [(ngModel)]="organisation.telephone" name="telephone" />
            </label>
        </div>
        <div class="row">
            <label class="col-md-6">
                <div class="col-form-label">{{ "ORGANISATION_EDIT.PLZ" | translate }}</div>
                <input class="form-control" [(ngModel)]="organisation.postalCode" name="postalCode" type="number" />
            </label>
        </div>
        <div class="row">
            <label class="col-md-6">
                <div class="col-form-label">{{ "ORGANISATION_EDIT.CITY" | translate }}</div>
                <input class="form-control" [required]="true" [(ngModel)]="organisation.location" name="location" />
            </label>
        </div>
    </form>
</div>
