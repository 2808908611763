import { BackendService } from "@geolib/geolib-client";
import IUser from "./IUser";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { assert } from "ts-essentials";


@Injectable({ providedIn: "root" })
export class UserService extends BackendService<IUser, string> {

    public constructor(
        http: HttpClient,
    ) {
        super("/api/users", http);
    }

    public findCurrentUser(): Observable<IUser> {
        return this.http.get<IUser>("/api/users/currentUser");
    }

    public findAllInactiveUsers(): Observable<IUser[]> {
        return this.http.get<IUser[]>("/api/users/allInactiveUsers");
    }

    public findAllActiveAndInactive(): Observable<IUser[]> {
        return this.http.get<IUser[]>("/api/users/allActiveAndInactive");
    }

    public requestNewPassword(name: string): Observable<{ success: boolean }> {
        return this.http.post<{ success: boolean }>(`/api/passwordrecovery/reset`, { name });
    }

    public getUserByPasswordRecoveryToken(token: string): Observable<IUser> {
        return this.http.get<IUser>(`/api/passwordrecovery/token/${token}`);
    }

    public setPasswordWithRecoveryToken(token: string, newPassword: string): Observable<void> {
        return this.http.post<void>("/api/passwordrecovery/setpassword", { token, newPassword });
    }

    public createOne(): IUser {
        return {
            active: true,
            id: Math.random().toString(),
        };
    }

    public get salutationOptions(): Array<{ key: string; value: string }> {
        return [
            { key: "Frau", value: "Frau" },
            { key: "Herr", value: "Herr" },
            { key: "Damen und Herren", value: "Damen und Herren" },
        ];
    }

    public checkIfUniqueUsername(user: IUser): Observable<{ usernameIsUnique: boolean }> {
        assert(user.username);
        assert(user.id);

        return this.http.get<{ usernameIsUnique: boolean }>(`/api/users/checkIfUniqueUsername`, {
            params: { username: user.username, id: user.id },
        });
    }

}
