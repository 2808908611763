import { ApplicationRef, CUSTOM_ELEMENTS_SCHEMA, DoBootstrap, ErrorHandler, Injector, NgModule } from "@angular/core";
import localeDECH from "@angular/common/locales/de-CH";
import { registerLocaleData } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule, HttpErrorResponse } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule, TranslateService } from "@ngx-translate/core";
import translationDe from "./data/local-de_DE.json";
import { AppColorThemeSwitcher } from "./app/theme/AppColorThemeSwitcher";
import { GeolibClientModule } from "@geolib/geolib-client";
import {
    DxButtonGroupModule,
    DxButtonModule,
    DxDataGridModule,
    DxDateBoxModule,
    DxDropDownButtonModule,
    DxFileUploaderModule,
    DxLookupModule,
    DxSelectBoxModule,
    DxSwitchModule,
    DxTextBoxModule,
} from "devextreme-angular";
import { LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_SERVER_SERVICE } from "ngx-logger";
import { loadMessages, locale } from "devextreme/localization";
import deMessages from "devextreme/localization/messages/de.json";
import { UIRouterModule } from "@uirouter/angular";
import { UserService } from "./app/user/UserService";
import { EventSubscriptionService } from "./app/user/EventSubscriptionService";
import { TopicService } from "./app/topic/TopicService";
import { ModelService } from "./app/model/ModelService";
import { GemeindeService } from "./app/gemeinde/GemeindeService";
import StatusMapping from "./app/common/StatusMapping";
import { IdentityService } from "./app/account/IdentityService";
import { PasswordPolicy } from "./app/common/PasswordPolicy";
import { AuthenticationService } from "./app/account/AuthenticationService";
import { GeobusAppService } from "./app/tracking/GeobusAppService";
import { OrganisationService } from "./app/organisation/OrganisationService";
import { FtpServerService } from "./app/ftpServer/FtpServerService";
import { IntegrationService } from "./app/integration/IntegrationService";
import { PublicationService } from "./app/publication/PublicationService";
import { TrackingService } from "./app/tracking/TrackingService";
import { GeopackageService } from "./app/tracking/GeopackageService";
import DatasetListController from "./app/tracking/DatasetListController";
import DownloadFileModalController from "./app/downloadFileModal/DownloadFileModalController";
import UserProfileController from "./app/user/UserProfileController";
import UserListController from "./app/user/UserListController";
import UserEditController from "./app/user/UserEditController";
import MainController from "./app/main/MainController";
import TrackingListController from "./app/tracking/TrackingListController";
import ModelEditController from "./app/model/ModelEditController";
import TopicEditController from "./app/topic/TopicEditController";
import DatasetEditController from "./app/tracking/DatasetEditController";
import ModelListController from "./app/model/ModelListController";
import EventSubscriptionController from "./app/user/EventSubscriptionController";
import TopicNotificationSelectorController from "./app/topic/TopicNotificationSelector";
import GemeindeListController from "./app/gemeinde/GemeindeListController";
import GemeindeEditController from "./app/gemeinde/GemeindeEditController";
import TopicListController from "./app/topic/TopicListController";
import TrackingEditController from "./app/tracking/TrackingEditController";
import DatasetCheckinController from "./app/tracking/DatasetCheckinController";
import OrganisationEditController from "./app/organisation/OrganisationEditController";
import FtpServerController from "./app/ftpServer/FtpServerController";
import LoginController from "./app/login/LoginController";
import PasswordResetController from "./app/passwordrecovery/PasswordResetController";
import ProtocolController from "./app/tracking/ProtocolController";
import DownloadModelListController from "./app/downloadmodel/DownloadModelListController";
import OrganisationListController from "./app/organisation/OrganisationListController";
import TechRejectDialog from "./app/tracking/dialogs/techRejectDialog/TechRejectDialog";
import ExtendedTechRejectDialog from "./app/tracking/dialogs/extendedTechRejectDialog/ExtendedTechRejectDialog";
import ProRejectDialog from "./app/tracking/dialogs/proRejectDialog/ProRejectDialog";
import DistributionComponent from "./app/distribution/Distribution";
import PasswordRecoveryController from "./app/passwordrecovery/PasswordRecoveryController";
import AppComponent from "./app/AppComponent";
import { states } from "./app/states";
import AppInitializer from "./app/AppInitializer";
import { AutofocusDirective } from "./app/common/Autofocus";
import { LogoComponent } from "./app/logo/logo.component";
import { registerExtensions } from "./app/extensions";
import { ExtensionRegistry, GeoappbaseClientInitializer, GeoappbaseClientModule } from "@geolib/geoappbase-client";
import { LoginPanelComponent } from "./app/login/login-panel.component";
import { DxiItemModule } from "devextreme-angular/ui/nested";
import FtpServerEditComponent from "./app/ftpServer/ftp-server-edit.component";
import DistributionEditComponent from "./app/distribution/distribution-edit.component";
import { BreadcrumbComponent } from "./app/breadcrumb/breadcrumb.component";
import GridService from "./app/common/GridService";
import { HeaderInfoComponent } from "./app/headerInfo/header-info.component";
import { GeobusLoginService } from "./app/account/GeobusLoginService";
import { LoggingService } from "./app/logging/LoggingService";
import { GlobalErrorHandler } from "./app/logging/GlobalErrorHandler";
import { CustomNGXLoggerServerService } from "./app/logging/CustomNGXLoggerServerService";

registerLocaleData(localeDECH);

const componentes = [
    AppComponent,
    BreadcrumbComponent,
    DatasetCheckinController,
    DatasetEditController,
    DatasetListController,
    DistributionComponent,
    DistributionEditComponent,
    DownloadFileModalController,
    DownloadModelListController,
    EventSubscriptionController,
    ExtendedTechRejectDialog,
    FtpServerController,
    FtpServerEditComponent,
    GemeindeEditController,
    GemeindeListController,
    LoginController,
    LoginPanelComponent,
    LogoComponent,
    HeaderInfoComponent,
    MainController,
    ModelEditController,
    ModelListController,
    OrganisationEditController,
    OrganisationListController,
    PasswordRecoveryController,
    PasswordResetController,
    ProRejectDialog,
    ProtocolController,
    TechRejectDialog,
    TopicEditController,
    TopicListController,
    TopicNotificationSelectorController,
    TrackingEditController,
    TrackingListController,
    UserEditController,
    UserListController,
    UserProfileController,
];

@NgModule({
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        DxButtonGroupModule,
        DxButtonModule,
        DxDataGridModule,
        DxDataGridModule,
        DxDateBoxModule,
        DxDropDownButtonModule,
        DxFileUploaderModule,
        DxSelectBoxModule,
        DxSwitchModule,
        DxTextBoxModule,
        DxiItemModule,
        DxDataGridModule,
        DxLookupModule,
        FormsModule,
        GeoappbaseClientModule,
        GeolibClientModule,
        HttpClientModule,
        LoggerModule.forRoot({
            serverLoggingUrl: "/api/log",
            level: NgxLoggerLevel.DEBUG,
            serverLogLevel: NgxLoggerLevel.ERROR,
            enableSourceMaps: true,
        }, {
            serverProvider: {
                provide: TOKEN_LOGGER_SERVER_SERVICE, useClass: CustomNGXLoggerServerService,
            },
        }),
        ReactiveFormsModule,
        TranslateModule.forRoot({ extend: true }),
        UIRouterModule.forRoot({ states }),
    ],
    declarations: [
        ...componentes,
        AutofocusDirective,
    ],
    providers: [
        AuthenticationService,
        EventSubscriptionService,
        FtpServerService,
        GemeindeService,
        GeobusAppService,
        GeopackageService,
        IdentityService,
        IntegrationService,
        ModelService,
        OrganisationService,
        PasswordPolicy,
        PublicationService,
        StatusMapping,
        TopicService,
        TrackingService,
        UserService,
        GridService,
        GeobusLoginService,
        LoggingService,
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ],
    exports: [],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule implements DoBootstrap {

    public constructor(
        private readonly translateService: TranslateService,
        private readonly injector: Injector,
        private readonly extensionRegistry: ExtensionRegistry,
        private readonly loggingService: LoggingService,
    ) {
        this.setGlobalErrorHandler();
        this.setTranslation();
    }

    public ngDoBootstrap(appRef: ApplicationRef): void {
        appRef.bootstrap(AppComponent);
        registerExtensions(this.extensionRegistry, this.injector);
        this.injector.get(AppColorThemeSwitcher);
        this.injector.get(AppInitializer);
        this.injector.get(GeoappbaseClientInitializer);
    }

    private setGlobalErrorHandler(): void {
        window.onerror = (message: string, source: string, lineno: number, colno: number, error: Error | HttpErrorResponse) => {
            if (error instanceof HttpErrorResponse && error.url?.endsWith("/api/log")) {
                return false;
            }

            this.loggingService.logError({
                message: message.toString(),
                stack: error && "stack" in error ? error.stack : `Source: ${source}, Line: ${lineno}, Column: ${colno}`,
            });

            return false;
        };

        window.onunhandledrejection = (event: PromiseRejectionEvent) => {
            this.loggingService.logError({
                message: event.reason ? event.reason.message : "Unhandled Promise Rejection",
                stack: event.reason ? event.reason.stack : "",
            });
            return false;
        };
    }

    private setTranslation(): void {
        this.translateService.setTranslation("de", translationDe);
        this.translateService.setDefaultLang("de");
        this.translateService.use("de");

        loadMessages(deMessages);
        locale("de-CH");
    }

}
