import { BackendService } from "@geolib/geolib-client";
import IOrganisation from "./IOrganisation";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";


@Injectable({ providedIn: "root" })
export class OrganisationService extends BackendService<IOrganisation, string> {

    public constructor(
        http: HttpClient,
    ) {
        super("/api/organisation", http);
    }

    public createOne(): IOrganisation {
        return {
            isFs: false,
            id: Math.random().toString(),
            isAdmin: false,
        };
    }

    public findAllActiveAndInactiveOrganisations(): Observable<IOrganisation[]> {
        return this.http.get<IOrganisation[]>("/api/organisation/allActiveAndInactive");
    }

}
