import { Component, Input } from "@angular/core";
import { MessageBox } from "@geolib/geolib-client";
import IDataset from "../../IDataset";


@Component({
    selector: "extended-tech-reject-dialog",
    templateUrl: "./extendedTechRejectDialog.html",
})
export default class ExtendedTechRejectDialog {

    @Input() public dataset: IDataset;
    @Input() public onFileSelect: (files: File[]) => void;
    @Input() public extendedTechApprove: () => void;
    @Input() public extendedTechReject: () => void;

    public extendedTechApproveAction: string;
    public datasetFile: File;

    public constructor(
        private readonly messageBox: MessageBox,
    ) {
    }

    public cancel(): void {
        this.messageBox.hideAll();
    }

    public onFileSelected(files: File[]): void {
        this.datasetFile = files[0];
        this.onFileSelect(files);
    }

    public approve(): void {
        this.extendedTechApprove();
        this.cancel();
    }

    public reject(): void {
        this.extendedTechReject();
        this.cancel();
    }

}
