import { NotificationService, StateService } from "@geolib/geolib-client";
import { TopicService } from "./TopicService";
import ITopic from "./ITopic";
import { Component } from "@angular/core";
import { isNumber } from "../common/IsNumber";


@Component({
    selector: "topic-edit",
    templateUrl: "./topic-edit.html",
})
export default class TopicEditController {

    public topic: ITopic;
    public errorMessage: string = "";

    public constructor(
        private readonly stateService: StateService,
        private readonly topicService: TopicService,
        private readonly notificationService: NotificationService,
    ) {
        const id = stateService.getParameter("id");
        this.topicService.findOne(id ? id : "create").subscribe((topic) => {
            this.topic = topic;
        }, () => {
            this.errorMessage = "TOPIC_EDIT.LOADING_FAILED";
        });
    }

    public save(): void {
        if (isNumber(this.topic.id)) {
            delete this.topic.id;
        }

        this.topicService.save(this.topic).subscribe(() => {
            this.notificationService.notify("SYSTEM.DEFAULT_SAVE_SUCCESS");
            this.abort();
        }, () => {
            this.notificationService.error("SYSTEM.DEFAULT_SAVE_ERROR");
        });
    }

    public abort(): void {
        this.stateService.go("app.topics.list");
    }

}
