import { Injectable } from "@angular/core";


@Injectable({ providedIn: "root" })
export class PasswordPolicy {
    private static readonly PASSWORD_REGEX = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/;

    public validate(password?: string): { valid: boolean; message?: string } {
        if (!password || password.length < 8) {
            return { message: "Passwort ist zu kurz", valid: false };
        }

        if (!PasswordPolicy.PASSWORD_REGEX.test(password)) {
            return { message: "Passwort muss mindestens 8 Zeichen lang sein und Zahlen, Gross- und Kleinbuchstaben enthalten", valid: false };
        }

        return { valid: true };
    }

}
