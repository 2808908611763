import { ChangeDetectorRef, Directive, OnDestroy, OnInit, ViewChild } from "@angular/core";
import GridService from "./GridService";
import { UserSettingsService } from "@geolib/geoappbase-client";
import { DxGridState } from "./DxTypes";
import { NGXLogger } from "ngx-logger";
import { DxDataGridComponent } from "devextreme-angular";
import { lastValueFrom, Subscription } from "rxjs";
import { formatMessage } from "devextreme/localization";
import { ColumnChooser } from "devextreme/common/grids";
import { CellPreparedEvent, EditorPreparingEvent } from "devextreme/ui/data_grid";
import { ValueChangedEvent } from "devextreme/ui/select_box";
import DxList from "devextreme/ui/list";
import DxTreeView from "devextreme/ui/tree_view";
import { IdentityService } from "../account/IdentityService";


@Directive()
export default abstract class GridController implements OnInit, OnDestroy {

    private _dxDataGrid: DxDataGridComponent;
    private onToolbarPreparingSubscription?: Subscription;
    public readonly columnChooserOptions: ColumnChooser;

    @ViewChild(DxDataGridComponent)
    public set dxDataGrid(value: DxDataGridComponent) {
        this._dxDataGrid = value;

        if (this._dxDataGrid && !this.onToolbarPreparingSubscription) {
            this.onToolbarPreparingSubscription = this._dxDataGrid.onToolbarPreparing.subscribe(() => {
                this.changeDetectorRef.detectChanges();
            });
        }
    }

    public get dxDataGrid(): DxDataGridComponent {
        return this._dxDataGrid;
    }

    public gridHeight: number = 250;
    protected hasGridControls: boolean = true;
    private isColumnChooserOpen: boolean = false;
    private editorElements: Map<HTMLElement, unknown> = new Map();

    protected abstract get settingsKey(): string;

    protected constructor(
        private readonly element: HTMLElement,
        private readonly gridService: GridService,
        private readonly userSettingsService: UserSettingsService,
        protected readonly logger: NGXLogger,
        private readonly changeDetectorRef: ChangeDetectorRef,
        protected readonly identityService: IdentityService,
    ) {
        this.calculateGridHeight = this.calculateGridHeight.bind(this);
        this.loadState = this.loadState.bind(this);
        this.saveState = this.saveState.bind(this);

        // "as unknown as number" is done because actually every css valid value is allowed. the type is wrong.
        this.columnChooserOptions = { height: "35vh" as unknown as number, width: 355, search: { enabled: false }, mode: "select" };

        DxList.defaultOptions({
            device: { deviceType: "desktop" },
            options: {
                onItemRendered: (args) => {
                    if (args.itemData) {
                        args.itemElement.setAttribute("title", args.itemData.text);
                    }
                },
            } as any,
        });

        DxTreeView.defaultOptions({
            device: { deviceType: "desktop" },
            options: {
                onItemRendered: (args) => {
                    if (args.itemData) {
                        args.itemElement.setAttribute("title", args.itemData.text);
                    }
                },
            } as any,
        });
    }

    public ngOnInit(): void {
        window.addEventListener("resize", this.calculateGridHeight);
    }

    public ngOnDestroy(): void {
        window.removeEventListener("resize", this.calculateGridHeight);
    }

    protected calculateGridHeight(): void {
        this.gridHeight = this.gridService.calculateGridHeight(this.element, this.hasGridControls);
    }

    protected afterGridDataLoaded(): void {
        this.calculateGridHeight();
        this.dxDataGrid.instance.element().classList.remove("hide-overflow");

        setTimeout(() => {
            this.updateEditorElements();
        });
    }

    private updateEditorElements(): void {
        this.editorElements.forEach((value, editorElement) => this.updateFilterAppliedClass(editorElement, value));
        this.editorElements.clear();
    }

    public loadState(): Promise<DxGridState | undefined> {
        return lastValueFrom(this.userSettingsService.get<DxGridState>(this.settingsKey)).then((data) => {
            if (data) {
                this.deleteSelectedRowKeys(data);
                this.deleteInvalidValues(data);
                return data;
            }
        }).catch((error) => {
            this.logger.error(error);
            return undefined;
        });
    }

    private deleteSelectedRowKeys(data: DxGridState): void {
        if (data.selectedRowKeys) {
            delete data.selectedRowKeys;
        }
    }

    private deleteInvalidValues(data: DxGridState): void {
        for (const column of data.columns) {
            if (!column.width) {
                delete column.width;
            }
        }
    }

    public saveState(state: DxGridState): Promise<void> {
        this.deleteSelectedRowKeys(state);
        this.deleteInvalidValues(state);
        return lastValueFrom(this.userSettingsService.put<DxGridState>(this.settingsKey, state));

    }

    public hasHiddenColumns(): boolean {
        if (!this.dxDataGrid || !this.dxDataGrid.instance.getVisibleColumns().length) {
            return false;
        }

        return this.dxDataGrid.columns.filter((column) => {
            return typeof column !== "string" && column.showInColumnChooser !== false;
        }).length !== this.dxDataGrid.instance.getVisibleColumns().length;
    }

    public getDevExpressTranslation(name: string): string {
        return formatMessage(name);
    }

    public toggleColumnChooser(): void {
        if (this.isColumnChooserOpen) {
            this.dxDataGrid.instance.hideColumnChooser();
        } else {
            this.dxDataGrid.instance.showColumnChooser();
        }
    }

    public resetGridSettings(): void {
        this.dxDataGrid.instance.state(null);
    }

    public get isAdmin(): boolean {
        return Boolean(this.identityService.currentUser?.organisation?.isAdmin);
    }

    public onEditorPreparing(event: EditorPreparingEvent): void {
        if (event.parentType === "filterRow") {
            this.updateFilterAppliedClass(event.editorElement, event.value);
            this.editorElements.set(event.editorElement, event.value);
            const originalValueChangedHandler = event.editorOptions.onValueChanged;

            event.editorOptions.onValueChanged = (args: ValueChangedEvent): void => {
                this.updateFilterAppliedClass(args.element, args.value);
                originalValueChangedHandler(args);
            };

            if (this.isStatusField(event.dataField)) {
                // event.editorOptions.width = "200px";
            }
        }
    }

    private updateFilterAppliedClass(element: HTMLElement, value: unknown): void {
        const filterIconElement = element.closest(".dx-editor-with-menu")?.querySelector(".dx-icon");

        if (filterIconElement) {
            if (value) {
                filterIconElement.classList.add("gp-text-color-red");
            } else {
                filterIconElement.classList.remove("gp-text-color-red");
            }
        }
    }

    public onCellPrepared(event: CellPreparedEvent): void {
        // can be completely removed if the new colors are good enough
        if (event.rowType === "data" && this.isStatusField(event.column.dataField)) {
            const element = event.cellElement.querySelector(".status");
            if (element && element.classList.contains("dx-icon-isnotblank")) {
                // const color = window.getComputedStyle(element).color;
                // const rgbaColor = color.replace("rgb", "rgba").replace(")", ", 0.2)");
                // event.cellElement.style.backgroundColor = rgbaColor;
            }
        }
    }

    private isStatusField(dataField?: string): boolean {
        return Boolean(
            dataField === "status" || (dataField?.includes(".") && dataField?.split(".").pop() === "status"),
        );
    }

}
