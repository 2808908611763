import { NotificationService, StateService } from "@geolib/geolib-client";
import { ModelService } from "./ModelService";
import { IdentityService } from "../account/IdentityService";
import { TopicService } from "../topic/TopicService";
import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import { RoleName } from "../user/IRole";
import IModelDto from "./IModelDto";
import ITopic from "../topic/ITopic";
import { finalize } from "rxjs/operators";
import { NgForm } from "@angular/forms";
import DataSource from "devextreme/data/data_source";
import { forkJoin } from "rxjs";
import ArrayStore from "devextreme/data/array_store";
import { DxDateBoxComponent } from "devextreme-angular";
import { isNumber } from "../common/IsNumber";


@Component({
    selector: "model-edit",
    templateUrl: "./model-edit.html",
})
export default class ModelEditController {

    @ViewChild(NgForm) public readonly modelEditForm: NgForm;

    private _dateBox: DxDateBoxComponent;
    @ViewChild(DxDateBoxComponent)
    public set dateBox(value: DxDateBoxComponent) {
        this._dateBox = value;
        this.changeDetectorRef.detectChanges();
    }

    public get dateBox(): DxDateBoxComponent {
        return this._dateBox;
    }

    public topicDataSource?: DataSource<ITopic, number>;
    public formatDataSource?: DataSource<string, string>;
    public interlisFormatDataSource?: DataSource<string, string>;
    private modelFile: File & { key?: string };
    private guidelinesFile: File & { key?: string };
    public geodatenModel: IModelDto;
    public hasVisualization: boolean;
    private readonly isUserInTP: boolean;
    private readonly isUserInFS: boolean;
    public opened: boolean;
    public isLoading: boolean;
    public errorMessage: string = "";

    public constructor(
        private readonly stateService: StateService,
        private readonly modelService: ModelService,
        private readonly topicService: TopicService,
        private readonly identityService: IdentityService,
        private readonly notificationService: NotificationService,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {
        this.formatDataSource = new DataSource({
            store: new ArrayStore({
                data: this.modelService.formatDefinition,
            }),
        });

        this.interlisFormatDataSource = new DataSource({
            store: new ArrayStore({
                data: this.modelService.interlisFormatDefinition,
            }),
        });

        const id = stateService.getParameter("id");

        forkJoin([
            this.modelService.findOne(id ? id : "create"),
            this.topicService.findAll(),
        ]).subscribe(([model, topics]) => {
            this.geodatenModel = model;
            if (this.geodatenModel.fromdate) {
                this.geodatenModel.fromdate = new Date(this.geodatenModel.fromdate);
            }
            this.topicDataSource = new DataSource({
                store: new ArrayStore({
                    key: "id",
                    data: topics,
                }),
                sort: ({ selector: "name", desc: false }),
            });
        }, () => {
            this.errorMessage = "MODEL_EDIT.LOADING_FAILED";
        });

        this.isUserInTP = this.identityService.isAuthorized(RoleName.TP);
        this.isUserInFS = this.identityService.isAuthorized(RoleName.FS);

        this.onModelFileSelect = this.onModelFileSelect.bind(this);
        this.onGuidelinesFileSelect = this.onGuidelinesFileSelect.bind(this);
    }

    public save(): void {
        this.isLoading = true;

        const files: File[] = [];

        if (this.modelFile) {
            this.modelFile.key = "modelFile";
            files.push(this.modelFile);
        }

        if (this.guidelinesFile) {
            this.guidelinesFile.key = "guidelinesFile";
            files.push(this.guidelinesFile);
        }

        if (this.geodatenModel.fromdate) {
            this.geodatenModel.fromdate = new Date(this.geodatenModel.fromdate);
            this.geodatenModel.fromdate.setHours(12);
        }

        if (isNumber(this.geodatenModel.id)) {
            delete this.geodatenModel.id;
        }

        this.modelService.upload(this.geodatenModel, files).pipe(
            finalize(() => {
                this.isLoading = false;
            })).subscribe({
            next: () => {
                this.abort();
                this.notificationService.notify("Modell gespeichert");
            },
            error: () => {
                this.notificationService.error("Das Modell konnte nicht gespeichert werden.");
            },
        });
    }

    public abort(): void {
        this.stateService.go("app.models.list");
    }

    public onModelFileSelect(files: File[]): void {
        this.modelFile = files[0];
    }

    public onGuidelinesFileSelect(files: File[]): void {
        this.guidelinesFile = files[0];
    }

    public visualizationChanged(): void {
        if (!this.geodatenModel.hasVisualization) {
            this.geodatenModel.mapId = null;
        }
    }

    public geoportalPublicationChanged(): void {
        if (!this.geodatenModel.hasGeoportalPublication) {
            this.geodatenModel.hasOerebPublication = false;
            this.geodatenModel.datastockId = null;
        }
    }

    public get isAuthorizedForTP(): boolean {
        return this.isUserInTP || this.isUserInFS;
    }

    private get isAdminUser(): boolean {
        return Boolean(this.identityService.currentUser?.organisation?.isAdmin);
    }

    public canEditField(field: string): boolean {
        if (!this.canEditModel) {
            return false;
        }

        return !this.geodatenModel.restrictedFields.includes(field) || this.isAdminUser;
    }

    public get canEditModel(): boolean {
        return !this.hasGeoProcessRelevance() || this.isAdminUser;
    }

    private hasGeoProcessRelevance(): boolean {
        return this.geodatenModel.hasVisualization || this.geodatenModel.hasGeoportalPublication || this.geodatenModel.hasOerebPublication;
    }

}
