import { GemeindeService } from "./GemeindeService";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DirtyNavigationWarningFactory, IDirtyNavigationWarning, NotificationService, StateService } from "@geolib/geolib-client";
import IGemeinde from "./IGemeinde";
import { NgForm } from "@angular/forms";
import { UIRouterGlobals } from "@uirouter/angular";
import { isNumber } from "../common/IsNumber";


@Component({
    selector: "gemeinde-edit",
    templateUrl: "./gemeinde-edit.html",
})
export default class GemeindeEditController implements OnInit, OnDestroy {

    public gemeinde: IGemeinde;
    @ViewChild(NgForm) public readonly communityEditForm: NgForm;
    private readonly dirtyNavigationWarning: IDirtyNavigationWarning;

    public constructor(
        private readonly stateService: StateService,
        private readonly gemeindeService: GemeindeService,
        private readonly notificationService: NotificationService,
        private readonly uiRouterGlobals: UIRouterGlobals,
        dirtyNavigationWarningFactory: DirtyNavigationWarningFactory,
    ) {
        this.dirtyNavigationWarning = dirtyNavigationWarningFactory.create("communityEditForm", this.uiRouterGlobals.current.name, this);
        this.gemeinde = this.gemeindeService.createOne();
    }

    public ngOnInit(): void {
        const id = this.stateService.getParameter("id");
        this.gemeindeService.findOne(id ? id : "create").subscribe((gemeinde) => {
            this.gemeinde = gemeinde;
        });
    }

    public save(): void {
        this.gemeinde.primaryArea = this.gemeinde.primaryArea || null;

        if (isNumber(this.gemeinde.id)) {
            delete this.gemeinde.id;
        }

        this.gemeindeService.save(this.gemeinde).subscribe({
            next: () => {
                this.notificationService.notify("Gemeinde gespeichert");
                this.dirtyNavigationWarning.disable();
                this.abort();
            },
            error: () => {
                this.notificationService.error("Gemeinde konnte nicht gespeichert werden.");
            },
        });
    }

    public abort(): void {
        this.stateService.go("app.gemeinden.list");
    }

    public ngOnDestroy(): void {
        this.dirtyNavigationWarning.destroy();
    }

}
