<div>
    <div class="gp-page-header">
        <div class="gp-container-normal">
            <breadcrumb></breadcrumb>
            <div class="gp-page-header-container">
                <h1 class="gp-page-header-title">{{ "DISTRIBUTION.TITLE" | translate }} "{{ editTitle }}"</h1>
                <div class="gp-page-header-actions">
                    <div class="btn-group">
                        <button class="btn btn-default btn-back" (click)="abort()">
                            {{ "NAVIGATION.BACK" | translate }}
                        </button>
                        <gp-button-primary
                            (click)="edit()"
                            translation="DISTRIBUTION.BUTTON.EDIT"
                            [isDisabled]="!hasSelectedItem()"
                        ></gp-button-primary>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="gp-container-normal">
        <dx-data-grid
            [dataSource]="dataSource"
            [showBorders]="true"
            [allowColumnReordering]="true"
            [allowColumnResizing]="true"
            columnResizingMode="widget"
            [rowAlternationEnabled]="true"
            (onRowDblClick)="onRowDblClick()"
            [columnChooser]="columnChooserOptions"
            (onEditorPreparing)="onEditorPreparing($event)"
        >
            <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
            <dxo-search-panel [visible]="true"></dxo-search-panel>
            <dxo-header-filter [visible]="true"></dxo-header-filter>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-sorting mode="multiple" [showSortIndexes]="true"></dxo-sorting>
            <dxo-selection showCheckBoxesMode="never" mode="single"></dxo-selection>
            <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
            <dxo-toolbar>
                <dxi-item>
                    <dx-button
                        (onClick)="toggleColumnChooser()"
                        [type]="hasHiddenColumns() ? 'danger' : 'normal'"
                        icon="column-chooser"
                        [hint]="getDevExpressTranslation('dxDataGrid-columnChooserTitle')"
                    ></dx-button>
                </dxi-item>
                @if (isAdmin) {
                    <dxi-item>
                        <dx-button
                            (onClick)="resetGridSettings()"
                            type="normal"
                            icon="revert"
                            [hint]="'GRID.REVERT_SETTINGS' | translate"
                        ></dx-button>
                    </dxi-item>
                }
            </dxo-toolbar>
            @for (column of dataGridColumns; track column) {
                <dxi-column
                    [allowResizing]="true"
                    [allowSorting]="true"
                    [dataField]="column.dataField"
                    [caption]="column.caption"
                    [visible]="column.visible"
                    [width]="column.width"
                    [sortOrder]="column.sortOrder"
                    [cellTemplate]="column.cellTemplate"
                ></dxi-column>
            }
            <div *dxTemplate="let cell of 'typeTemplate'">
                <div class="cell-overflow">{{ cell.data.type ? ("DISTRIBUTION.TYPES." + cell.data.type.toUpperCase() | translate) : "" }}</div>
            </div>
            <div *dxTemplate="let cell of 'targetTemplate'">
                <div class="cell-overflow">{{ getTargetValue(cell.data) }}</div>
            </div>
        </dx-data-grid>
        <div class="gp-margin-top-big flex-start-container float-right">
            <button class="btn btn-default" (click)="delete()" [disabled]="!isChangeDistributionAllowed || !hasSelectedItem()">
                {{ "DISTRIBUTION.BUTTON.DELETE" | translate }}
            </button>
            <button class="btn btn-default" (click)="create()" [disabled]="!isChangeDistributionAllowed">
                {{ "DISTRIBUTION.BUTTON.NEW" | translate }}
            </button>
        </div>
    </div>
</div>
