import { Injectable } from "@angular/core";
import GeobusNavigationExtension from "./geobus-navigation.extension";


@Injectable({ providedIn: "root" })
export default class GeobusOrganisationExtension extends GeobusNavigationExtension {

    public get category(): string {
        return "user_and_organisation";
    }

    public get state(): { name: string } {
        return { name: "app.organisations" };
    }

    public get label(): string {
        return "SIDE_MENU.USER_ORGANISATION.ORGANISATION";
    }

    public get icon(): string {
        return "users";
    }

}
