import { Injectable } from "@angular/core";
import { IUiStateCurrent, StateService } from "@geolib/geolib-client";
import { TranslateService } from "@ngx-translate/core";
import { StateObject } from "@uirouter/angular";


export interface IBreadcrumb {
    breadcrumbTitle: string;
    isBreadcrumbLoading: boolean;
    url: { pattern: string };
    name?: string;
    redirectTo?: StateObject["redirectTo"];
}

@Injectable({ providedIn: "root" })
export class BreadcrumbService {

    public breadcrumbs: IBreadcrumb[];

    public constructor(
        private readonly stateService: StateService,
        private readonly translateService: TranslateService,
    ) {
    }

    public stateChangeListener(): void {
        this.breadcrumbs = [{
            breadcrumbTitle: "BREADCRUMB.HOME",
            isBreadcrumbLoading: false,
            url: {
                pattern: "/dashboard",
            },
            name: "app.main",
        }];

        const currentState = this.stateService.getCurrentState();
        this.readState(currentState);

    }

    // eslint-disable-next-line complexity
    public readState(state: StateObject): void {
        if (state.parent) {
            this.readState(state.parent);
        }

        if (state.url && state.url.pattern.length && !state.abstract && this.checkforAppState(state) === false) {
            this.breadcrumbs.push({
                url: { pattern: state.url.pattern },
                breadcrumbTitle: `BREADCRUMB.${state.self.name?.toUpperCase()}`,
                isBreadcrumbLoading: state.self.data?.isBreadcrumbLoading,
                redirectTo: state.redirectTo,
                name: state.self.name,
            });
        }

        this.setDocumentTitle({});
    }

    public setDocumentTitle(param: IUiStateCurrent | object): void {
        const separator = " - ";
        const breadcrumb = this.breadcrumbs.map((state) => state.breadcrumbTitle);

        const systemTranslations = {
            "BREADCRUMB.HOME": "GEOBUS",
            ...param,
        };

        this.translateService.get(breadcrumb).subscribe((translations: any) => {
            const allTranslations = { ...translations, ...systemTranslations };

            const documentTitle = this.breadcrumbs.map((state) => {
                return allTranslations[state.breadcrumbTitle];
            });

            window.document.title = `${documentTitle.join(separator)}`;
        });
    }

    private checkforAppState(state: StateObject): boolean {
        return state.name === "app";
    }
}
