import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";


@Injectable({ providedIn: "root" })
export class IntegrationService {

    public constructor(
        private readonly httpClient: HttpClient,
    ) {
    }

    public startIntegration(datasetId: number): Observable<void> {
        return this.httpClient.post<void>(`/api/integration/${datasetId}`, null);
    }

}
