<div class="gp-page-header">
    <div class="gp-container-normal">
        <breadcrumb></breadcrumb>
        @if (geodatenModel && modelEditForm) {
            <div class="gp-page-header-container">
                @if (geodatenModel.name) {
                    <h1 class="gp-page-header-title">{{ geodatenModel.name }}</h1>
                }
                @if (!geodatenModel.name) {
                    <h1 class="gp-page-header-title">{{ "MODEL_EDIT.TITLE" | translate }}</h1>
                }
                <div class="gp-page-header-actions">
                    <div class="btn-group">
                        <button class="btn btn-default btn-back" (click)="abort()">
                            {{ "NAVIGATION.BACK" | translate }}
                        </button>
                        <gp-button-primary
                            (click)="save()"
                            [isDisabled]="!modelEditForm.valid || isLoading || !dateBox?.isValid || !canEditModel"
                            translation="NAVIGATION.SAVE"
                        ></gp-button-primary>
                        @if (isLoading) {
                            <gp-spinner class="loading-spinner" [visible]="true"></gp-spinner>
                        }
                    </div>
                </div>
            </div>
        }
    </div>
</div>
<div class="gp-container-normal">
    @if (!errorMessage && !geodatenModel) {
        <gp-spinner [visible]="true"></gp-spinner>
    }
    @if (errorMessage) {
        <div class="alert alert-danger">{{ errorMessage | translate }}</div>
    }

    @if (geodatenModel) {
        <form #modelEditForm="ngForm" (ngSubmit)="save()">
            <div class="row">
                <label class="col-md-6" [class.has-error]="prmModelName.invalid">
                    <div class="col-form-label">{{ "MODEL_EDIT.MODEL" | translate }}</div>
                    <input
                        class="form-control"
                        #prmModelName="ngModel"
                        [required]="true"
                        [(ngModel)]="geodatenModel.name"
                        name="ModelInput"
                        [disabled]="!canEditModel"
                        pattern="[^\s]+"
                    />
                    @if (prmModelName.invalid) {
                        <ul class="gp-form-control-error">
                            <li>Leerzeichen sind nicht erlaubt</li>
                        </ul>
                    }
                </label>
                <label class="col-md-6">
                    <div class="col-form-label">{{ "MODEL_EDIT.FILE" | translate }}</div>
                    @if (canEditModel) {
                        <div class="row">
                            <div class="col-md-12">
                                <gp-file (onFileSelected)="onModelFileSelect($event)"></gp-file>
                            </div>
                        </div>
                    }
                    @if (geodatenModel.file) {
                        <div class="row">
                            <div class="col-md-12">
                                <small>{{ "MODEL_EDIT.CURRENT" | translate }} {{ geodatenModel.file.name }}</small>
                            </div>
                        </div>
                    }
                </label>
            </div>
            <div class="row">
                <label class="col-md-3">
                    <div class="col-form-label">{{ "MODEL_EDIT.VERSION" | translate }}</div>
                    <input
                        class="form-control"
                        type="text"
                        maxlength="20"
                        [required]="true"
                        [(ngModel)]="geodatenModel.version"
                        name="Version"
                        [disabled]="!canEditModel"
                    />
                </label>
                <label class="col-md-3">
                    <div class="col-form-label">{{ "MODEL_EDIT.PRIVATE" | translate }}</div>
                    <div class="btn-group">
                        <dx-switch
                            [(ngModel)]="geodatenModel.isSiaModel"
                            switchedOnText="Ja"
                            switchedOffText="Nein"
                            name="isSiaModel"
                            class="switch-danger"
                            [disabled]="!canEditModel"
                        ></dx-switch>
                    </div>
                </label>
                <label class="col-md-6">
                    <div class="col-form-label">{{ "MODEL_EDIT.DESCRIPTION" | translate }}</div>
                    @if (canEditModel) {
                        <div class="row">
                            <div class="col-md-12">
                                <gp-file (onFileSelected)="onGuidelinesFileSelect($event)"></gp-file>
                            </div>
                        </div>
                    }
                    @if (geodatenModel.guideline) {
                        <div class="row">
                            <div class="col-md-12">
                                <small>{{ "MODEL_EDIT.CURRENT" | translate }} {{ geodatenModel.guideline?.name }}</small>
                            </div>
                        </div>
                    }
                </label>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="col-form-label">{{ "MODEL_EDIT.FORMAT" | translate }}</div>
                    <dx-select-box
                        [dataSource]="formatDataSource"
                        [(ngModel)]="geodatenModel.format"
                        displayExpr="value"
                        valueExpr="key"
                        name="formatBox"
                        [required]="true"
                        [disabled]="!canEditModel"
                        [searchEnabled]="true"
                    ></dx-select-box>
                </div>
                @if (geodatenModel.format === "interlis1" || geodatenModel.format === "interlis2") {
                    <div class="col-md-6">
                        <div class="col-form-label">{{ "MODEL_EDIT.MODEL_TYPE" | translate }}</div>
                        <dx-select-box
                            [dataSource]="interlisFormatDataSource"
                            [(ngModel)]="geodatenModel.interlisFormat"
                            displayExpr="value"
                            valueExpr="key"
                            name="interlisFormatBox"
                            [required]="true"
                            [disabled]="!canEditModel"
                            [searchEnabled]="true"
                        ></dx-select-box>
                    </div>
                }
            </div>
            <div class="row">
                <label class="col-md-3">
                    <div class="col-form-label">{{ "MODEL_EDIT.DATE" | translate }}</div>
                    <p class="input-group">
                        <dx-date-box
                            [(ngModel)]="geodatenModel.fromdate"
                            applyValueMode="instantly"
                            name="fromdate"
                            displayFormat="dd.MM.yyyy"
                            type="date"
                            [inputAttr]="{ 'aria-label': 'Date' }"
                            [disabled]="!canEditModel"
                        ></dx-date-box>
                    </p>
                </label>
                @if (isAuthorizedForTP && geodatenModel.hasVisualization) {
                    <label class="col-md-6 offset-md-3">
                        <div class="col-form-label">{{ "MODEL_EDIT.MAP_ID" | translate }}</div>
                        <input
                            class="form-control"
                            [required]="true"
                            [(ngModel)]="geodatenModel.mapId"
                            name="mapId"
                            [disabled]="!canEditField('mapId')"
                        />
                    </label>
                }
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="col-form-label">{{ "MODEL_EDIT.TOPIC" | translate }}</div>
                    <dx-select-box
                        [dataSource]="topicDataSource"
                        [(ngModel)]="geodatenModel.topicId"
                        displayExpr="name"
                        valueExpr="id"
                        [searchEnabled]="true"
                        searchMode="contains"
                        searchExpr="name"
                        name="topicsBox"
                        autofocus="true"
                        [required]="true"
                        [disabled]="!canEditModel"
                    ></dx-select-box>
                </div>
                @if (geodatenModel.hasGeoportalPublication) {
                    <label class="col-md-6">
                        <div class="col-form-label">{{ "MODEL_EDIT.TRACKING_ID" | translate }}</div>
                        <input
                            class="form-control"
                            [required]="true"
                            [(ngModel)]="geodatenModel.datastockId"
                            name="datastockId"
                            [disabled]="!canEditField('datastockId')"
                        />
                    </label>
                }
            </div>
            <div class="row">
                <label class="col-md-3">
                    <div class="col-form-label">{{ "MODEL_EDIT.TP" | translate }}</div>
                    <div class="btn-group">
                        <dx-switch
                            [(ngModel)]="geodatenModel.hasExtendedTechnicalCheck"
                            switchedOnText="Ja"
                            switchedOffText="Nein"
                            name="hasExtendedTechnicalCheck"
                            class="switch-danger"
                            [disabled]="!canEditModel"
                        ></dx-switch>
                    </div>
                </label>
                @if (isAuthorizedForTP) {
                    <label class="col-md-3">
                        <div class="col-form-label">{{ "MODEL_EDIT.VISUALIZATION" | translate }}</div>
                        <div class="btn-group">
                            <dx-switch
                                [(ngModel)]="geodatenModel.hasVisualization"
                                switchedOnText="Ja"
                                switchedOffText="Nein"
                                (ngModelChange)="visualizationChanged()"
                                name="hasVisualization"
                                class="switch-danger"
                                [disabled]="!canEditField('hasVisualization')"
                            ></dx-switch>
                        </div>
                    </label>
                }
            </div>
            <div class="row">
                @if (isAuthorizedForTP) {
                    <label class="col-md-3">
                        <div class="col-form-label">{{ "MODEL_EDIT.GEOPORTAL" | translate }}</div>
                        <div class="btn-group">
                            <dx-switch
                                [(ngModel)]="geodatenModel.hasGeoportalPublication"
                                switchedOnText="Ja"
                                switchedOffText="Nein"
                                (ngModelChange)="geoportalPublicationChanged()"
                                name="hasGeoportalPublication"
                                class="switch-danger"
                                [disabled]="!canEditField('hasGeoportalPublication')"
                            ></dx-switch>
                        </div>
                    </label>
                }
                @if (geodatenModel.hasGeoportalPublication) {
                    <label class="col-md-3">
                        <div class="col-form-label">{{ "MODEL_EDIT.ÖREB" | translate }}</div>
                        <div class="btn-group">
                            <dx-switch
                                [(ngModel)]="geodatenModel.hasOerebPublication"
                                switchedOnText="Ja"
                                switchedOffText="Nein"
                                name="hasOerebPublication"
                                class="switch-danger"
                                [disabled]="!canEditField('hasOerebPublication')"
                            ></dx-switch>
                        </div>
                    </label>
                }
            </div>
        </form>
    }
</div>
