import { HomePageLogoExtension } from "@geolib/geoappbase-client";
import { Injectable } from "@angular/core";


@Injectable({ providedIn: "root" })
export class GeobusStartPageLogoExtension extends HomePageLogoExtension {

    public isEnabled(): boolean {
        return true;
    }

    public get imageUrl(): string {
        return "../../images/logo/GEOBUS.svg";
    }

    public get logoClaim(): string {
        return "";
    }

}
