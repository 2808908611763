<div class="gp-page-header">
    <div class="gp-container gp-container-extra-large">
        <breadcrumb></breadcrumb>
        <div class="gp-page-header-container">
            <h1 class="gp-page-header-title">{{ "DATASET_EDIT.TITLE" | translate }}</h1>
            <div class="gp-page-header-actions">
                <div class="btn-group">
                    <button class="btn btn-default btn-back" (click)="abort()">
                        {{ "NAVIGATION.BACK" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="gp-container gp-container-extra-large text-nowrap">
    <div class="col-12">
        <div class="row">
            <h3>{{ "DATASET_EDIT.STATUS" | translate }}</h3>
        </div>
        <div class="row status">{{ extStatus }}</div>
    </div>
    <div class="row gx-5">
        <div class="col">
            <div class="card-layout">
                <div class="row">
                    <div class="col-12 col-lg-6 col-sm-6">
                        <h3>{{ "DATASET_EDIT.DATASET.TITLE" | translate }}</h3>
                    </div>
                </div>
                @if (odataset) {
                    <div class="row">
                        <div class="col-12 col-lg-6 col-sm-6">
                            <div class="row card-line">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.DATASET.LABEL.TOPIC" | translate }}</div>
                                </div>
                                <div class="col">{{ odataset.topicName }}</div>
                            </div>
                            <div class="row card-line">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.DATASET.LABEL.AREA" | translate }}</div>
                                </div>
                                <div class="col">{{ odataset.gemeindeName }}</div>
                            </div>
                            <div class="row card-line">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.DATASET.LABEL.MODEL" | translate }}</div>
                                </div>
                                <div class="col">{{ odataset.modelName }}</div>
                            </div>
                            <div class="row card-line">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.DATASET.LABEL.MODEL_VERSION" | translate }}</div>
                                </div>
                                <div class="col">{{ odataset.modelVersion }}</div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-sm-6">
                            <div class="row card-line">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.DATASET.LABEL.CHECKOUT_DATE" | translate }}</div>
                                </div>
                                <div class="col">{{ ocheckoutDate }}</div>
                            </div>
                            <div class="row card-line">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.DATASET.LABEL.CHECKOUT_USER" | translate }}</div>
                                </div>
                                <div class="col">{{ ocheckoutUserName }}</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div class="card-layout">
                <div class="row">
                    <div class="col-12 col-lg-6 col-sm-6">
                        <h3>{{ "DATASET_EDIT.TECH_CHECK.TITLE" | translate }}</h3>
                    </div>
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="row">
                            <div class="btn-group">
                                <button class="btn btn-default btn-back" (click)="showReport()">
                                    {{ "DATASET_EDIT.TECH_CHECK.BUTTON.REPORT" | translate }}
                                </button>
                                @if (isTechApproveAllowed()) {
                                    <gp-button-primary
                                        (click)="showTechRejectModal()"
                                        [isDisabled]="isTechApproveSelectedItemLocked()"
                                        translation="DATASET_EDIT.BUTTON.CHECK"
                                    ></gp-button-primary>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="row card-line">
                            <div class="col">
                                <div class="gb-label">{{ "DATASET_EDIT.LABEL.RESULT" | translate }}</div>
                            </div>
                            <div class="col">{{ otechnicalcheck }}</div>
                        </div>
                        <div class="row card-line">
                            <div class="col">
                                <div class="gb-label">{{ "DATASET_EDIT.TECH_CHECK.LABEL.FILE" | translate }}</div>
                            </div>
                            <div class="col">{{ ocheckinFileName }}</div>
                        </div>
                        <div class="row card-line-no-border">
                            <div class="col">
                                <div class="gb-label">{{ "DATASET_EDIT.LABEL.NOTE" | translate }}</div>
                            </div>
                            <div class="col note">{{ ocheckinNote }}</div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="row card-line">
                            <div class="col">
                                <div class="gb-label">{{ "DATASET_EDIT.TECH_CHECK.LABEL.CHECKIN_DATE" | translate }}</div>
                            </div>
                            <div class="col">{{ ocheckinDate }}</div>
                        </div>
                        <div class="row card-line">
                            <div class="col">
                                <div class="gb-label">{{ "DATASET_EDIT.TECH_CHECK.LABEL.CHECKIN_USER" | translate }}</div>
                            </div>
                            <div class="col">{{ ocheckinUserName }}</div>
                        </div>
                    </div>
                </div>
            </div>
            @if (hasManualTech()) {
                <div class="card-layout">
                    <div class="row">
                        <div class="col-12 col-lg-6 col-sm-6">
                            <h3>{{ "DATASET_EDIT.MANUAL_TECH.TITLE" | translate }}</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6 col-sm-6">
                            <div class="row card-line-no-border">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.LABEL.NOTE" | translate }}</div>
                                </div>
                                <div class="col note">{{ omanualApproveNote }}</div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-sm-6">
                            <div class="row card-line">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.LABEL.DATE" | translate }}</div>
                                </div>
                                <div class="col">{{ omanualApproveDate }}</div>
                            </div>
                            <div class="row card-line">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.LABEL.USER" | translate }}</div>
                                </div>
                                <div class="col">{{ omanualApproveUser?.username }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            @if (hasExtendendTechnicalCheck()) {
                <div class="card-layout">
                    <div class="row">
                        <div class="col-12 col-lg-6 col-sm-6">
                            <h3>{{ "DATASET_EDIT.EXTENDED_TECH.TITLE" | translate }}</h3>
                        </div>
                        <div class="col-12 col-lg-6 col-sm-6">
                            <div class="row">
                                <div class="btn-group">
                                    <button class="btn btn-default btn-back" (click)="showTestProtocol()">
                                        {{ "DATASET_EDIT.EXTENDED_TECH.BUTTON.PROTOCOL" | translate }}
                                    </button>
                                    @if (isExtendedTechApproveAllowed()) {
                                        <gp-button-primary
                                            (click)="showExtendedTechRejectModal()"
                                            [isDisabled]="isTechExtendedApproveSelectedItemLocked()"
                                            translation="DATASET_EDIT.BUTTON.CHECK"
                                        ></gp-button-primary>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6 col-sm-6">
                            <div class="row card-line">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.EXTENDED_TECH.LABEL.STATUS" | translate }}</div>
                                </div>
                                <div class="col">{{ extendedTechnicalCheckStatus }}</div>
                            </div>
                            <div class="row card-line">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.LABEL.PROTOCOL" | translate }}</div>
                                </div>
                                <div class="col">{{ nameCheckProtocol }}</div>
                            </div>
                            <div class="row card-line-no-border">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.LABEL.NOTE" | translate }}</div>
                                </div>
                                <div class="col note">{{ extendedTechnicalCheckNote }}</div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-sm-6">
                            <div class="row card-line">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.LABEL.DATE" | translate }}</div>
                                </div>
                                <div class="col">{{ extendedTechnicalCheckDate }}</div>
                            </div>
                            <div class="row card-line">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.LABEL.USER" | translate }}</div>
                                </div>
                                <div class="col">{{ extendedUserName }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            @if (hasVisualization()) {
                <div class="card-layout">
                    <div class="row">
                        <div class="col-12 col-lg-6 col-sm-6">
                            <h3>{{ "DATASET_EDIT.VISUALIZATION.TITLE" | translate }}</h3>
                        </div>
                        <div class="col-12 col-lg-6 col-sm-6">
                            <div class="row">
                                <div class="btn-group">
                                    @if (hasVisualization()) {
                                        <button
                                            class="btn btn-default btn-back"
                                            (click)="downloadIntegrationProtocol()"
                                            [disabled]="!isVisualizationable()"
                                        >
                                            {{ "DATASET_EDIT.VISUALIZATION.BUTTON.PROTOCOL" | translate }}
                                        </button>
                                    }
                                    @if (hasVisualization()) {
                                        <gp-button-primary
                                            (click)="showDatasetInGeobusApp()"
                                            [isDisabled]="!isVisualizationable()"
                                            translation="DATASET_EDIT.VISUALIZATION.BUTTON.VISUALIZATION"
                                        ></gp-button-primary>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-lg-6 col-sm-6">
                            <div class="row card-line">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.VISUALIZATION.LABEL.TOTAL" | translate }}</div>
                                </div>
                                <div class="col">{{ getTotalCount() }}</div>
                            </div>
                            <div class="row card-line">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.VISUALIZATION.LABEL.DATA_EDIT" | translate }}</div>
                                </div>
                                <div class="col">{{ dataset?.countEditedData }} {{ "DATASET_EDIT.VISUALIZATION.OBJECT" | translate }}</div>
                            </div>
                            <div class="row card-line">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.VISUALIZATION.LABEL.GEOM_EDIT" | translate }}</div>
                                </div>
                                <div class="col">{{ dataset?.countEditedGeom }} {{ "DATASET_EDIT.VISUALIZATION.OBJECT" | translate }}</div>
                            </div>
                        </div>
                        <div class="col-12 col-lg-6 col-sm-6">
                            <div class="row card-line">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.VISUALIZATION.LABEL.NEW" | translate }}</div>
                                </div>
                                <div class="col">{{ dataset?.countNew }} {{ "DATASET_EDIT.VISUALIZATION.OBJECT" | translate }}</div>
                            </div>
                            <div class="row card-line">
                                <div class="col">
                                    <div class="gb-label">{{ "DATASET_EDIT.VISUALIZATION.LABEL.DELETED" | translate }}</div>
                                </div>
                                <div class="col">{{ dataset?.countRemoved }} {{ "DATASET_EDIT.VISUALIZATION.OBJECT" | translate }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div class="card-layout">
                <div class="row">
                    <div class="col-12 col-lg-6 col-sm-6">
                        <h3>{{ "DATASET_EDIT.EXPERT_CHECK.TITLE" | translate }}</h3>
                    </div>
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="row">
                            <div class="btn-group">
                                @if (isApproveAllowed()) {
                                    <gp-button-primary
                                        (click)="showProRejectModal()"
                                        [isDisabled]="isApproveSelectedItemLocked()"
                                        translation="DATASET_EDIT.BUTTON.CHECK"
                                    ></gp-button-primary>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="row card-line">
                            <div class="col">
                                <div class="gb-label">{{ "DATASET_EDIT.LABEL.RESULT" | translate }}</div>
                            </div>
                            <div class="col">{{ expertcheck }}</div>
                        </div>
                        <div class="row card-line">
                            <div class="col">
                                <div class="gb-label">{{ "DATASET_EDIT.LABEL.PROTOCOL" | translate }}</div>
                            </div>
                            <div class="col">{{ nameCheckProtocol }}</div>
                        </div>
                        <div class="row card-line-no-border">
                            <div class="col">
                                <div class="gb-label">{{ "DATASET_EDIT.LABEL.NOTE" | translate }}</div>
                            </div>
                            <div class="col note">{{ expertcheckNote }}</div>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 col-sm-6">
                        <div class="row card-line">
                            <div class="col">
                                <div class="gb-label">{{ "DATASET_EDIT.LABEL.DATE" | translate }}</div>
                            </div>
                            <div class="col">{{ approvedDate }}</div>
                        </div>
                        <div class="row card-line">
                            <div class="col">
                                <div class="gb-label">{{ "DATASET_EDIT.LABEL.USER" | translate }}</div>
                            </div>
                            <div class="col">{{ approver?.username }}</div>
                        </div>
                    </div>
                </div>
            </div>
            @if (hasGeoportalPublication()) {
                <div class="card-layout">
                    <div class="row">
                        <div class="col-12 col-lg-6 col-sm-6">
                            <h3>{{ "DATASET_EDIT.PUBLICATION.TITLE" | translate }}</h3>
                        </div>
                    </div>
                    <div class="nested-card-layout gp-margin-top-none">
                        <div class="row">
                            <h4>{{ "DATASET_EDIT.PUBLICATION.TITLE_FTP" | translate }}</h4>
                        </div>
                        <div class="row">
                            <div class="col-12 col-lg-6 col-sm-6">
                                <div class="row card-line">
                                    <div class="col">
                                        <div class="gb-label">{{ "DATASET_EDIT.LABEL.RESULT" | translate }}</div>
                                    </div>
                                    <div class="col">{{ geoportalFtpUploadSucceeded }}</div>
                                </div>
                            </div>
                            <div class="col-12 col-lg-6 col-sm-6">
                                <div class="row card-line">
                                    <div class="col">
                                        <div class="gb-label">{{ "DATASET_EDIT.LABEL.DATE" | translate }}</div>
                                    </div>
                                    <div class="col">{{ geoportalFtpUploadDate }}</div>
                                </div>
                                <div class="row card-line">
                                    <div class="col">
                                        <div class="gb-label">{{ "DATASET_EDIT.LABEL.USER" | translate }}</div>
                                    </div>
                                    <div class="col">{{ dataset.geoportalFtpUploadUser?.username }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="nested-card-layout">
                        <div class="row">
                            <div class="col">
                                <div class="row">
                                    <h4>{{ "DATASET_EDIT.PUBLICATION.TITLE_GEOPORTAL" | translate }}</h4>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-lg-6 col-sm-6">
                                        <div class="row card-line">
                                            <div class="col">
                                                <div class="gb-label">{{ "DATASET_EDIT.LABEL.RESULT" | translate }}</div>
                                            </div>
                                            <div class="col">{{ geoportalPublishSucceeded }}</div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-6 col-sm-6">
                                        <div class="row card-line">
                                            <div class="col">
                                                <div class="gb-label">{{ "DATASET_EDIT.LABEL.DATE" | translate }}</div>
                                            </div>
                                            <div class="col">{{ geoportalPublishDate }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    @if (hasOerebPublication()) {
                        <div class="nested-card-layout">
                            <div class="row">
                                <div class="row">
                                    <div class="col">
                                        <div class="row">
                                            <div class="col-12 col-lg-6 col-sm-6">
                                                <h4>{{ "DATASET_EDIT.PUBLICATION.TITLE_ÖREB" | translate }}</h4>
                                            </div>
                                            <div class="col-12 col-lg-6 col-sm-6">
                                                @if (canBePublicatedToOereb()) {
                                                    <div class="row">
                                                        <div class="btn-group">
                                                            <gp-button-primary
                                                                (click)="publicateOereb()"
                                                                translation="DATASET_EDIT.PUBLICATION.BUTTON.PUBLICATION"
                                                                [isDisabled]="publicateOerebIsLoading"
                                                            ></gp-button-primary>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-lg-6 col-sm-6">
                                                <div class="row card-line">
                                                    <div class="col">
                                                        <div class="gb-label">{{ "DATASET_EDIT.PUBLICATION.LABEL.APPROVAL_DATE" | translate }}</div>
                                                    </div>
                                                    <div class="col">{{ oerebShareDate }}</div>
                                                </div>
                                                <div class="row card-line">
                                                    <div class="col">
                                                        <div class="gb-label">{{ "DATASET_EDIT.PUBLICATION.LABEL.USER" | translate }}</div>
                                                    </div>
                                                    <div class="col">{{ dataset.oerebShareUser?.username }}</div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-6 col-sm-6">
                                                <div class="row card-line">
                                                    <div class="col">
                                                        <div class="gb-label">
                                                            {{ "DATASET_EDIT.PUBLICATION.LABEL.PUBLICATION_DATE" | translate }}
                                                        </div>
                                                    </div>
                                                    <div class="col">{{ oerebPublishDate }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
</div>
