<div class="gp-header-map-info">
    <div
        class="primary-area-text"
        title="{{ extensionData?.primaryAreaName }}"
        [ngClass]="{ 'with-sub-title': !!extensionData?.primaryAreaSubTitle }"
    >
        {{ extensionData?.primaryAreaName }}
    </div>
    @if (!!extensionData?.primaryAreaSubTitle) {
        <div class="primary-area-text sub-title" title="{{ extensionData?.primaryAreaSubTitle }}">
            {{ extensionData?.primaryAreaSubTitle }}
        </div>
    }
</div>
