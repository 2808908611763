import { MoreNavigationLinkExtension } from "@geolib/geoappbase-client";
import { Injectable } from "@angular/core";


@Injectable({ providedIn: "root" })

export default class GeobusHelpExtension extends MoreNavigationLinkExtension {

    public isEnabled(): boolean {
        return true;
    }

    public getTemplate(): string {
        return "";
    }

    public get title(): string {
        return "HELP";
    }

    public get linkUrl(): string {
        return "https://help.geoportal.ch/GEOBUS";
    }
}

