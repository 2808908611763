import { Component } from "@angular/core";


@Component({
    selector: "app-component",
    templateUrl: "./appComponent.html",
})
export default class AppComponent {

}
