import { NotificationService, StateService } from "@geolib/geolib-client";
import { UserService } from "./UserService";
import { OrganisationService } from "../organisation/OrganisationService";
import { PasswordPolicy } from "../common/PasswordPolicy";
import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import IUser from "./IUser";
import IOrganisation from "../organisation/IOrganisation";
import { forkJoin } from "rxjs";
import { FormControl, NgForm, NgModel } from "@angular/forms";
import { isNumber } from "../common/IsNumber";


@Component({
    selector: "user-edit",
    templateUrl: "./user-edit.html",
})
export default class UserEditController {

    @ViewChild(NgForm) public readonly userEditForm: NgForm;
    @ViewChild("prmEmail") public readonly prmEmail: FormControl;
    @ViewChild("password", { read: NgModel }) public readonly passwordModel: NgModel;

    public user: IUser;
    public organisations: IOrganisation[] = [];
    public userAlreadyExist: boolean = false;
    public password: string;
    public errorPassword?: string;
    public validPassword: boolean = true;
    public salutationOptions: Array<{ key: string; value: string }> = [];

    public constructor(
        private readonly stateService: StateService,
        private readonly userService: UserService,
        private readonly organisationService: OrganisationService,
        private readonly passwordPolicy: PasswordPolicy,
        private readonly changeDetectorRef: ChangeDetectorRef,
        private readonly notificationService: NotificationService,
    ) {
        this.password = "";
        this.salutationOptions = this.userService.salutationOptions;

        const id = this.stateService.getParameter("id");
        forkJoin([
            this.userService.findOne(id ? id : "create"),
            this.organisationService.findAll(),
        ]).subscribe(([user, organisations]) => {
            this.organisations = organisations;
            this.user = user;
            this.validPwdCheck();
            this.changeDetectorRef.detectChanges();
        });
    }

    public save(): void {
        this.userService.checkIfUniqueUsername(this.user).subscribe((uniqueCheck) => {
            this.userAlreadyExist = !uniqueCheck.usernameIsUnique;

            if (!uniqueCheck.usernameIsUnique) {
                this.notificationService.error("USER_EDIT.ERROR_MESSAGE.USERNAME");
                return;
            }

            if (isNumber(this.user.id)) {
                delete this.user.id;
            }

            this.userService.save(this.user).subscribe(() => {
                this.notificationService.notify("SYSTEM.DEFAULT_SAVE_SUCCESS");
                this.abort();
            }, () => {
                this.notificationService.error("SYSTEM.DEFAULT_SAVE_ERROR");
            });
        });
    }

    public abort(): void {
        this.stateService.go("app.users.list");
    }

    public validPwdCheck(): void {
        if (!this.passwordModel?.dirty || (this.user?.id && !this.user.newPassword)) {
            this.errorPassword = "";
            this.validPassword = true;
            return;
        }

        const validationResult = this.passwordPolicy.validate(this.user.newPassword);
        this.errorPassword = validationResult.message;
        this.validPassword = validationResult.valid;
    }

    public isNewUser(): boolean {
        return !this.user?.id || isNumber(this.user.id);
    }

    public organisationChanged(): void {
        const organisation = this.organisations.find((organisation) => organisation.id === this.user.organisationId);

        if (organisation) {
            this.user.organisation = organisation;
        }
    }

    public isFormValid(): boolean {
        return Boolean(this.userEditForm?.valid && this.validPassword && this.prmEmail?.valid);
    }

}
