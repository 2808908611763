import { Injectable } from "@angular/core";

import { FeedbackHeaderComponent, MyGeoportalHeaderExtension } from "@geolib/geoappbase-client";

@Injectable({ providedIn: "root" })
export class MyGeoportalFeedbackHeaderExtension extends MyGeoportalHeaderExtension<FeedbackHeaderComponent> {

    public isEnabled(): boolean {
        return true;
    }

    public get component(): typeof FeedbackHeaderComponent {
        return FeedbackHeaderComponent;
    }

    public getClassName(): string {
        return "gp-screen-mobile-small-hide";
    }

}
