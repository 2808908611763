import IDataset from "./IDataset";
import IDatasetTrackingProtocol from "./IDatasetTrackingProtocol";
import ITrackingSpecification from "./ITrackingSpecification";
import { BackendService } from "@geolib/geolib-client";
import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
import IFile from "../../../common/model/IFile";
import { ITrackingDashboard, ITrackingSpecificationList } from "../../../common/tracking/ITrackingSpecificationList";
import ITopic from "../topic/ITopic";
import IGemeinde from "../gemeinde/IGemeinde";
import { map } from "rxjs/operators";
import IDistribution from "./IDistribution";
import { QueryParamsOptions } from "@geolib/geolib-client/dist/common/BackendService";


@Injectable({ providedIn: "root" })
export class TrackingService extends BackendService<ITrackingSpecification, string> {

    public constructor(
        http: HttpClient,
    ) {
        super("/api/tracking", http);
    }

    public findAllDtoList(): Observable<ITrackingSpecificationList[]> {
        return this.findAll().pipe(
            map((list) => {
                // todo: proper typing
                return list as unknown as ITrackingSpecificationList[];
            }),
        );
    }

    public findPending(): Observable<ITrackingDashboard[]> {
        return this.http.get<ITrackingDashboard[]>(`/api/tracking/pending`);
    }

    public findDataset(trackingSpecificationId: string, datasetId: number, params?: QueryParamsOptions): Observable<IDataset> {
        return this.http.get<IDataset>(`/api/tracking/${trackingSpecificationId}/dataset/${datasetId}`, { params });
    }

    public findDatasets(trackingSpecificationId: string): Observable<IDataset[]> {
        return this.http.get<IDataset[]>(`/api/tracking/${trackingSpecificationId}/dataset`);
    }

    public checkout(trackingSpecificationId: string): Observable<void> {
        return this.http.post<void>(`/api/tracking/${trackingSpecificationId}/dataset`, null);
    }

    public checkin(trackingSpecificationId: string, datasetId: string, datasetFile: Blob, checkinNote: string): Observable<void> {
        return this.uploadFile(`/api/tracking/${trackingSpecificationId}/dataset/${datasetId}`, datasetFile, { checkinNote: checkinNote || "" });
    }

    public getTrackingProtocol(trackingSpecificationId: string): Observable<IDatasetTrackingProtocol[]> {
        return this.http.get<IDatasetTrackingProtocol[]>(`/api/tracking/${trackingSpecificationId}/protocol`);
    }

    // todo: keine Aufruf gefunden und in der Routes ist ein "get" anstatt eines "post" ?!!
    // public uploadTestProtocol(datasetId, datasetFile) {
    //     return this.uploadFile(`/api/tracking/testprotocol/${datasetId}`, datasetFile, {});
    // }

    public approveDataset(dataset: IDataset): Observable<void> {
        return this.http.post<void>(`/api/tracking/approvedataset/${dataset.id}`, dataset);
    }

    public rejectDataset(dataset: IDataset): Observable<void> {
        return this.http.post<void>(`/api/tracking/rejectdataset/${dataset.id}`, dataset);
    }

    public technicallyApproveDataset(dataset: IDataset): Observable<void> {
        return this.http.post<void>(`/api/tracking/techapprovedataset/${dataset.id}`, dataset);
    }

    public extendedTechnicallyApproveDataset(dataset: IDataset, datasetFile: Blob): Observable<void> {
        return this.uploadFile(`/api/tracking/extendedtechapprovedataset/${dataset.id}`, datasetFile, {
            params: {
                specificationId: dataset.specificationId,
                id: dataset.id,
                extendedTechnicalCheckNote: dataset.extendedTechnicalCheckNote,
            },
        });
    }

    public getExtendedTestProtocolFile(extendedTestProtocolFileId: number): Observable<IFile> {
        return this.http.get<IFile>(`/api/file/${extendedTestProtocolFileId}/info`);
    }

    public extendedTechnicallyRejectDataset(dataset: IDataset, datasetFile: Blob): Observable<void> {
        return this.uploadFile(`/api/tracking/extendedtechrejectdataset/${dataset.id}`, datasetFile, {
            params: {
                specificationId: dataset.specificationId,
                id: dataset.id,
                extendedTechnicalCheckNote: dataset.extendedTechnicalCheckNote,
            },
        });
    }

    public getLatestApprovedDataset(trackingSpecificationId: string): Observable<IDataset> {
        return this.http.get<IDataset>(`/api/tracking/latestapprovedataset/${trackingSpecificationId}`);
    }

    public distributeDataset(specificationId: string, datasetId: number, distributions: IDistribution): Observable<void> {
        return this.http.post<void>(`/api/tracking/${specificationId}/dataset/${datasetId}/distribute`, { distributions });
    }

    public downloadDataset(specificationId: string, datasetId: number): Observable<HttpResponse<Blob>> {
        return this.http.get(`/api/tracking/${specificationId}/dataset/${datasetId}/download`, {
            responseType: "blob",
            observe: "response",
        });
    }

    public createOne(): ITrackingSpecification {
        return {
            topicId: null,
            zs: {},
            tp: {},
            id: Math.random().toString(),
        };
    }

    private uploadFile<T = unknown, R = T>(url: string, files?: Blob | Blob[], additionalData: Partial<T> = {}): Observable<R> {
        const formData = this.getUploadFormData(files, additionalData);
        return this.http.post<R>(url, formData);
    }

    private getUploadFormData(files?: Blob | Blob[], additionalData: { [key: string]: unknown } = {}): FormData {
        const blobArray = Array.isArray(files) ? files : [files];
        const blobs = blobArray.filter((file) => file instanceof Blob);

        const keysToKeep = Object.entries(additionalData).filter(([key, value]) => !(value === undefined || key.startsWith("$")));
        const data = Object.fromEntries([...keysToKeep, ["file", blobs]]);
        return this.getFormData(data);
    }

    private getFormData(data: object): FormData {
        const formData = new FormData();
        Object.entries(data).forEach(([key, value]) => {
            if (key === "file") {
                const files: File[] = Array.isArray(value) ? value : [value];
                files.forEach((file) => formData.append(key, file, file.name));
            } else {
                formData.append(key, JSON.stringify(value));
            }
        });
        return formData;
    }

    public deleteTracking(id: string): Observable<void> {
        return this.http.delete<void>(`/api/tracking/${id}`);
    }

    public getEditTitle(topic?: ITopic, gemeinde?: IGemeinde): string {
        const invalidValues = ["", undefined, null];

        if (!invalidValues.includes(topic?.name) && !invalidValues.includes(gemeinde?.name)) {
            return `${(topic ? topic.name : "")} in ${(gemeinde ? gemeinde.name : "")}`;
        }

        return "";
    }

}
