import { Injectable } from "@angular/core";
import { MyGeoportalNavigationCategoryExtension } from "@geolib/geoappbase-client";

@Injectable({ providedIn: "root" })
export default class GeobusUserOrganisationExtension extends MyGeoportalNavigationCategoryExtension {

    public isEnabled(): boolean {
        return true;
    }

    public get name(): string {
        return "user_and_organisation";
    }

    public get label(): string {
        return "SIDE_MENU.USER_ORGANISATION.CATEGORY";
    }

}
