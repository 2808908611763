import { MessageBox, NotificationService, StateService } from "@geolib/geolib-client";
import { TopicService } from "./TopicService";
import ITopic from "./ITopic";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { ChangeDetectorRef, Component, ElementRef, OnInit } from "@angular/core";
import { IGridColumn } from "../../../common/IGridColumn";
import { TranslateService } from "@ngx-translate/core";
import GridController from "../common/GridController";
import GridService from "../common/GridService";
import { UserSettingsService } from "@geolib/geoappbase-client";
import { NGXLogger } from "ngx-logger";
import { ContentReadyEvent } from "devextreme/ui/data_grid";
import { IdentityService } from "../account/IdentityService";
import { lastValueFrom } from "rxjs";


@Component({
    selector: "topic-list",
    templateUrl: "./topic-list.html",
})
export default class TopicListController extends GridController implements OnInit {

    public dataSource?: DataSource<ITopic, string>;
    public dataGridColumns: IGridColumn[] = [];

    // eslint-disable-next-line max-params
    public constructor(
        private readonly stateService: StateService,
        private readonly topicService: TopicService,
        private readonly notificationService: NotificationService,
        private readonly translateService: TranslateService,
        private readonly messageBox: MessageBox,
        elementRef: ElementRef<HTMLElement>,
        gridService: GridService,
        userSettingsService: UserSettingsService,
        logger: NGXLogger,
        changeDetectorRef: ChangeDetectorRef,
        identityService: IdentityService,
    ) {
        super(elementRef.nativeElement, gridService, userSettingsService, logger, changeDetectorRef, identityService);
    }

    protected get settingsKey(): string {
        return "geobus.topicList.grid";
    }

    public ngOnInit(): void {
        this.dataGridColumns = this.getColumns();
        this.dataSource = new DataSource({
            store: new CustomStore({
                key: "id",
                load: async (): Promise<ITopic[]> => {
                    const topics = await lastValueFrom(this.topicService.findAll());
                    this.afterGridDataLoaded();
                    return topics;
                },
            }),
        });
    }

    public onRowDblClick(): void {
        this.edit();
    }

    public onContentReady(event: ContentReadyEvent): void {
        if (!event.component.getDataSource()) {
            event.component.option("dataSource", this.dataSource);
        }
    }

    public create(): void {
        this.stateService.go("app.topics.edit", { id: "create" });
    }

    private getSelectedItem(): ITopic | undefined {
        return this.dxDataGrid?.instance.getSelectedRowsData()[0];
    }

    public delete(): void {
        if (this.isOneItemSelected()) {
            const selectedItem = this.getSelectedItem();

            if (!selectedItem) {
                return;
            }

            this.messageBox.confirmDelete({ translationKey: "SYSTEM.DELETE_CONFIRMATION", name: selectedItem.name }).subscribe(() => {
                this.topicService.remove(selectedItem).subscribe({
                    next: () => {
                        this.notificationService.notify("SYSTEM.DEFAULT_DELETE_SUCCESS");
                        this.dxDataGrid.instance.refresh();
                    },
                    error: () => {
                        this.notificationService.error("Das Thema kann nicht gelöscht werden, da es verwendet wird");
                    },
                });
            });
        }
    }

    public edit(): void {
        const keys = this.dxDataGrid.selectedRowKeys;
        if (keys.length !== 1) {
            return;
        }

        this.stateService.go("app.topics.edit", { id: keys[0] });
    }

    public isOneItemSelected(): boolean {
        return this.dxDataGrid?.selectedRowKeys.length === 1;
    }

    private getColumns(): IGridColumn[] {
        return [
            {
                dataField: "name",
                caption: this.translateService.instant("TOPIC.GRID.NAME"),
                groupIndex: 0,
                sortOrder: "asc",
            },
        ];
    }
}
