<div class="gp-page-header">
    <div class="gp-container-normal">
        <breadcrumb></breadcrumb>
        <div class="gp-page-header-container">
            @if (editTitle !== "") {
                <h1 class="gp-page-header-title">{{ editTitle }}</h1>
            }
            @if (editTitle === "") {
                <h1 class="gp-page-header-title">{{ "TRACKING_EDIT.TITLE" | translate }}</h1>
            }
            <div class="gp-page-header-actions">
                <div class="btn-group">
                    <button class="btn btn-default btn-back" (click)="abort()">
                        {{ "NAVIGATION.BACK" | translate }}
                    </button>
                    <gp-button-primary (click)="save()" [isDisabled]="trackingEditForm.invalid" translation="NAVIGATION.SAVE"></gp-button-primary>
                </div>
            </div>
        </div>
    </div>
</div>
<form class="gp-container-normal" #trackingEditForm="ngForm" (ngSubmit)="save()">
    @if (trackingSpecification) {
        <div>
            <div class="row">
                <div class="col-md-6">
                    <div class="col-form-label">{{ "TRACKING_EDIT.TOPIC" | translate }}</div>
                    <dx-select-box
                        [dataSource]="topicsDataSource"
                        [(ngModel)]="trackingSpecification.topicId"
                        displayExpr="name"
                        valueExpr="id"
                        [searchEnabled]="true"
                        searchMode="contains"
                        searchExpr="name"
                        name="topicId"
                        [required]="true"
                        (ngModelChange)="topicChanged()"
                        [disabled]="isOnlyNsEnabled() || isExistingSpecification()"
                    ></dx-select-box>
                </div>
                <label class="col-md-6">
                    <div class="col-form-label">{{ "TRACKING_EDIT.PERIOD" | translate }}</div>
                    <input
                        class="form-control"
                        [required]="true"
                        [(ngModel)]="trackingSpecification.maxCheckoutPeriod"
                        type="number"
                        [disabled]="isOnlyNsEnabled()"
                        min="1"
                        max="365"
                        name="maxCheckoutPeriod"
                    />
                </label>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="col-form-label">{{ "TRACKING_EDIT.MODEL" | translate }}</div>
                    <dx-select-box
                        [dataSource]="modelsDataSource"
                        [(ngModel)]="trackingSpecification.modelId"
                        displayExpr="name"
                        valueExpr="id"
                        [searchEnabled]="true"
                        searchMode="contains"
                        searchExpr="name"
                        name="modelId"
                        [required]="true"
                        (ngModelChange)="modelChanged()"
                        [disabled]="isOnlyNsEnabled() || isExistingSpecification()"
                    ></dx-select-box>
                </div>
                <div class="col-md-6">
                    <div class="col-form-label">{{ "TRACKING_EDIT.ZS" | translate }}</div>
                    <dx-select-box
                        [dataSource]="organisationsDataSource"
                        [(ngModel)]="trackingSpecification.zs.organisationId"
                        displayExpr="name"
                        valueExpr="id"
                        [searchEnabled]="true"
                        searchMode="contains"
                        searchExpr="name"
                        name="zsOrganisationId"
                        [disabled]="isOnlyNsEnabled()"
                    ></dx-select-box>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="col-form-label">{{ "TRACKING_EDIT.GEMEINDE" | translate }}</div>
                    <dx-select-box
                        [dataSource]="gemeindenDateSource"
                        [(ngModel)]="trackingSpecification.gemeindeId"
                        displayExpr="name"
                        valueExpr="id"
                        [searchEnabled]="true"
                        searchMode="contains"
                        searchExpr="name"
                        name="gemeindeId"
                        [required]="true"
                        [disabled]="isOnlyNsEnabled() || isExistingSpecification()"
                    ></dx-select-box>
                </div>
                <div class="col-md-6">
                    <div class="col-form-label">{{ "TRACKING_EDIT.TP" | translate }}</div>
                    <dx-select-box
                        [dataSource]="organisationsDataSource"
                        [(ngModel)]="trackingSpecification.tp.organisationId"
                        displayExpr="name"
                        valueExpr="id"
                        [searchEnabled]="true"
                        searchMode="contains"
                        searchExpr="name"
                        name="tpOrganisationId"
                    ></dx-select-box>
                </div>
            </div>
            <div class="row margin-top-extra-large">
                <div class="col-md-6">
                    <h5>
                        <span class="gp-margin-right-medium">{{ "TRACKING_EDIT.NS" | translate }}</span>
                        <dx-button icon="add" (click)="addNs()"></dx-button>
                    </h5>
                    @for (ns of trackingSpecification.ns; track ns; let idx = $index) {
                        <div class="row">
                            <div class="flex-start-container gp-margin-bottom-medium">
                                <div class="col-md-11">
                                    <dx-select-box
                                        [dataSource]="organisationsDataSource"
                                        [(ngModel)]="ns.organisationId"
                                        displayExpr="name"
                                        valueExpr="id"
                                        [searchEnabled]="true"
                                        searchMode="contains"
                                        searchExpr="name"
                                        [name]="'nsOrganisationId_' + idx"
                                    ></dx-select-box>
                                </div>
                                <div class="col-md-1">
                                    <dx-button class="btn-no-border" icon="trash" (click)="deleteNs(ns)"></dx-button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    }
</form>
