<iframe id="triggerSaveCredentialsFix" name="triggerSaveCredentialsFix" hidden="hidden"></iframe>
<div class="gp-pagewidth gp-pagewidth-login-form">
    @if (!isAuthenticated()) {
        <form class="form" id="login-form" target="triggerSaveCredentialsFix" action="/login" method="post" (ngSubmit)="login()">
            <fieldset>
                <label class="form-group">
                    <div class="control-label">{{ "LOGINPANEL.USERNAME" | translate }}</div>
                    <input
                        class="form-control"
                        placeholder="{{ 'LOGINPANEL.USERNAME' | translate }}"
                        name="username"
                        [(ngModel)]="username"
                        [required]="true"
                        autofocus="autofocus"
                    />
                </label>
                <label class="form-group gp-margin-none">
                    <div class="control-label">{{ "LOGINPANEL.PASSWORD" | translate }}</div>
                    <input
                        class="form-control"
                        placeholder="{{ 'LOGINPANEL.PASSWORD' | translate }}"
                        name="password"
                        type="password"
                        [(ngModel)]="password"
                        [required]="true"
                    />
                </label>
                <div class="form-submit d-flex align-items-center">
                    <label class="gp-margin-none flex-grow-1">
                        <input type="checkbox" [(ngModel)]="rememberme" name="rememberme" />
                        <span>{{ "LOGINPANEL.STAY_SIGNED_IN" | translate }}</span>
                    </label>
                    <div class="btn-group">
                        <button class="btn btn-default">
                            {{ "LOGINPANEL.LOGIN" | translate }}
                        </button>
                    </div>
                </div>
                <div class="gp-margin-top-big text-right">
                    <a [uiParams]="{ userName: username }" uiSref="passwordrecovery">{{ "ACCOUNT.FORGET_PASSWORD" | translate }}</a>
                </div>
                <div class="gp-margin-top-big text-right"><a uiSref="modeldownload">Modell-Dateien herunterladen</a></div>
            </fieldset>
        </form>
    }
</div>
