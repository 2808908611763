<form name="formApproveReject">
    <div class="row">
        <div class="col-md-12">{{ "DATASET_DIALOG.CHOOSE" | translate }}</div>
    </div>
    <div class="row">
        <div class="col-md-12">&nbsp;</div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <label>
                <input type="radio" [(ngModel)]="extendedTechApproveAction" value="extendedTechApprove" name="extendedTechApproveAction" />
                {{ "DATASET_DIALOG.LABEL.OKAY" | translate }}
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <label>
                <input type="radio" [(ngModel)]="extendedTechApproveAction" value="extendedTechReject" name="extendedTechApproveAction" />
                {{ "DATASET_DIALOG.LABEL.NOT_OKAY" | translate }}
            </label>
        </div>
    </div>
    <div class="row">
        @if (extendedTechApproveAction === "extendedTechApprove") {
            <div class="col-md-12 gp-margin-bottom-medium">{{ "DATASET_DIALOG.EXTENDED_TECH.OKAY.TEXT" | translate }}</div>
        }
        @if (extendedTechApproveAction === "extendedTechReject") {
            <div class="col-md-12 gp-margin-bottom-medium">{{ "DATASET_DIALOG.EXTENDED_TECH.NOT_OKAY.TEXT" | translate }}</div>
        }
        <div class="col-md-12">
            <textarea class="rejectcomment form-control" [(ngModel)]="dataset.extendedTechnicalCheckNote" name="extendedTechnicalCheckNote"></textarea
            ><br />
        </div>
        <div class="col-md-12">
            <label class="form-group"
                >{{ "DATASET_DIALOG.EXTENDED_TECH.FILE" | translate }}
                <gp-file (onFileSelected)="onFileSelected($event)" accept=".pdf"></gp-file>
            </label>
        </div>
    </div>
    <div class="form-submit dialog-form-submit text-right">
        <div class="btn-group">
            <div class="btn-group">
                <button class="btn btn-default" type="button" (click)="cancel()">{{ "NAVIGATION.ABORT" | translate }}</button>
            </div>
            @if (extendedTechApproveAction === "extendedTechApprove" && datasetFile) {
                <div class="btn-group">
                    <button class="btn btn-primary" type="button" data-dismiss="modal" (click)="approve()">
                        {{ "DATASET_DIALOG.BUTTON.OKAY" | translate }}
                    </button>
                </div>
            }
            @if (extendedTechApproveAction === "extendedTechReject" && datasetFile) {
                <div class="btn-group">
                    <button class="btn btn-primary" type="button" data-dismiss="modal" (click)="reject()">
                        {{ "DATASET_DIALOG.BUTTON.NOT_OKAY" | translate }}
                    </button>
                </div>
            }
        </div>
    </div>
</form>
