import { NotificationService, StateService } from "@geolib/geolib-client";
import { OrganisationService } from "./OrganisationService";
import { Component, ViewChild } from "@angular/core";
import IOrganisation from "./IOrganisation";
import { FormControl, NgForm } from "@angular/forms";
import { isNumber } from "../common/IsNumber";


@Component({
    selector: "organisation-edit",
    templateUrl: "./organisation-edit.html",
})
export default class OrganisationEditController {

    @ViewChild(NgForm) public readonly editOrganisationForm: NgForm;
    @ViewChild("prmEmail") public readonly prmEmail: FormControl;
    public organisation: IOrganisation;

    public constructor(
        private readonly stateService: StateService,
        private readonly organisationService: OrganisationService,
        private readonly notificationService: NotificationService,
    ) {
        this.organisation = this.organisationService.createOne();

        const id = this.stateService.getParameter("id");

        this.organisationService.findOne(id ? id : "create").subscribe((organisation) => {
            this.organisation = organisation;
        });
    }

    public save(): void {
        if (isNumber(this.organisation.id)) {
            delete this.organisation.id;
        }

        this.organisationService.save(this.organisation).subscribe(() => {
            this.notificationService.notify("SYSTEM.DEFAULT_SAVE_SUCCESS");
            this.abort();
        }, () => {
            this.notificationService.error("SYSTEM.DEFAULT_SAVE_ERROR");
        });
    }

    public abort(): void {
        this.stateService.go("app.organisations.list");
    }

}
