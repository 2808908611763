import { Injectable } from "@angular/core";
import { MyGeoportalNavigationItemExtension } from "@geolib/geoappbase-client";
import { IdentityService } from "../account/IdentityService";
import { statesMap } from "../states";


@Injectable({ providedIn: "root" })
export default abstract class GeobusNavigationExtension extends MyGeoportalNavigationItemExtension {

    protected constructor(
        private readonly identityService: IdentityService,
    ) {
        super();
    }

    public isEnabled(): boolean {
        const state = statesMap.get(this.state.name);

        if (state && Array.isArray(state.data?.roles)) {
            return this.identityService.isAuthorized(...state.data.roles);
        } else {
            return true;
        }
    }

}
