import { ModelService } from "../model/ModelService";
import { Component, OnInit, ViewChild } from "@angular/core";
import IModelDto from "../model/IModelDto";
import { DxDataGridComponent } from "devextreme-angular";
import { IGridColumn } from "../../../common/IGridColumn";
import { TranslateService } from "@ngx-translate/core";
import { StateService } from "@geolib/geolib-client";


@Component({
    selector: "download-model-list",
    templateUrl: "./downloadmodel-list.html",
})
export default class DownloadModelListController implements OnInit {

    @ViewChild(DxDataGridComponent) public dxDataGrid: DxDataGridComponent;

    public models: IModelDto[] = [];
    public dataGridColumns: IGridColumn[] = [];

    public constructor(
        private readonly modelService: ModelService,
        private readonly stateService: StateService,
        private readonly translateService: TranslateService,
    ) {
    }

    public ngOnInit(): void {
        this.fetchData();
        this.dataGridColumns = this.getColumns();
    }

    private fetchData(): void {
        this.modelService.getAllPublicModels().subscribe((models: IModelDto[]) => {
            this.models = models;
        });
    }

    public goToStartPage(): void {
        this.stateService.go("login");
    }

    private getColumns(): IGridColumn[] {
        return [{
            dataField: "cantonId",
            dataType: "string",
            caption: this.translateService.instant("MODEL.GRID.CANTON_ID"),
            groupIndex: 0,
            sortOrder: "asc",
            sortIndex: 1,
            calculateDisplayValue: (rowData: { cantonId: string }) => {
                return `${this.translateService.instant(rowData.cantonId.toUpperCase())} (${rowData.cantonId.substring(2).toUpperCase()})`;
            },
            calculateCellValue: (rowData: { cantonId: string }) => {
                return `${this.translateService.instant(rowData.cantonId.toUpperCase())} (${rowData.cantonId.substring(2).toUpperCase()})`;
            },
        }, {
            dataField: "topic.name",
            dataType: "string",
            caption: this.translateService.instant("MODEL.GRID.TOPIC_NAME"),
            groupIndex: 0,
        }, {
            dataField: "name",
            dataType: "string",
            caption: this.translateService.instant("MODEL.GRID.NAME"),
            groupIndex: 1,
            sortOrder: "asc",
            sortIndex: 2,
        }, {
            dataField: "version",
            dataType: "string",
            caption: this.translateService.instant("MODEL.GRID.VERSION"),
            groupIndex: 2,
            alignment: "center",
        }, {
            dataField: "fromdate",
            dataType: "date",
            caption: this.translateService.instant("MODEL.GRID.FROM_DATE"),
            groupIndex: 3,
            format: "dd.MM.yyyy",
        }, {
            dataField: "Modell",
            dataType: "string",
            caption: this.translateService.instant("MODEL.GRID.MODEL"),
            groupIndex: 4,
            cellTemplate: "modelTemplate",
        }, {
            dataField: "Beschreibung",
            dataType: "string",
            caption: this.translateService.instant("MODEL.GRID.DESCRIPTION"),
            groupIndex: 5,
            cellTemplate: "guidelineTemplate",
        }];
    }

}
