import { LogoExtension } from "@geolib/geoappbase-client";
import { HeaderInfoComponent } from "./header-info.component";
import { Injectable } from "@angular/core";


@Injectable({ providedIn: "root" })
export class GeobusHeaderInfoExtension extends LogoExtension<HeaderInfoComponent> {

    public isEnabled(): boolean {
        return true;
    }

    public get component(): typeof HeaderInfoComponent {
        return HeaderInfoComponent;
    }
}
