import { Component, Input } from "@angular/core";
import { AuthenticationService } from "../account/AuthenticationService";
import { NotificationService, StateService } from "@geolib/geolib-client";
import { IdentityService } from "../account/IdentityService";


@Component({
    selector: "login-panel",
    templateUrl: "login-panel.component.html",
})

export class LoginPanelComponent {

    @Input() public extensionData: unknown;

    public constructor(
        private readonly notificationService: NotificationService,
        private readonly authenticationService: AuthenticationService,
        private readonly stateService: StateService,
        private readonly identityService: IdentityService,
    ) {
    }

    public get fullTitle(): string {
        return `${this.identityService.currentUser?.firstname} ${this.identityService.currentUser?.lastname} ` +
            `[${this.identityService.currentUser?.username}]`;
    }

    public logout(): void {
        this.authenticationService.logoutUser().subscribe({
            next: (): void => {
                this.notificationService.notify("Erfolgreich abgemeldet");
                this.stateService.go("login");
            },
            error: (): void => {
                this.notificationService.error("Abmeldung fehlgeschlagen");
            },
        });
    }
}
