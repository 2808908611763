import { Injectable } from "@angular/core";
import GeobusNavigationExtension from "./geobus-navigation.extension";


@Injectable({ providedIn: "root" })
export default class GeobusToDoExtension extends GeobusNavigationExtension {

    public get category(): string {
        return "general";
    }

    public get state(): { name: string } {
        return { name: "app.main" };
    }

    public get label(): string {
        return "SIDE_MENU.GENERAL.TO_DO";
    }

    public get icon(): string {
        return "done";
    }

}
