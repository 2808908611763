import { ModelService } from "./ModelService";
import { IdentityService } from "../account/IdentityService";
import { ChangeDetectorRef, Component, ElementRef, OnInit } from "@angular/core";
import { TopicService } from "../topic/TopicService";
import { RoleName } from "../user/IRole";
import IModelDto from "./IModelDto";
import { MessageBox, NotificationService, StateService } from "@geolib/geolib-client";
import { forkJoin, lastValueFrom } from "rxjs";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { Column as DxColumn, ContentReadyEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import { IGridColumn } from "../../../common/IGridColumn";
import { TranslateService } from "@ngx-translate/core";
import GridController from "../common/GridController";
import GridService from "../common/GridService";
import { UserSettingsService } from "@geolib/geoappbase-client";
import { NGXLogger } from "ngx-logger";


@Component({
    selector: "model-list",
    templateUrl: "./model-list.html",
})
export default class ModelListController extends GridController implements OnInit {

    public dataSource?: DataSource<IModelDto, string>;
    public selected: IModelDto | null;
    public dataGridColumns: IGridColumn[] = [];

    // eslint-disable-next-line max-params
    public constructor(
        private readonly stateService: StateService,
        private readonly modelService: ModelService,
        private readonly notificationService: NotificationService,
        private readonly messageBox: MessageBox,
        private readonly topicService: TopicService,
        private readonly translateService: TranslateService,
        elementRef: ElementRef<HTMLElement>,
        gridService: GridService,
        userSettingsService: UserSettingsService,
        logger: NGXLogger,
        changeDetectorRef: ChangeDetectorRef,
        identityService: IdentityService,
    ) {
        super(elementRef.nativeElement, gridService, userSettingsService, logger, changeDetectorRef, identityService);
    }

    protected get settingsKey(): string {
        return "geobus.modelList.grid";
    }

    public ngOnInit(): void {
        this.dataGridColumns = this.getColumns();
        this.dataSource = new DataSource({
            store: new CustomStore<IModelDto, string>({
                key: "id",
                load: (): Promise<IModelDto[]> => {
                    this.reset();
                    return lastValueFrom(forkJoin([
                        this.modelService.findAll(),
                        this.topicService.findAll(),
                    ])).then(([models, topics]) => {
                        for (const model of models) {
                            model.private = model.isSiaModel;
                            model.topic = topics.find(({ id }) => id === model.topicId);
                        }

                        this.afterGridDataLoaded();
                        return models;
                    });
                },
            }),
        });
    }

    private reset(): void {
        this.selected = null;
    }

    public selectionChanged(event: SelectionChangedEvent): void {
        this.selected = event.selectedRowsData[0];
    }

    public onContentReady(event: ContentReadyEvent): void {
        if (!event.component.getDataSource()) {
            event.component.option("dataSource", this.dataSource);
        }
    }

    private getSelectedItem(): IModelDto {
        return this.dxDataGrid.instance.getSelectedRowsData()[0];
    }

    public onRowDblClick(): void {
        this.edit();
    }

    public create(): void {
        this.stateService.go("app.models.edit", { id: "create" });
    }

    public delete(): void {
        const selectedItem = this.getSelectedItem();

        if (!selectedItem) {
            return;
        }

        this.messageBox.confirmDelete({ translationKey: "SYSTEM.DELETE_CONFIRMATION", name: selectedItem.name }).subscribe(() => {
            this.modelService.remove(selectedItem).subscribe({
                next: () => {
                    this.notificationService.notify("SYSTEM.DEFAULT_DELETE_SUCCESS");
                    this.dxDataGrid.instance.refresh();
                },
                error: () => {
                    this.notificationService.error("Das Modell konnte nicht gelöscht werden.");
                },
            });
        });
    }

    public edit(): void {
        const selectedItem = this.getSelectedItem();

        if (!selectedItem) {
            return;
        }

        this.stateService.go("app.models.edit", { id: selectedItem.id });
    }

    public downloadModelFile(): void {
        if (!this.selected) {
            return;
        }

        const urlDownload = `/api/file/${this.selected.modelFileId}`;
        window.location.assign(urlDownload);
    }

    public downloadGuidelinesFile(): void {
        if (!this.selected) {
            return;
        }

        const urlDownload = `/api/file/${this.selected.guidelinesFileId}`;
        window.location.assign(urlDownload);
    }

    public currentUserCanEditModels(): boolean {
        return this.identityService.isAuthorized(RoleName.FS, RoleName.TP);
    }

    public setFilterForBooleanField(rowData: IModelDto): boolean | undefined {

        const column = this as DxColumn;

        if (typeof column.dataField === "string") {

            const dataField = column.dataField;

            if (rowData[dataField] === null) {
                return false;
            }

            return Boolean(rowData[dataField]);
        }

    }

    public presentOtherValuesInterlisFormat(rowData: IModelDto): string | null {
        /*
        present other values in the grid
         */
        switch (rowData.interlisFormat) {
            case "canton":
                return "Kantonales Modell";
            case "federal":
                return "Bundesmodell";
            default:
                return rowData.interlisFormat;
        }
    }

    public presentOtherValuesFormat(rowData: IModelDto): string {
        /*
        present other values in the grid
         */
        switch (rowData.format) {
            case "interlis1":
                return "Interlis1 (.itf)";
            case "interlis2":
                return "Interlis2 (.xtf)";
            case "geopackage":
                return "Geopackage (.gpkg)";
            case "other":
                return "Andere";
            default:
                return rowData.format;
        }
    }

    private getColumns(): IGridColumn[] {
        return [
            {
                dataField: "topic.name",
                caption: this.translateService.instant("MODEL.GRID.TOPIC_NAME"),
                groupIndex: 0,
                sortOrder: "asc",
            }, {
                dataField: "name",
                caption: this.translateService.instant("MODEL.GRID.NAME"),
                groupIndex: 1,
            }, {
                dataField: "version",
                caption: this.translateService.instant("MODEL.GRID.VERSION"),
                groupIndex: 2,
            }, {
                dataField: "private",
                caption: this.translateService.instant("MODEL.GRID.PRIVATE"),
                groupIndex: 3,
                calculateCellValue: this.setFilterForBooleanField,
                showEditorAlways: false,
                trueText: this.translateService.instant("GRID_FILTER.YES"),
                falseText: this.translateService.instant("GRID_FILTER.NO"),
            }, {
                dataField: "fromdate",
                caption: this.translateService.instant("MODEL.GRID.FROM_DATE"),
                groupIndex: 4,
                dataType: "date",
                format: "dd.MM.yyyy",
            }, {
                dataField: "format",
                caption: this.translateService.instant("MODEL.GRID.FORMAT"),
                groupIndex: 5,
                calculateCellValue: this.presentOtherValuesFormat,
            }, {
                dataField: "interlisFormat",
                caption: this.translateService.instant("MODEL.GRID.INTERLIS_FORMAT"),
                groupIndex: 6,
                calculateCellValue: this.presentOtherValuesInterlisFormat,
            }, {
                dataField: "hasExtendedTechnicalCheck",
                caption: this.translateService.instant("MODEL.GRID.EXTENDED_TECHNICAL_CHECK"),
                groupIndex: 7,
                calculateCellValue: this.setFilterForBooleanField,
                showEditorAlways: false,
                trueText: this.translateService.instant("GRID_FILTER.YES"),
                falseText: this.translateService.instant("GRID_FILTER.NO"),
            }, {
                dataField: "hasVisualization",
                caption: this.translateService.instant("MODEL.GRID.VISUALIZATION"),
                groupIndex: 8,
                calculateCellValue: this.setFilterForBooleanField,
                showEditorAlways: false,
                trueText: this.translateService.instant("GRID_FILTER.YES"),
                falseText: this.translateService.instant("GRID_FILTER.NO"),
            }, {
                dataField: "hasGeoportalPublication",
                caption: this.translateService.instant("MODEL.GRID.GEOPORTAL"),
                groupIndex: 9,
                calculateCellValue: this.setFilterForBooleanField,
                showEditorAlways: false,
                trueText: this.translateService.instant("GRID_FILTER.YES"),
                falseText: this.translateService.instant("GRID_FILTER.NO"),
            }, {
                dataField: "hasOerebPublication",
                caption: this.translateService.instant("MODEL.GRID.ÖREB"),
                groupIndex: 10,
                calculateCellValue: this.setFilterForBooleanField,
                showEditorAlways: false,
                trueText: this.translateService.instant("GRID_FILTER.YES"),
                falseText: this.translateService.instant("GRID_FILTER.NO"),
            },
        ];
    }
}
