import { MessageBox, NotificationService, StateService } from "@geolib/geolib-client";
import { lastValueFrom } from "rxjs";
import { OrganisationService } from "./OrganisationService";
import { IdentityService } from "../account/IdentityService";
import { ChangeDetectorRef, Component, ElementRef, OnInit } from "@angular/core";
import { RoleName } from "../user/IRole";
import IOrganisation from "./IOrganisation";
import { isTruthy } from "@geolib/geolib-types/src/common/PredicateChecks";
import { IGridColumn } from "../../../common/IGridColumn";
import { TranslateService } from "@ngx-translate/core";
import { ContentReadyEvent, RowPreparedEvent } from "devextreme/ui/data_grid";
import IUser from "../user/IUser";
import GridController from "../common/GridController";
import GridService from "../common/GridService";
import { UserSettingsService } from "@geolib/geoappbase-client";
import { NGXLogger } from "ngx-logger";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";


@Component({
    selector: "organisation-list",
    templateUrl: "./organisation-list.html",
})
export default class OrganisationListController extends GridController implements OnInit {

    public organisations: IOrganisation[] = [];
    public showDeleted: boolean = false;
    public dataGridColumns: IGridColumn[] = [];
    private activeColumn: IGridColumn;
    private dataSource: DataSource<IOrganisation, string>;

    // eslint-disable-next-line max-params
    public constructor(
        private readonly stateService: StateService,
        private readonly organisationService: OrganisationService,
        private readonly notificationService: NotificationService,
        private readonly translateService: TranslateService,
        private readonly messageBox: MessageBox,
        elementRef: ElementRef<HTMLElement>,
        gridService: GridService,
        userSettingsService: UserSettingsService,
        logger: NGXLogger,
        changeDetectorRef: ChangeDetectorRef,
        identityService: IdentityService,
    ) {
        super(elementRef.nativeElement, gridService, userSettingsService, logger, changeDetectorRef, identityService);
    }

    protected get settingsKey(): string {
        return "geobus.organisationList.grid";
    }

    public ngOnInit(): void {
        this.dataGridColumns = this.getColumns();
        this.dataSource = new DataSource<IOrganisation, string>({
            store: new CustomStore({
                key: "id",
                load: (): Promise<IOrganisation[]> => {
                    return this.loadGridData().then((organisations) => {
                        this.afterGridDataLoaded();
                        return organisations;
                    });
                },
            }),
        });
    }

    private loadGridData(): Promise<IOrganisation[]> {
        if (this.showDeleted) {
            this.activeColumn.visible = true;
            return this.fetchInactiveOrganisations();
        } else {
            this.activeColumn.visible = false;
            return this.fetchActiveOrganisations();
        }
    }

    public onRowDblClick(): void {
        this.edit();
    }

    public onRowPrepared(event: RowPreparedEvent<IUser>): void {
        if (event.rowType === "data") {
            if (!event.data.active) {
                event.rowElement.classList.add("inactive");
            }
        }
    }

    public onContentReady(event: ContentReadyEvent): void {
        if (!event.component.getDataSource()) {
            event.component.option("dataSource", this.dataSource);
        }
    }

    public create(): void {
        this.stateService.go("app.organisations.edit", { id: "create" });
    }

    public delete(): void {
        const selectedItem = this.getSelectedItem();

        if (!selectedItem) {
            return;
        }

        this.messageBox.confirmDelete({ translationKey: "SYSTEM.DELETE_CONFIRMATION", name: selectedItem.name }).subscribe(() => {
            this.organisationService.remove(selectedItem).subscribe({
                next: () => {
                    this.notificationService.notify("SYSTEM.DEFAULT_DELETE_SUCCESS");
                    this.fetchActiveOrganisations();
                },
                error: () => {
                    this.notificationService.error("Die Organisation konnte nicht gelöscht werden.");
                },
            });
        });
    }

    public edit(): void {
        const selectedItem = this.getSelectedItem();

        if (!selectedItem) {
            return;
        }

        if (this.identityService.isAuthorized(RoleName.FS)) {
            this.stateService.go("app.organisations.edit", { id: selectedItem.id });
        } else {
            this.notificationService.error("Sie haben keine Berechtigung um eine Organisation zu bearbeiten");
        }
    }

    public isSelectedItemModifiable(): boolean {
        return !this.getSelectedItem();
    }

    public userHasEditPermissions(): boolean {
        return this.identityService.isAuthorized(RoleName.FS);
    }

    private getSelectedItem(): IOrganisation | undefined {
        return this.dxDataGrid?.instance.getSelectedRowsData()[0];
    }

    public toggleActiveInactiveOrganisations(): void {
        this.dxDataGrid.instance.refresh();
    }

    private fetchAtrributes(organisations: IOrganisation[]): void {
        for (const organisation of organisations) {
            const street = organisation.street ? `${organisation.street}, ` : "";
            const place = [organisation.postalCode, organisation.location].filter(isTruthy).join(" ");
            organisation.address = `${street}${place}`;
        }
        this.organisations = organisations;
    }

    private fetchActiveOrganisations(): Promise<IOrganisation[]> {
        return lastValueFrom(this.organisationService.findAll()).then((organisations: IOrganisation[]) => {
            this.fetchAtrributes(organisations);
            return this.organisations;
        });
    }

    private fetchInactiveOrganisations(): Promise<IOrganisation[]> {
        return lastValueFrom(this.organisationService.findAllActiveAndInactiveOrganisations()).then((organisations: IOrganisation[]) => {
            this.fetchAtrributes(organisations);
            return this.organisations;
        });
    }

    private getColumns(): IGridColumn[] {
        this.activeColumn = {
            dataField: "active",
            dataType: "boolean",
            caption: this.translateService.instant("USER.GRID.ACTIVE"),
            groupIndex: 4,
            visible: false,
            trueText: this.translateService.instant("GRID_FILTER.YES"),
            falseText: this.translateService.instant("GRID_FILTER.NO"),
        };

        return [
            {
                dataField: "name",
                caption: this.translateService.instant("ORGANISATION.GRID.NAME"),
                groupIndex: 0,
                sortOrder: "asc",
            }, {
                dataField: "telephone",
                caption: this.translateService.instant("ORGANISATION.GRID.PHONE"),
                groupIndex: 1,
            }, {
                dataField: "email",
                caption: this.translateService.instant("ORGANISATION.GRID.EMAIL"),
                groupIndex: 2,
            }, {
                dataField: "address",
                caption: this.translateService.instant("ORGANISATION.GRID.ADDRESS"),
                groupIndex: 3,
            }, this.activeColumn,
        ];
    }

}
