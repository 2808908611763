<div class="gp-startpage">
    <div class="gp-app-title"><img src="/images/logo/GEOBUS.svg" /></div>
    <div class="gp-startpage-scrollpanel">
        <div class="gp-pagewidth gp-pagewidth-geoapp gp-margin-top-big">
            @if (loading || !isTokenValid) {
                <div class="row">
                    <div class="col-lg-12">
                        @if (loading) {
                            <gp-spinner class="loading-spinner" visible="true"></gp-spinner>
                        }
                        @if (!loading && !isTokenValid) {
                            <div class="alert alert-warning">{{ "PASSWORD_RESET.TOKEN" | translate }}</div>
                        }
                    </div>
                </div>
            }
            @if (isTokenValid && !loading) {
                <div class="row">
                    <div class="col-lg-12">
                        <div class="gp-page-header">
                            <div class="gp-container-normal">
                                <div class="gp-page-header-container">
                                    <h1 class="gp-page-header-title">{{ "PASSWORD_RESET.TITLE" | translate }}</h1>
                                </div>
                            </div>
                        </div>
                        <form class="gp-container-normal" #passwordResetForm="ngForm">
                            <div class="row">
                                <div class="col-md-12 gp-margin-bottom-large">
                                    @if (user) {
                                        <div>
                                            {{ "PASSWORD_RESET.LABEL.USER_NAME" | translate }}
                                            <b>{{ user.username }}</b>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div class="row">
                                <label class="col-md-6">
                                    <div class="col-form-label">{{ "PASSWORD_RESET.LABEL.PASSWORD" | translate }}</div>
                                    <input
                                        class="form-control"
                                        #pwNew="ngModel"
                                        [required]="true"
                                        [(ngModel)]="password"
                                        name="newPassword"
                                        type="password"
                                        (keyup)="validPwdCheck()"
                                    />
                                    @if (errorPassword && pwNew.value) {
                                        <ul class="gp-form-control-error">
                                            <li>{{ errorPassword }}</li>
                                        </ul>
                                    }
                                </label>
                                <label class="col-md-6">
                                    <div class="col-form-label">{{ "PASSWORD_RESET.LABEL.CONFIRM_PASSWORD" | translate }}</div>
                                    <input
                                        class="form-control"
                                        #pwNewConfirm="ngModel"
                                        [required]="true"
                                        [(ngModel)]="passwordcopy"
                                        name="newPasswordCopy"
                                        type="password"
                                        (keyup)="validPwdCheck()"
                                    />
                                    @if (!equalPassword && pwNewConfirm.value) {
                                        <ul class="gp-form-control-error">
                                            <li>{{ "PASSWORD_RESET.ERROR_MESSAGE.CONFIRM_PASSWORD" | translate }}</li>
                                        </ul>
                                    }
                                </label>
                            </div>
                            <div class="gp-margin-top-big flex-start-container float-right">
                                <div class="btn-group">
                                    <button class="btn btn-default btn-back" (click)="abort()">
                                        {{ "NAVIGATION.BACK" | translate }}
                                    </button>
                                    <gp-button-primary
                                        (click)="resetPassword()"
                                        translation="PASSWORD_RESET.SAVE"
                                        [isDisabled]="checkPassword()"
                                    ></gp-button-primary>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            }
        </div>
    </div>
    <footer></footer>
</div>
