import { HomeContentExtension } from "@geolib/geoappbase-client";
import { Injectable } from "@angular/core";
import LoginController from "../login/LoginController";


@Injectable({ providedIn: "root" })
export class GeobusStartPageExtension extends HomeContentExtension<LoginController> {

    public constructor() {
        super();
    }

    public isEnabled(): boolean {
        return true;
    }

    public get component(): typeof LoginController {
        return LoginController;
    }
}
