import { lastValueFrom } from "rxjs";
import { TrackingService } from "../tracking/TrackingService";
import { IdentityService } from "../account/IdentityService";
import StatusMapping from "../common/StatusMapping";
import { ITrackingDashboard } from "../../../common/tracking/ITrackingSpecificationList";
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit } from "@angular/core";
import { StateService } from "@geolib/geolib-client";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { ContentReadyEvent, RowDblClickEvent } from "devextreme/ui/data_grid";
import { TranslateService } from "@ngx-translate/core";
import { IGridColumn } from "../../../common/IGridColumn";
import GridController from "../common/GridController";
import GridService from "../common/GridService";
import { UserSettingsService } from "@geolib/geoappbase-client";
import { NGXLogger } from "ngx-logger";


@Component({
    selector: "geobus-main",
    templateUrl: "./main.html",
})
export default class MainController extends GridController implements OnInit {

    @Input() public extensionData: unknown;

    public dataSource?: DataSource<ITrackingDashboard, string>;
    public dataGridColumns: IGridColumn[] = [];
    protected hasGridControls: boolean = false;

    public constructor(
        private readonly trackingService: TrackingService,
        private readonly statusMapping: StatusMapping,
        private readonly stateService: StateService,
        private readonly translateService: TranslateService,
        elementRef: ElementRef<HTMLElement>,
        gridService: GridService,
        userSettingsService: UserSettingsService,
        logger: NGXLogger,
        changeDetectorRef: ChangeDetectorRef,
        identityService: IdentityService,
    ) {
        super(elementRef.nativeElement, gridService, userSettingsService, logger, changeDetectorRef, identityService);

        this.identityService.onLogout(() => {
            delete this.dataSource;
        });
    }

    protected get settingsKey(): string {
        return "geobus.dashboard.grid";
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.dataGridColumns = this.getColumns();
        this.dataSource = new DataSource({
            store: new CustomStore({
                key: "id",
                load: (): Promise<ITrackingDashboard[]> => {
                    return lastValueFrom(this.trackingService.findPending()).then((pendingDataset: ITrackingDashboard[]) => {
                        for (const pending of pendingDataset) {
                            pending.extstatus = this.statusMapping.statusName(pending);
                        }

                        this.afterGridDataLoaded();
                        return pendingDataset;
                    });
                },
            }),
        });
    }

    public isSelectedItemModifiable(): boolean {
        return !this.getSelectedItem();
    }

    private getSelectedItem(): ITrackingDashboard | undefined {
        return this.dxDataGrid?.instance.getSelectedRowsData()[0];
    }

    public showDetails(): void {
        const selectedKeys = this.dxDataGrid.selectedRowKeys;

        if (selectedKeys.length === 1) {
            this.show(selectedKeys[0]);
        }
    }

    private show(id: number): void {
        this.stateService.go("app.trackings.datasets", { trackingSpecificationId: id });
    }

    public onRowDblClick(event: RowDblClickEvent<ITrackingDashboard, number>): void {
        this.show(event.key);
    }

    public onContentReady(event: ContentReadyEvent): void {
        if (!event.component.getDataSource()) {
            event.component.option("dataSource", this.dataSource);
        }
    }

    private getColumns(): IGridColumn[] {
        return [
            {
                dataField: "topicName",
                dataType: "string",
                caption: this.translateService.instant("TO_DO.GRID.TOPIC_NAME"),
            },
            {
                dataField: "modelName",
                dataType: "string",
                caption: this.translateService.instant("TO_DO.GRID.MODEL_NAME"),
            },
            {
                dataField: "gemeindeName",
                dataType: "string",
                caption: this.translateService.instant("TO_DO.GRID.GEMEINDE_NAME"),
            },
            {
                dataField: "checkoutDate",
                dataType: "date",
                format: "dd.MM.yyyy",
                caption: this.translateService.instant("TO_DO.GRID.CHECKOUT_DATE"),
            },
            {
                dataField: "dueDate",
                dataType: "date",
                format: "dd.MM.yyyy",
                caption: this.translateService.instant("TO_DO.GRID.DUE_DATE"),
                sortOrder: "asc",
            },
            {
                dataField: "extstatus",
                dataType: "string",
                caption: this.translateService.instant("TO_DO.GRID.EXTSTATUS"),
            },
            {
                dataField: "zs",
                dataType: "string",
                caption: this.translateService.instant("TO_DO.GRID.ZS"),
            },
            {
                dataField: "ns",
                dataType: "string",
                caption: this.translateService.instant("TO_DO.GRID.NS"),
            },
        ];
    }

}
