import { TrackingService } from "./TrackingService";
import { UserService } from "../user/UserService";
import { ModelService } from "../model/ModelService";
import { IdentityService } from "../account/IdentityService";
import StatusMapping from "../common/StatusMapping";
import { RoleName } from "../user/IRole";
import ITrackingSpecification from "./ITrackingSpecification";
import IUser from "../user/IUser";
import IDataset from "./IDataset";
import { ChangeDetectorRef, Component, ElementRef, OnInit } from "@angular/core";
import { MessageBox, NotificationService, StateService } from "@geolib/geolib-client";
import DataSource from "devextreme/data/data_source";
import { ContentReadyEvent, RowDblClickEvent } from "devextreme/ui/data_grid";
import { ITrackingDashboard } from "../../../common/tracking/ITrackingSpecificationList";
import DownloadFileModalController from "../downloadFileModal/DownloadFileModalController";
import IDistribution from "./IDistribution";
import { lastValueFrom, Subscription } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { IGridColumn } from "../../../common/IGridColumn";
import { saveAs } from "file-saver";
import GridController from "../common/GridController";
import GridService from "../common/GridService";
import { UserSettingsService } from "@geolib/geoappbase-client";
import { NGXLogger } from "ngx-logger";
import CustomStore from "devextreme/data/custom_store";
import { switchMap } from "rxjs/operators";
import { DxGridState } from "../common/DxTypes";


@Component({
    selector: "dataset-list",
    templateUrl: "./dataset-list.html",
    styleUrls: ["../common/status.scss"],
})
export default class DatasetListController extends GridController implements OnInit {

    public downloadingGPKG: boolean;
    public checkoutmessage: string | null;
    public datasets: IDataset[];
    private trackingSpecification: ITrackingSpecification;
    public currentIgCheckExecution?: number | null;
    private currentValidDataset: IDataset | null;
    private currentCheckedOutDataset: IDataset | null;
    public hasExtendedTechnicalCheck: boolean;
    private checkoutUser: IUser;
    public dataSource: DataSource<IDataset, number>;
    public expertCheckFilterOptions: Array<{ value: string; text: string }>;
    public technicalCheckFilterOptions: Array<{ value: string; text: string }>;

    private timeoutHandle: number | undefined;
    private subscription: Subscription;
    public dataGridColumns: IGridColumn[] = [];
    private extendedTechnicalCheckStatusColumn: IGridColumn;

    // eslint-disable-next-line max-params
    public constructor(
        private readonly stateService: StateService,
        private readonly trackingService: TrackingService,
        private readonly notificationService: NotificationService,
        private readonly statusMapping: StatusMapping,
        private readonly userService: UserService,
        private readonly messageBox: MessageBox,
        private readonly modelService: ModelService,
        private readonly translateService: TranslateService,
        elementRef: ElementRef<HTMLElement>,
        gridService: GridService,
        userSettingsService: UserSettingsService,
        logger: NGXLogger,
        changeDetectorRef: ChangeDetectorRef,
        identityService: IdentityService,
    ) {
        super(elementRef.nativeElement, gridService, userSettingsService, logger, changeDetectorRef, identityService);

        this.downloadingGPKG = false;
        this.expertCheckFilterOptions = [
            { value: "Nicht in Ordnung", text: "Nicht in Ordnung" },
            { value: "In Ordnung", text: "In Ordnung" },
        ];
        this.technicalCheckFilterOptions = [
            { value: "Datei fehlerfrei", text: "Datei fehlerfrei" },
            { value: "Datei fehlerhaft", text: "Datei fehlerhaft" },
            { value: "Datei fehlerhaft: manuell angenommen", text: "Datei fehlerhaft: manuell angenommen" },
            { value: "Datei fehlerhaft: manuell abgelehnt", text: "Datei fehlerhaft: manuell abgelehnt" },
        ];
    }

    protected get settingsKey(): string {
        return "geobus.datasetList.grid";
    }

    public ngOnInit(): void {
        super.ngOnInit();
        this.dataGridColumns = this.getColumns();
        this.dataSource = new DataSource<IDataset, number>({
            store: new CustomStore({
                key: "id",
                load: (): Promise<IDataset[]> => {
                    return this.loadGridData();
                },
            }),
        });
    }

    private loadGridData(): Promise<IDataset[]> {
        this.currentIgCheckExecution = null;
        this.currentValidDataset = null;
        this.currentCheckedOutDataset = null;
        this.checkoutmessage = null;

        const currentTimeLessFiveMinutes = new Date();
        currentTimeLessFiveMinutes.setMinutes(currentTimeLessFiveMinutes.getMinutes() - 10);

        return lastValueFrom(this.trackingService.findOne(this.trackingSpecificationId).pipe(
            switchMap((trackingSpecification) => {
                this.trackingSpecification = trackingSpecification;
                return this.modelService.findOne(this.trackingSpecification.modelId!);
            }),
            switchMap((model) => {
                this.hasExtendedTechnicalCheck = model.hasExtendedTechnicalCheck;
                this.extendedTechnicalCheckStatusColumn.visible = this.hasExtendedTechnicalCheck;
                return this.trackingService.findDatasets(this.trackingSpecificationId);
            }),
        )).then((datasets) => {
            const dsGroups: string[] = [];

            // eslint-disable-next-line complexity, max-statements
            datasets.forEach((dataset) => {
                const approved = dataset.approved;
                const extendedTechnicalStatus = dataset.extendedTechnicalStatus;
                const hasBeenRejected = dataset.hasbeenrejected;

                dataset.technicalcheck = this.statusMapping.technicalStatusName(dataset.status);
                dataset.expertcheck = this.statusMapping.expertCheckStatusName(hasBeenRejected, approved, extendedTechnicalStatus);
                dataset.isValid = false;
                dataset.isCurrent = false;
                dataset.extendedTechnicalCheckStatus = this.translateStatus(dataset.extendedTechnicalCheckStatus);

                if (approved === false && !dsGroups.includes(dataset.ocheckoutDate)) {
                    dsGroups.push(dataset.ocheckoutDate);
                }

                if (approved === true) {
                    if (!this.currentValidDataset || (dataset.checkinDate > this.currentValidDataset.checkinDate)) {
                        this.currentValidDataset = dataset;
                    }
                    dataset.isValid = true;
                }

                if (!dataset.fileId || approved === null) {
                    dataset.isCurrent = true;
                    dataset.cellClass = "current-checkedout-dataset";
                    this.currentCheckedOutDataset = dataset;
                    const msg = "Die Daten befinden sich im Nachführungs- und Kontrollprozess. " +
                        "Die Daten sind voraussichtlich wieder verfügbar am ";
                    const dueDate = new Date(dataset.dueDate);
                    this.checkoutmessage = `${msg}${dueDate.getDate()}.${(dueDate.getMonth() + 1)}.${dueDate.getFullYear()}`;
                }

                if (!this.currentIgCheckExecution && this.isDatasetWaitingForIgCheck(dataset) &&
                    new Date(dataset.checkinDate) > currentTimeLessFiveMinutes
                ) {
                    this.currentIgCheckExecution = dataset.id;
                    setTimeout(() => {
                        this.calculateGridHeight();

                        setTimeout(() => {
                            this.waitIgCheckExecution(this.currentIgCheckExecution!, 0, () => this.stopIgCheckWaiting());
                        }, 5000);
                    });
                }
            });

            this.setCurrentDatasetStyle();

            if (this.currentCheckedOutDataset) {
                this.userService.findOne(this.currentCheckedOutDataset.checkoutUserId).subscribe((checkoutUser) => {
                    this.checkoutUser = checkoutUser;
                });
            }

            this.datasets = datasets;
            this.afterGridDataLoaded();

            return this.datasets;
        });
    }

    private get trackingSpecificationId(): string {
        return this.stateService.getParameter("trackingSpecificationId");
    }

    public evaluateStyleClasses(data: IDataset): string {
        return this.statusMapping.evaluateStyleClasses(data);
    }

    public addCustomTooltip(data: IDataset): string {
        return this.statusMapping.addCustomTooltip(data);
    }

    // eslint-disable-next-line complexity
    private setCurrentDatasetStyle(): void {
        if (this.currentValidDataset) {
            this.currentValidDataset.cellClass = "current-valid-dataset";
        }

        if (this.currentValidDataset && this.currentValidDataset.geoportalPublishSucceeded) {
            this.currentValidDataset.cellClass = "geoportal-publish-succeeded";
        }

        if (this.currentValidDataset && this.currentValidDataset.geoportalPublishSucceeded && this.currentValidDataset.oerebPublishSucceeded) {
            this.currentValidDataset.cellClass = "oereb-publish-succeeded";
        }
    }

    public isSelectedItemModifiable(): boolean {
        return !this.getSelectedItem();
    }

    private show(id: number): void {
        this.stateService.go("app.trackings.datasets.edit", { trackingSpecificationId: this.trackingSpecificationId, id });
    }

    private translateStatus(status: string): string {
        const statusTypes = [
            {
                isValid: status === "valid",
                translation: "in Ordnung",
            },
            {
                isValid: status === "invalidAndRejected",
                translation: "Nicht in Ordnung",
            },
            {
                isValid: !status,
                translation: "",
            },
        ];

        return statusTypes.find((statusType) => statusType.isValid)!.translation!;
    }

    private isDatasetWaitingForIgCheck(dataset: IDataset): boolean {
        return Boolean(dataset.fileId && !dataset.status);
    }

    private stopIgCheckWaiting(): void {
        this.currentIgCheckExecution = null;
        setTimeout(() => {
            this.calculateGridHeight();
            this.dxDataGrid.instance.refresh();
        });
    }

    private waitIgCheckExecution(datasetId: number | undefined, num: number, callback: () => void): void {
        const currentTimeLessFiveMinutes = new Date();
        currentTimeLessFiveMinutes.setMinutes(currentTimeLessFiveMinutes.getMinutes() - 10);

        // eslint-disable-next-line complexity
        this.subscription = this.trackingService.findDataset(this.trackingSpecificationId, datasetId!).subscribe((dataset) => {
            if (this.isDatasetWaitingForIgCheck(dataset) && new Date(dataset.checkinDate) > currentTimeLessFiveMinutes && num < 30) {
                this.timeoutHandle = setTimeout(() => {
                    this.waitIgCheckExecution(datasetId, num + 1, callback);
                }, 10000);
            } else {
                this.subscription.unsubscribe();
                clearTimeout(this.timeoutHandle);
                datasetId = undefined;

                if (dataset.status === "valid") {
                    this.notificationService.notify("Die Daten wurden fehlerfrei eingelesen.");
                } else if (dataset.status === "invalid") {
                    const msg = "Die Daten haben die Qualitätskontrolle nicht bestanden. " +
                        "Das Fehlerprotokoll kann unter «Fehlerdatei anzeigen» eingesehen werden.";
                    this.notificationService.error(msg);
                }

                callback();
            }
        });
    }

    private getSelectedItem(): IDataset | null {
        return this.dxDataGrid?.instance.getSelectedRowsData()[0];
    }

    public onRowDblClick(event: RowDblClickEvent<ITrackingDashboard, number>): void {
        this.show(event.key);
    }

    public onContentReady(event: ContentReadyEvent): void {
        if (!event.component.getDataSource()) {
            event.component.option("dataSource", this.dataSource);
        }
    }

    public edit(): void {
        const selectedItem = this.getSelectedItem();

        if (selectedItem) {
            this.show(selectedItem.id!);
        }
    }

    public showProtocol(): void {
        this.stateService.go("app.trackings.protocol", { trackingSpecificationId: this.trackingSpecificationId });
    }

    public isDownloadSelectedItemLocked(): boolean {
        const item = this.getSelectedItem();
        return !(item && item.fileId);
    }

    public isCheckoutLocked(): boolean {
        const isAuthorized = this.identityService.isAuthorized(RoleName.FS, RoleName.ZS, RoleName.TP, RoleName.NS);
        return Boolean(this.currentCheckedOutDataset) || !isAuthorized;
    }

    public isCheckInLocked(): boolean {
        const selectedItem = this.getSelectedItem();

        if (!selectedItem) {
            return true;
        }

        return this.dxDataGrid.selectedRowKeys.length && this.currentCheckedOutDataset ?
            !this.isUserFromSameOrganistaionAsCheckoutUser(selectedItem) : true;
    }

    private isUserFromSameOrganistaionAsCheckoutUser(selectedItem: IDataset): boolean {
        return this.currentCheckedOutDataset?.id === selectedItem.id &&
            this.checkoutUser.organisationId === this.identityService.currentUser?.organisationId &&
            this.currentCheckedOutDataset!.checkinDate === null;
    }

    public download(): void {
        if (!this.isDownloadSelectedItemLocked()) {
            this.messageBox.custom(DownloadFileModalController, {
                title: "Download",
                width: "900px",
            }, {
                dataset: this.getSelectedItem()!,
                onClose: (datasetId: number, selectedDistribution: IDistribution | undefined) =>
                    this.onCloseDownload(datasetId, selectedDistribution),
            });
        }
    }

    private onCloseDownload(datasetId: number, selectedDistribution: IDistribution | undefined): void {
        if (!selectedDistribution) {
            this.notificationService.notify("Es wurde keine Verteilungskonfiguration ausgewählt.");
            return;
        }

        if (selectedDistribution.target === "direct") {
            this.trackingService.downloadDataset(this.trackingSpecificationId, datasetId).subscribe((response) => {
                const file = new Blob([response.body!], { type: "application/zip" });
                const disposition = response.headers.get("Content-disposition")!;
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(disposition)!;
                saveAs(file, matches[1].replace(/['"]/g, ""));
            }, () => {
                this.notificationService.error("Die Verteilungskonfigurationen konnten nicht ausgeführt werden.");
            });
        } else {
            this.trackingService.distributeDataset(this.trackingSpecificationId, datasetId, selectedDistribution).subscribe({
                next: () => {
                    this.notificationService.notify("Die Verteilungskonfigurationen wurden ausgeführt.");
                },
                error: () => {
                    this.notificationService.error("Die Verteilungskonfigurationen konnten nicht ausgeführt werden.");
                },
            });
        }
    }

    public checkin(): void {
        this.stateService.go("app.trackings.datasets.checkin", {
            trackingSpecificationId: this.trackingSpecificationId,
            id: this.currentCheckedOutDataset!.id,
        });
    }

    public checkout(): void {
        this.trackingService.checkout(this.trackingSpecificationId).subscribe(() => {
            this.dxDataGrid.instance.refresh();
            const urlDownload = `/api/tracking/${this.trackingSpecificationId}/dataset/original`;
            window.location.assign(urlDownload);

            this.messageBox.info(
                `Sie haben mit dem Checkout die Daten automatisch für die Nachführung heruntergeladen. Der Nachführungszeitraum beträgt 
                ${this.trackingSpecification.maxCheckoutPeriod} Tag${(this.trackingSpecification.maxCheckoutPeriod === 1 ? "" : "e")}.`,
                "CHECK_OUT.TITLE").subscribe();
        });
    }

    public back(): void {
        this.stateService.go("app.trackings");
    }

    public loadState(): Promise<DxGridState | undefined> {
        return super.loadState().then((data) => {
            const column = data?.columns.find((column) => column.dataField === this.extendedTechnicalCheckStatusColumn.dataField);

            if (column) {
                this.extendedTechnicalCheckStatusColumn.visible = this.hasExtendedTechnicalCheck;
            }

            return data;
        });
    }

    private getColumns(): IGridColumn[] {
        this.extendedTechnicalCheckStatusColumn = {
            dataField: "extendedTechnicalCheckStatus",
            dataType: "string",
            caption: this.translateService.instant("DATASET_LIST.GRID.EXTENDED_TP"),
            dataSource: this.expertCheckFilterOptions,
            visible: false,
        };

        return [
            {
                dataField: "status",
                dataType: "string",
                caption: this.translateService.instant("DATASET_LIST.GRID.STATUS"),
                cellTemplate: "statusCellTemplate",
                alignment: "center",
                headerFilter: {
                    dataSource: this.statusMapping.getStatusDataSource(),
                    width: 350,
                },
                lookup: {
                    dataSource: this.statusMapping.getStatusDataSource(),
                    displayExpr: "text",
                },
                allowFiltering: false,
                allowHeaderFiltering: true,
            }, {
                dataField: "topicName",
                dataType: "string",
                caption: this.translateService.instant("DATASET_LIST.GRID.TOPIC"),
            }, {
                dataField: "modelName",
                dataType: "string",
                caption: this.translateService.instant("DATASET_LIST.GRID.MODEL"),
            }, {
                dataField: "gemeindeName",
                dataType: "string",
                caption: this.translateService.instant("DATASET_LIST.GRID.GEMEINDE"),
            }, {
                dataField: "checkoutDate",
                dataType: "date",
                caption: this.translateService.instant("DATASET_LIST.GRID.CHECKOUT_DATE"),
                format: "dd.MM.yyyy",
                sortOrder: "desc",
            }, {
                dataField: "checkoutUser.username",
                dataType: "string",
                caption: this.translateService.instant("DATASET_LIST.GRID.CHECKOUT_USER"),
            }, {
                dataField: "checkinDate",
                dataType: "date",
                caption: this.translateService.instant("DATASET_LIST.GRID.CHECKIN_DATE"),
                format: "dd.MM.yyyy",
            }, {
                dataField: "checkinUser.username",
                dataType: "string",
                caption: this.translateService.instant("DATASET_LIST.GRID.CHECKIN_USER"),
            }, {
                dataField: "technicalcheck",
                dataType: "string",
                caption: this.translateService.instant("DATASET_LIST.GRID.TP"),
                dataSource: this.technicalCheckFilterOptions,
            }, this.extendedTechnicalCheckStatusColumn, {
                dataField: "expertcheck",
                dataType: "string",
                caption: this.translateService.instant("DATASET_LIST.GRID.FP"),
                dataSource: this.expertCheckFilterOptions,
            }, {
                dataField: "fileId",
                dataType: "string",
                visible: false,
                showInColumnChooser: false,
            }, {
                dataField: "hasbeenrejected",
                dataType: "boolean",
                visible: false,
                showInColumnChooser: false,
            }, {
                dataField: "approved",
                dataType: "boolean",
                visible: false,
                showInColumnChooser: false,
            }, {
                dataField: "geoportalFtpUploadSucceeded",
                dataType: "boolean",
                visible: false,
                showInColumnChooser: false,
            }, {
                dataField: "manualApproveDate",
                dataType: "date",
                visible: false,
                showInColumnChooser: false,
            },
        ];
    }

}
