import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";


@Injectable({
    providedIn: "root",
})
export class PrimaryAreaService {

    private readonly cantonUrl: string;

    public constructor(
        private readonly httpClient: HttpClient,
    ) {
        this.cantonUrl = "/api/instance/canton";
    }


    public getCanton(): Observable<{ canton: string }> {
        return this.httpClient.get<{ canton: string }>(`${this.cantonUrl}`);
    }
}
