import { LogoExtension } from "@geolib/geoappbase-client";
import { LogoComponent } from "./logo.component";
import { Injectable } from "@angular/core";


@Injectable({ providedIn: "root" })
export class GeobusLogoExtension extends LogoExtension<LogoComponent> {

    public isEnabled(): boolean {
        return true;
    }

    public get component(): typeof LogoComponent {
        return LogoComponent;
    }
}
