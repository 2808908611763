import { ChangeDetectorRef, Component, ElementRef, OnInit } from "@angular/core";
import { FtpServerService } from "./FtpServerService";
import IFtpServer from "./IFtpServer";
import { MessageBox, NotificationService, StateService } from "@geolib/geolib-client";
import DataSource from "devextreme/data/data_source";
import { ContentReadyEvent, SelectionChangedEvent } from "devextreme/ui/data_grid";
import { IGridColumn } from "../../../common/IGridColumn";
import { TranslateService } from "@ngx-translate/core";
import { isNumber } from "../common/IsNumber";
import GridController from "../common/GridController";
import GridService from "../common/GridService";
import { UserSettingsService } from "@geolib/geoappbase-client";
import { NGXLogger } from "ngx-logger";
import CustomStore from "devextreme/data/custom_store";
import { IdentityService } from "../account/IdentityService";
import { lastValueFrom } from "rxjs";


@Component({
    selector: "ftp-server",
    templateUrl: "./ftp-server.component.html",
})
export default class FtpServerController extends GridController implements OnInit {

    public dataSource?: DataSource<IFtpServer, string>;
    public dataGridColumns: IGridColumn[] = [];

    public selectedItem: IFtpServer | null;
    public ftpType: Array<{ key: string; value: string; disabled?: boolean }> = [];

    // eslint-disable-next-line max-params
    public constructor(
        private readonly ftpServerService: FtpServerService,
        private readonly translateService: TranslateService,
        private readonly messageBox: MessageBox,
        private readonly notificationService: NotificationService,
        private readonly stateService: StateService,
        elementRef: ElementRef<HTMLElement>,
        gridService: GridService,
        userSettingsService: UserSettingsService,
        logger: NGXLogger,
        changeDetectorRef: ChangeDetectorRef,
        identityService: IdentityService,
    ) {
        super(elementRef.nativeElement, gridService, userSettingsService, logger, changeDetectorRef, identityService);

        this.ftpType = this.ftpServerService.serverTypeDefinition;
    }

    protected get settingsKey(): string {
        return "geobus.ftpServer.grid";
    }

    public ngOnInit(): void {
        this.dataGridColumns = this.getColumns();
        this.dataSource = new DataSource<IFtpServer, string>({
            store: new CustomStore({
                key: "id",
                load: async (): Promise<IFtpServer[]> => {
                    const ftpServers: IFtpServer[] = await lastValueFrom(this.ftpServerService.findAll());
                    this.afterGridDataLoaded();
                    return ftpServers;
                },
            }),
        });
    }

    public create(): void {
        this.stateService.go("app.ftpServer.edit", { id: "create" });
    }

    public delete(): void {
        const selectedItem = this.getSelectedItem();

        if (!selectedItem) {
            return;
        }

        if (!selectedItem.id || isNumber(selectedItem.id)) {
            this.removeFtpServer(selectedItem);
            this.selectedItem = null;
            return;
        }

        this.messageBox.confirmDelete({ translationKey: "SYSTEM.DELETE_CONFIRMATION", name: selectedItem.server }).subscribe(() => {
            this.ftpServerService.remove(selectedItem).subscribe({
                next: () => {
                    this.removeFtpServer(selectedItem);
                    this.selectedItem = null;
                    this.notificationService.notify(this.translateService.instant("FTP_SERVER.MESSAGE.DELETE_SUCCESS"));
                },
                error: () => {
                    this.notificationService.error(this.translateService.instant("FTP_SERVER.MESSAGE.DELETE_FAILURE"));
                },
            });
        });
    }

    private removeFtpServer(selectedItem: IFtpServer): void {
        selectedItem = selectedItem || this.getSelectedItem();

        if (!selectedItem) {
            return;
        }

        this.dxDataGrid.instance.deleteRow(this.dxDataGrid.instance.getRowIndexByKey(selectedItem.id));
    }

    public edit(): void {
        const selectedItem = this.getSelectedItem();

        if (!selectedItem) {
            return;
        }

        this.stateService.go("app.ftpServer.edit", { id: selectedItem.id });
    }

    public onRowDblClick(): void {
        return this.edit();
    }

    public onContentReady(event: ContentReadyEvent): void {
        if (!event.component.getDataSource()) {
            event.component.option("dataSource", this.dataSource);
        }
    }

    public hasSelectedItem(): boolean {
        return !!this.getSelectedItem();
    }

    private getSelectedItem(): IFtpServer | undefined {
        return this.dxDataGrid?.instance.getSelectedRowsData()[0];
    }

    public selectionChanged(event: SelectionChangedEvent<IFtpServer, number>): void {
        if (event.selectedRowsData.length > 0) {
            this.selectedItem = event.selectedRowsData[0];
            this.setDisabledProperty();
        }
    }

    private setDisabledProperty(): void {
        for (const ftpType of this.ftpType) {
            ftpType.disabled = this.selectedItem?.type ? ftpType.key !== this.selectedItem.type : false;
        }
    }

    private getColumns(): IGridColumn[] {
        return [
            {
                dataField: "type",
                caption: this.translateService.instant("FTP_SERVER.GRID.TYPE"),
                groupIndex: 0,
                sortOrder: "asc",
                cellTemplate: "typeTemplate",
            }, {
                dataField: "server",
                caption: this.translateService.instant("FTP_SERVER.GRID.SERVER"),
                groupIndex: 1,
            }, {
                dataField: "port",
                caption: this.translateService.instant("FTP_SERVER.GRID.PORT"),
                groupIndex: 2,
            },
        ];
    }
}
