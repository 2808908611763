<div class="gp-page-header">
    <div class="gp-container gp-container-extra-large">
        <breadcrumb></breadcrumb>
        <div class="gp-page-header-container">
            <h1 class="gp-page-header-title">{{ "SIDE_MENU.GENERAL.TRACKINGS" | translate }}</h1>
            <div class="gp-page-header-actions">
                <gp-button-primary
                    (click)="showLastDatasets()"
                    [isDisabled]="isSelectedItemModifiable()"
                    translation="TRACKING.SHOW_DATASETS"
                ></gp-button-primary>
            </div>
        </div>
    </div>
</div>
<div class="gp-container gp-container-extra-large">
    <dx-data-grid
        class="hide-overflow w-100"
        [height]="gridHeight"
        [showBorders]="true"
        [columnAutoWidth]="true"
        [allowColumnReordering]="true"
        [rowAlternationEnabled]="true"
        [allowColumnResizing]="true"
        columnResizingMode="widget"
        (onRowDblClick)="onRowDblClick($event)"
        (onContentReady)="onContentReady($event)"
        [columnChooser]="columnChooserOptions"
        (onEditorPreparing)="onEditorPreparing($event)"
        (onCellPrepared)="onCellPrepared($event)"
        [syncLookupFilterValues]="false"
    >
        <dxo-column-chooser [enabled]="true"></dxo-column-chooser>
        <dxo-search-panel [visible]="true"></dxo-search-panel>
        <dxo-filter-panel [visible]="true"></dxo-filter-panel>
        <dxo-header-filter [visible]="true"></dxo-header-filter>
        <dxo-filter-row [visible]="true"></dxo-filter-row>
        <dxo-sorting mode="multiple" [showSortIndexes]="true"></dxo-sorting>
        <dxo-selection showCheckBoxesMode="never" mode="single"></dxo-selection>
        <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
        <dxo-state-storing [enabled]="true" type="custom" [customLoad]="loadState" [customSave]="saveState" [savingTimeout]="250"></dxo-state-storing>
        <dxo-toolbar>
            <dxi-item>
                <dx-button
                    (onClick)="toggleColumnChooser()"
                    [type]="hasHiddenColumns() ? 'danger' : 'normal'"
                    icon="column-chooser"
                    [hint]="getDevExpressTranslation('dxDataGrid-columnChooserTitle')"
                ></dx-button>
            </dxi-item>
            @if (isAdmin) {
                <dxi-item>
                    <dx-button (onClick)="resetGridSettings()" type="normal" icon="revert" [hint]="'GRID.REVERT_SETTINGS' | translate"></dx-button>
                </dxi-item>
            }
        </dxo-toolbar>
        @for (column of dataGridColumns; track column) {
            <dxi-column
                [allowResizing]="true"
                [allowSorting]="true"
                [dataField]="column.dataField"
                [caption]="column.caption"
                [visible]="column.visible"
                [width]="column.width"
                [sortOrder]="column.sortOrder"
                [dataType]="column.dataType"
                [format]="column.format"
                [cellTemplate]="column.cellTemplate"
                [alignment]="column.alignment"
                [headerFilter]="column.headerFilter"
                [lookup]="column.lookup"
                [showInColumnChooser]="column.showInColumnChooser"
                [allowHeaderFiltering]="column.allowHeaderFiltering"
                [allowFiltering]="column.allowFiltering"
            ></dxi-column>
        }
        <div *dxTemplate="let cell of 'statusCellTemplate'">
            <span [class]="evaluateStyleClasses(cell.data.lastDataset)" [title]="addCustomTooltip(cell.data.lastDataset)"> </span>
        </div>
    </dx-data-grid>
    <div class="gp-margin-top-big flex-start-container grid-controls">
        <div>
            @if (userHasEditPermissions()) {
                <button class="btn btn-default" (click)="editDistribution()" [disabled]="isSelectedItemModifiable()">
                    {{ "TRACKING.EDIT_DISTRIBUTION" | translate }}
                </button>
            }
        </div>
        <div>
            @if (userHasCreateAndDropPermissions()) {
                <button class="btn btn-default" (click)="delete()" [disabled]="isSelectedItemModifiable()">
                    {{ "TRACKING.DELETE" | translate }}
                </button>
            }
            @if (userHasCreateAndDropPermissions()) {
                <button class="btn btn-default" (click)="create()">
                    {{ "TRACKING.CREATE" | translate }}
                </button>
            }
            @if (userHasEditPermissions() && !isEditSelectedItemNotAllowed()) {
                <button class="btn btn-default" (click)="edit()" [disabled]="isSelectedItemModifiable()">
                    {{ "TRACKING.EDIT" | translate }}
                </button>
            }
        </div>
    </div>
</div>
