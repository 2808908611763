<div class="gp-page-header">
    <div class="gp-container-normal">
        <breadcrumb></breadcrumb>
        <div class="gp-page-header-container">
            @if (gemeinde.name) {
                <h1 class="gp-page-header-title">{{ gemeinde.name }}</h1>
            }
            @if (!gemeinde.name) {
                <h1 class="gp-page-header-title">{{ "GEMEINDE_EDIT.TITLE" | translate }}</h1>
            }
            <div class="gp-page-header-actions">
                <div class="btn-group">
                    <button class="btn btn-default btn-back" (click)="abort()">
                        {{ "NAVIGATION.BACK" | translate }}
                    </button>
                    <gp-button-primary (click)="save()" [isDisabled]="!communityEditForm.valid" translation="NAVIGATION.SAVE"></gp-button-primary>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="gp-container-normal">
    <form class="panel panel-default" #communityEditForm="ngForm" (ngSubmit)="save()">
        <div class="row">
            <label class="col-md-6">
                <div class="control-label">{{ "GEMEINDE_EDIT.AREA" | translate }}</div>
                <input class="form-control" type="text" [required]="true" [(ngModel)]="gemeinde.name" name="gebiet" />
            </label>
            <label class="col-md-6">
                <div class="control-label">{{ "GEMEINDE_EDIT.PRIMARY_AREA" | translate }}</div>
                <input class="form-control" type="text" [(ngModel)]="gemeinde.primaryArea" [required]="true" name="primaryarea" />
            </label>
        </div>
        <div class="row">
            <label class="col-md-6">
                <div class="control-label">{{ "GEMEINDE_EDIT.BFSNR" | translate }}</div>
                <input class="form-control" type="text" [required]="true" [(ngModel)]="gemeinde.bfsnr" name="bfnr" />
            </label>
        </div>
    </form>
</div>
