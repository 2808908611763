import { lastValueFrom } from "rxjs";
import { AuthenticationService } from "./account/AuthenticationService";
import { RoleName } from "./user/IRole";
import { Ng2StateDeclaration, RedirectToResult, UIView } from "@uirouter/angular";
import { NotificationService, StateService } from "@geolib/geolib-client";
import UserListController from "./user/UserListController";
import UserEditController from "./user/UserEditController";
import UserProfileController from "./user/UserProfileController";
import EventSubscriptionController from "./user/EventSubscriptionController";
import OrganisationListController from "./organisation/OrganisationListController";
import OrganisationEditController from "./organisation/OrganisationEditController";
import GemeindeListController from "./gemeinde/GemeindeListController";
import GemeindeEditController from "./gemeinde/GemeindeEditController";
import TopicListController from "./topic/TopicListController";
import TopicEditController from "./topic/TopicEditController";
import ModelListController from "./model/ModelListController";
import ModelEditController from "./model/ModelEditController";
import FtpServerController from "./ftpServer/FtpServerController";
import DownloadModelListController from "./downloadmodel/DownloadModelListController";
import PasswordRecoveryController from "./passwordrecovery/PasswordRecoveryController";
import PasswordResetController from "./passwordrecovery/PasswordResetController";
import TrackingListController from "./tracking/TrackingListController";
import TrackingEditController from "./tracking/TrackingEditController";
import DistributionComponent from "./distribution/Distribution";
import DatasetListController from "./tracking/DatasetListController";
import DatasetEditController from "./tracking/DatasetEditController";
import DatasetCheckinController from "./tracking/DatasetCheckinController";
import ProtocolController from "./tracking/ProtocolController";
import { HomePageGeoAppController, MyGeoportalController } from "@geolib/geoappbase-client";
import MainController from "./main/MainController";
import FtpServerEditComponent from "./ftpServer/ftp-server-edit.component";
import DistributionEditComponent from "./distribution/distribution-edit.component";
import { IdentityService } from "./account/IdentityService";


export interface IStateDto extends Ng2StateDeclaration {
}

function createRoleCheck(...roles: RoleName[]) {
    return {
        auth: [
            AuthenticationService,
            StateService,
            IdentityService,
            NotificationService,

            (authenticationService: AuthenticationService, stateService: StateService, identityService: IdentityService,
                notificationService: NotificationService,
            ) => {
                return lastValueFrom(authenticationService.getUser())
                    .then(() => authenticationService.authorizeCurrentUserForRoute(roles))
                    .catch(() => {
                        if (identityService.isAuthenticated()) {
                            notificationService.error("NOT_AUTHORIZED");
                            return Promise.reject();
                        } else {
                            return stateService.go("login");
                        }
                    });
            },
        ],
    };
}

function getUser() {
    return {
        auth: [
            AuthenticationService,
            StateService,

            (authenticationService: AuthenticationService, stateService: StateService) => {
                return lastValueFrom(authenticationService.getUser())
                    .then(() => Promise.resolve())
                    .catch(() => stateService.go("login"));
            },
        ],
    };
}

const states: IStateDto[] = [
    {
        name: "login",
        url: "/",
        component: HomePageGeoAppController,
        data: { public: true },
    }, {
        name: "app",
        url: "/:primaryArea",
        component: MyGeoportalController,
    }, {
        name: "app.main",
        url: "/dashboard",
        component: MainController,
        resolve: getUser(),
    }, {
        name: "app.users",
        url: "/users",
        component: UIView,
        data: {
            roles: [RoleName.FS, RoleName.ZS, RoleName.TP],
        },
        redirectTo: (): RedirectToResult => "app.users.list",
    }, {
        name: "app.users.list",
        component: UserListController,
    }, {
        name: "app.users.edit",
        url: "/:id",
        component: UserEditController,
    }, {
        name: "app.profile",
        url: "/profile",
        component: UserProfileController,
        data: {
            roles: [RoleName.FS, RoleName.ZS, RoleName.NS, RoleName.TP],
        },
    }, {
        name: "app.profileEvents",
        url: "/profile/events",
        component: EventSubscriptionController,
        data: {
            roles: [RoleName.FS, RoleName.ZS, RoleName.NS, RoleName.TP],
        },
    }, {
        name: "app.organisations",
        url: "/organisations",
        component: UIView,
        data: {
            roles: [RoleName.FS, RoleName.ZS, RoleName.TP],
        },
        redirectTo: (): RedirectToResult => "app.organisations.list",
    }, {
        name: "app.organisations.list",
        component: OrganisationListController,
    }, {
        name: "app.organisations.edit",
        url: "/:id",
        component: OrganisationEditController,
        data: {
            roles: [RoleName.FS],
        },
    },
    {
        name: "app.gemeinden",
        url: "/gemeinden",
        component: UIView,
        data: {
            roles: [RoleName.FS],
        },
        redirectTo: (): RedirectToResult => "app.gemeinden.list",
    }, {
        name: "app.gemeinden.list",
        component: GemeindeListController,
    }, {
        name: "app.gemeinden.edit",
        url: "/:id",
        component: GemeindeEditController,
    }, {
        name: "app.topics",
        url: "/topics",
        component: UIView,
        data: {
            roles: [RoleName.FS],
        },
        redirectTo: (): RedirectToResult => "app.topics.list",
    }, {
        name: "app.topics.list",
        component: TopicListController,
    }, {
        name: "app.topics.edit",
        url: "/:id",
        component: TopicEditController,
    }, {
        name: "app.models",
        url: "/models",
        component: UIView,
        data: {
            roles: [RoleName.FS, RoleName.ZS, RoleName.NS, RoleName.TP],
        },
        redirectTo: (): RedirectToResult => "app.models.list",
    }, {
        name: "app.models.list",
        component: ModelListController,
    }, {
        name: "app.models.edit",
        url: "/:id",
        component: ModelEditController,
        data: {
            roles: [RoleName.FS, RoleName.TP],
        },
    }, {
        name: "app.ftpServer",
        url: "/ftpServer",
        component: UIView,
        data: {
            roles: [RoleName.FS],
        },
        redirectTo: (): RedirectToResult => "app.ftpServer.list",
    }, {
        name: "app.ftpServer.list",
        component: FtpServerController,
        data: {
            roles: [RoleName.FS],
        },
    }, {
        name: "app.ftpServer.edit",
        url: "/:id",
        component: FtpServerEditComponent,
        data: {
            roles: [RoleName.FS],
        },
    }, {
        name: "modeldownload",
        url: "/modeldownload",
        component: DownloadModelListController,
        data: { public: true },
    }, {
        name: "passwordrecovery",
        url: "/passwordrecovery?userName",
        component: PasswordRecoveryController,
        data: { public: true },
    }, {
        name: "passwordrecoveryReset",
        url: "/passwordrecovery/reset?token",
        component: PasswordResetController,
        data: { public: true },
    }, {
        name: "app.trackings",
        url: "/trackings",
        component: UIView,
        data: {
            roles: [RoleName.FS, RoleName.ZS, RoleName.NS, RoleName.TP],
        },
        redirectTo: (): RedirectToResult => "app.trackings.list",
    }, {
        name: "app.trackings.list",
        component: TrackingListController,
    }, {
        name: "app.trackings.edit",
        url: "/:id",
        component: TrackingEditController,
        data: {
            roles: [RoleName.FS, RoleName.ZS, RoleName.TP],
        },
    }, {
        name: "app.trackings.distribution",
        url: "/:id/distribution",
        component: UIView,
        redirectTo: (): RedirectToResult => "app.trackings.distribution.list",
    }, {
        name: "app.trackings.distribution.list",
        component: DistributionComponent,
    }, {
        name: "app.trackings.distribution.edit",
        url: "/:distributionId",
        component: DistributionEditComponent,
    }, {
        name: "app.trackings.protocol",
        url: "/:trackingSpecificationId/protocol",
        component: ProtocolController,
    }, {
        name: "app.trackings.datasets",
        url: "/:trackingSpecificationId/dataset",
        component: UIView,
        redirectTo: (): RedirectToResult => "app.trackings.datasets.list",
    }, {
        name: "app.trackings.datasets.list",
        component: DatasetListController,
    }, {
        name: "app.trackings.datasets.edit",
        url: "/:id",
        component: DatasetEditController,
    }, {
        name: "app.trackings.datasets.checkin",
        url: "/:id/checkin",
        component: DatasetCheckinController,
    },
];

const statesMap = new Map<string, IStateDto>();

for (const state of states) {
    if (state.name) {
        statesMap.set(state.name, state);
    }

    if (Array.isArray(state.data?.roles)) {
        state.resolve = createRoleCheck(...state.data.roles);
    }
}

export { states, statesMap };
