import { Component, Input } from "@angular/core";
import IDataset from "../../IDataset";
import { MessageBox } from "@geolib/geolib-client";


@Component({
    selector: "tech-reject-dialog",
    templateUrl: "./techRejectDialog.html",
})
export default class TechRejectDialog {

    @Input() public dataset: IDataset;
    @Input() public techApprove: () => void;
    @Input() public techReject: () => void;

    public techApproveAction: string;

    public constructor(
        private readonly messageBox: MessageBox,
    ) {
    }

    public cancel(): void {
        this.messageBox.hideAll();
    }

    public approve(): void {
        this.techApprove();
        this.cancel();
    }

    public reject(): void {
        this.techReject();
        this.cancel();
    }

}
