import { BackendService } from "@geolib/geolib-client";
import IGemeinde from "./IGemeinde";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";


@Injectable({ providedIn: "root" })
export class GemeindeService extends BackendService<IGemeinde, string> {

    public constructor(
        http: HttpClient,
    ) {
        super("/api/gemeinde", http);
    }

    public createOne(): IGemeinde {
        return {
            name: null,
            bfsnr: null,
            primaryArea: null,
            id: Math.random().toString(),
        };
    }

}
