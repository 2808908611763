import { GemeindeService } from "./GemeindeService";
import { ChangeDetectorRef, Component, ElementRef, OnInit } from "@angular/core";
import IGemeinde from "./IGemeinde";
import { MessageBox, NotificationService, StateService } from "@geolib/geolib-client";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { IGridColumn } from "../../../common/IGridColumn";
import { TranslateService } from "@ngx-translate/core";
import GridController from "../common/GridController";
import GridService from "../common/GridService";
import { UserSettingsService } from "@geolib/geoappbase-client";
import { NGXLogger } from "ngx-logger";
import { ContentReadyEvent } from "devextreme/ui/data_grid";
import { IdentityService } from "../account/IdentityService";
import { lastValueFrom } from "rxjs";


@Component({
    selector: "gemeinde-list",
    templateUrl: "./gemeinde-list.html",
})
export default class GemeindeListController extends GridController implements OnInit {

    public dataSource?: DataSource<IGemeinde, string>;
    public dataGridColumns: IGridColumn[] = [];

    // eslint-disable-next-line max-params
    public constructor(
        private readonly stateService: StateService,
        private readonly gemeindeService: GemeindeService,
        private readonly notificationService: NotificationService,
        private readonly translateService: TranslateService,
        private readonly messageBox: MessageBox,
        elementRef: ElementRef<HTMLElement>,
        gridService: GridService,
        userSettingsService: UserSettingsService,
        logger: NGXLogger,
        changeDetectorRef: ChangeDetectorRef,
        identityService: IdentityService,
    ) {
        super(elementRef.nativeElement, gridService, userSettingsService, logger, changeDetectorRef, identityService);
    }

    protected get settingsKey(): string {
        return "geobus.gemeindeList.grid";
    }

    public ngOnInit(): void {
        this.dataGridColumns = this.getColumns();
        this.dataSource = new DataSource({
            store: new CustomStore({
                key: "id",
                load: async (): Promise<IGemeinde[]> => {
                    const gemeinden = await lastValueFrom(this.gemeindeService.findAll());
                    this.afterGridDataLoaded();
                    return gemeinden;
                },
            }),
        });
    }

    public onRowDblClick(): void {
        this.edit();
    }

    public onContentReady(event: ContentReadyEvent): void {
        if (!event.component.getDataSource()) {
            event.component.option("dataSource", this.dataSource);
        }
    }

    public create(): void {
        this.stateService.go("app.gemeinden.edit", { id: "create" });
    }

    public delete(): void {
        const selectedItem = this.getSelectedItem();

        if (!selectedItem) {
            return;
        }

        this.messageBox.confirmDelete({ translationKey: "SYSTEM.DELETE_CONFIRMATION", name: selectedItem.name }).subscribe(() => {
            this.gemeindeService.remove(selectedItem).subscribe({
                next: () => {
                    this.notificationService.notify("Gebiet gelöscht");
                    this.dxDataGrid.instance.refresh();
                },
                error: () => {
                    this.notificationService.error("Das Gebiet konnte nicht gelöscht werden.");
                },
            });
        });
    }

    public edit(): void {
        const selectedItem = this.getSelectedItem();

        if (!selectedItem) {
            return;
        }

        this.stateService.go("app.gemeinden.edit", { id: selectedItem.id });
    }

    public hasSelectedItem(): boolean {
        return !!this.getSelectedItem();
    }

    private getSelectedItem(): IGemeinde | undefined {
        return this.dxDataGrid?.instance.getSelectedRowsData()[0];
    }

    private getColumns(): IGridColumn[] {
        return [
            {
                dataField: "name",
                caption: this.translateService.instant("GEMEINDE.GRID.NAME"),
                groupIndex: 0,
                sortOrder: "asc",
            }, {
                dataField: "bfsnr",
                caption: this.translateService.instant("GEMEINDE.GRID.BFSNR"),
                groupIndex: 1,
            }, {
                dataField: "primaryArea",
                caption: this.translateService.instant("GEMEINDE.GRID.PRIMARYAREA"),
                groupIndex: 2,
            },
        ];
    }
}
