<div class="gp-page-header">
    <div class="gp-container-normal">
        <breadcrumb></breadcrumb>
        <div class="gp-page-header-container">
            <h1 class="gp-page-header-title">{{ "SUBSCRIPTION.TITLE" | translate }}</h1>
            <div class="gp-page-header-actions">
                <div class="btn-group">
                    <button class="btn btn-default btn-back" (click)="back()">
                        {{ "NAVIGATION.BACK" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<form class="gp-container-normal">
    <h4 class="margin-top-extra-large gp-margin-bottom-large">{{ "SUBSCRIPTION.EVENTS.TITLE" | translate }}</h4>
    <div class="row">
        @for (subscriptEvent of subscriptEvents; track subscriptEvent; let indexOfEvent = $index) {
            <div class="col-md-4">
                @if (hasUserFsPrivilege(subscriptEvent)) {
                    <label class="form-group"
                        >{{ subscriptEvent.label }}<br />
                        <div class="btn-group">
                            <dx-switch
                                [(ngModel)]="subscriptEvent.checked"
                                (ngModelChange)="eventChanged(subscriptEvent)"
                                switchedOnText="Ja"
                                switchedOffText="Nein"
                                [name]="'subscriptEvent_' + indexOfEvent"
                            ></dx-switch>
                        </div>
                    </label>
                }
            </div>
        }
    </div>
    <div class="gp-delemiter"></div>
    <h4 class="margin-top-extra-large gp-margin-bottom-large">{{ "SUBSCRIPTION.TOPICS.TITLE" | translate }}</h4>
    <div class="row">
        <topic-notification-selector class="gp-container-normal"></topic-notification-selector>
    </div>
</form>
