import { StateService } from "@geolib/geolib-client";
import { UserService } from "../user/UserService";
import { Component, OnInit } from "@angular/core";
import { NotificationService } from "@geolib/geolib-client";


@Component({
    selector: "password-recovery",
    templateUrl: "./password-recovery.html",
})
export default class PasswordRecoveryController implements OnInit {

    public user: { name: string };

    public constructor(
        private readonly userService: UserService,
        private readonly stateService: StateService,
        private readonly notificationService: NotificationService,
    ) {
        this.user = { name: "" };
    }

    public ngOnInit(): void {
        this.user.name = this.stateService.getParameter("userName");
    }

    public save(): void {
        this.userService.requestNewPassword(this.user.name).subscribe({
            next: () => {
                this.notificationService.notify("Anfrage erfolgreich übermittelt.");
                this.abort();
            },
            error: () => {
                this.notificationService.error("Ihre Anfrage konnte nicht ordnungsgemäss übertragen werden.");
            },
        });
    }

    public abort(): void {
        this.stateService.go("login");
    }
}
