<div class="gp-startpage">
    <div class="gp-app-title"><img src="/images/logo/GEOBUS.svg" /></div>
    <div class="gp-startpage-scrollpanel">
        <div class="gp-pagewidth gp-pagewidth-geoapp gp-margin-top-big">
            <div class="gp-page-header">
                <div class="gp-container-normal">
                    <div class="gp-page-header-container">
                        <h1 class="gp-page-header-title">{{ "PASSWORD_RECOVERY.TITLE" | translate }}</h1>
                    </div>
                </div>
            </div>
            <form class="gp-container-normal" #passwordRecoveryForm="ngForm">
                <div class="row">
                    <label class="col-md-6">
                        <div class="col-form-label">{{ "PASSWORD_RECOVERY.USER_NAME" | translate }}</div>
                        <input class="form-control" [required]="true" [(ngModel)]="user.name" name="username" autofocus="autofocus" />
                    </label>
                </div>
                <div class="gp-margin-top-big flex-start-container float-right">
                    <div class="btn-group">
                        <button class="btn btn-default btn-back" (click)="abort()">
                            {{ "NAVIGATION.BACK" | translate }}
                        </button>
                        <gp-button-primary
                            (click)="save()"
                            translation="PASSWORD_RECOVERY.SEND"
                            [isDisabled]="!passwordRecoveryForm.valid"
                        ></gp-button-primary>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <footer></footer>
</div>
