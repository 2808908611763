import { Component, OnDestroy, OnInit } from "@angular/core";
import { TransitionService, UIRouterGlobals } from "@uirouter/angular";
import { StateParams } from "@uirouter/core/lib/params/stateParams";
import { BreadcrumbService, IBreadcrumb } from "./breadcrumb.service";

@Component({
    selector: "breadcrumb",
    templateUrl: "./breadcrumb.component.html",
})

export class BreadcrumbComponent implements OnInit, OnDestroy {

    // eslint-disable-next-line @typescript-eslint/ban-types
    private deregisterStateChange: Function;

    public constructor(
        private readonly breadcrumbService: BreadcrumbService,
        private readonly uiRouterGlobals: UIRouterGlobals,
        private readonly transitionService: TransitionService,

    ) {
    }

    public ngOnInit(): void {
        this.deregisterStateChange = this.transitionService.onSuccess({ to: "**" }, () => this.breadcrumbService.stateChangeListener());
        this.breadcrumbService.stateChangeListener();
    }

    public get breadcrumbs(): IBreadcrumb[] {
        return this.breadcrumbService.breadcrumbs;
    }

    public getStateName(state: IBreadcrumb): string {
        if (!state.url) {
            return "";
        }
        return state.name!;
    }

    public getStateParams(): StateParams {
        return this.uiRouterGlobals.params;
    }

    public identify(index: number): number {
        return index;
    }

    public ngOnDestroy(): void {
        this.deregisterStateChange();
    }
}
