import { IdentityService } from "./IdentityService";
import { RoleName } from "../user/IRole";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { filter, map, tap } from "rxjs/operators";
import { BehaviorSubject, Observable, of } from "rxjs";
import IUser from "../user/IUser";
import { EventEmitterCallback } from "./GeobusLoginService";


@Injectable({
    providedIn: "root",
})
export class AuthenticationService {

    private lastAuthorized = 0;
    private readonly observeUser: BehaviorSubject<IUser | undefined>;

    public constructor(
        private readonly http: HttpClient,
        private readonly identityService: IdentityService,
    ) {
        this.observeUser = new BehaviorSubject<IUser | undefined>(undefined);
        this.checkInitialLoginState();
    }

    private checkInitialLoginState(): void {
        this.getUser().subscribe({
            next: (response) => this.observeUser.next(response.user),
            error: () => this.observeUser.next(undefined),
        });
    }

    public wasPreviouslyAuthorized(): boolean {
        return (Date.now() - this.lastAuthorized < 60 * 1000);
    }

    public authenticateUser(username: string, password: string, rememberme: boolean): Observable<{ success: boolean; user: IUser; reason?: string }> {
        return this.http.post("/api/login", { username, password, logintype: "local", rememberme }).pipe(
            tap((data: { success: boolean; user: IUser; reason?: string }) => {
                if (data.success) {
                    this.identityService.currentUser = data.user;
                    this.observeUser.next(data.user);
                } else {
                    throw new Error("login failed");
                }
            }));
    }

    public getUser(): Observable<{ success: boolean; user: IUser; reason?: string }> {
        if (!this.wasPreviouslyAuthorized() && this.identityService.currentUser) {
            return of({ success: true, user: this.identityService.currentUser });
        }

        return this.http.get("/api/login").pipe(
            tap((data: { success: boolean; user: IUser; reason?: string }) => {
                if (data.success) {
                    this.identityService.currentUser = data.user;
                } else {
                    throw new Error("login failed");
                }
            }));
    }

    public logoutUser(): Observable<unknown> {
        return this.http.post("/api/logout", { logout: true }).pipe(
            tap(() => {
                this.identityService.currentUser = undefined;
                this.observeUser.next(undefined);
            }),
        );
    }

    public authorizeCurrentUserForRoute(roles: RoleName[]): Promise<boolean> {
        if (this.identityService.isAuthorized(...roles)) {
            return Promise.resolve(true);
        } else {
            return Promise.reject("not authorized");
        }
    }

    /**
     * @deprecated Use `observeIsLoggedIn` instead
     */
    public onLogin(listener: EventEmitterCallback): void {
        this.observeUser.asObservable().pipe(
            filter((user) => !!user),
        ).subscribe(() => listener());
    }

    /**
     * @deprecated Use `observeIsLoggedIn` instead
     */
    public onLogout(listener: EventEmitterCallback): void {
        this.observeUser.asObservable().pipe(
            filter((user) => !user),
        ).subscribe(() => listener());
    }

    public observeIsLoggedIn(): Observable<boolean> {
        return this.observeUser.asObservable().pipe(map((user) => !!user));
    }

}
