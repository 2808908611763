import IEventSubscription from "../tracking/IEventSubscription";
import { BackendService } from "@geolib/geolib-client";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";


@Injectable({ providedIn: "root" })
export class EventSubscriptionService extends BackendService<IEventSubscription, string> {

    public constructor(
        http: HttpClient,
    ) {
        super("/api/users/events", http);
    }

    public subscribeEvent(eventSubscription: IEventSubscription): Observable<void> {
        return this.http.post<void>(`/api/users/events/${eventSubscription.event}`, null);
    }

    public unsubscribeEvent(eventSubscription: IEventSubscription): Observable<void> {
        return this.http.delete<void>(`/api/users/events/${eventSubscription.event}`);
    }

    public createEventSubscription(event: string): IEventSubscription {
        return { event };
    }

    public createOne(): IEventSubscription {
        throw new Error("Method not implemented.");
    }

}
