import { Component, Input, OnInit } from "@angular/core";
import { PrimaryArea } from "@geolib/geoappbase-client";


@Component({
    templateUrl: "./header-info.component.html",
    selector: "geobus-header-info",
    styleUrls: ["./header-info.component.scss"],
})
export class HeaderInfoComponent implements OnInit {

    @Input() public extensionData: { primaryArea: string; primaryAreaName: string; primaryAreaSubTitle: string };

    public constructor(
        private readonly primaryArea: PrimaryArea,
    ) {
    }

    public ngOnInit(): void {
        this.primaryArea.getPrimaryAreaData(this.extensionData.primaryArea).subscribe((primaryAreaData) => {
            this.extensionData = {
                primaryArea: this.extensionData.primaryArea,
                primaryAreaName: primaryAreaData.label,
                primaryAreaSubTitle: primaryAreaData.subLabel,
            };
        });
    }

}
