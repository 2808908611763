import { Injectable } from "@angular/core";
import { MoreNavigationDialogExtension } from "@geolib/geoappbase-client";
import AboutController from "./AboutController";
import { MessageBox } from "@geolib/geolib-client";

@Injectable({ providedIn: "root" })
export class GeobusAboutExtension extends MoreNavigationDialogExtension {

    public constructor(
        private readonly messageBox: MessageBox,
    ) {
        super();
    }

    public isEnabled(): boolean {
        return true;
    }

    public get title(): string {
        return "MORE_NAVIGATION.ABOUT";
    }

    public callback(): void {
        this.messageBox.custom(AboutController, { title: "Über GEOBUS", showCloseButton: true });
    }

}
