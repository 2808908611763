<div class="gp-page-header">
    <div class="gp-container-normal">
        <breadcrumb></breadcrumb>
        <div class="gp-page-header-container">
            <h1 class="gp-page-header-title">{{ "CHECK_IN.TITLE" | translate }} {{ modelName }}</h1>
            <div class="gp-page-header-actions">
                <div class="btn-group">
                    <button class="btn btn-default btn-back" (click)="abort()">
                        {{ "NAVIGATION.BACK" | translate }}
                    </button>
                    <gp-button-primary (click)="save()" [isDisabled]="loading" translation="NAVIGATION.SAVE"></gp-button-primary>
                    @if (loading) {
                        <gp-spinner class="loading-spinner" [visible]="true"></gp-spinner>
                    }
                </div>
            </div>
        </div>
    </div>
</div>
<form class="gp-container-normal" (ngSubmit)="save()">
    <div class="row">
        <label class="col-md-4">
            <div class="col-form-label">{{ "CHECK_IN.FILE" | translate }}</div>
            <gp-file (onFileSelected)="onFileSelect($event)" accept=".zip"></gp-file>
        </label>
    </div>
    <div class="row">
        <label class="col-md-12">
            <div class="col-form-label">{{ "CHECK_IN.DESCRIPTION" | translate }}</div>
            <textarea class="form-control" rows="6" [(ngModel)]="checkinNote" name="checkinNote"></textarea>
        </label>
    </div>
</form>
