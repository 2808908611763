<div class="row">
    <div class="col-md-4 topicSelector">
        <div class="selectorHeader">
            <p>{{ "SUBSCRIPTION.TOPICS.AVAILABLE" | translate }}</p>
        </div>
        <ul>
            @for (topic of topics.availableTopics; track topic) {
                <li (click)="markTopics(topic, 'available')">
                    <p [ngClass]="{ selected: topic.selected }">{{ topic.name }}</p>
                </li>
            }
        </ul>
    </div>
    <div class="col-md-4 selectorButtonGroup">
        <dx-button icon="chevronleft" (click)="deselectTopics()" [disabled]="!hasMarkedTopics() || hasAvailableMarked()"> </dx-button>
        <dx-button icon="chevronright" (click)="selectTopics()" [disabled]="!hasMarkedTopics() || hasSelectedMarked()"> </dx-button>
    </div>
    <div class="col-md-4 topicSelector">
        <div class="selectorHeader">
            <p>{{ "SUBSCRIPTION.TOPICS.SELECTED" | translate }}</p>
        </div>
        <ul>
            @for (topic of topics.selectedTopics; track topic) {
                <li (click)="markTopics(topic, 'selected')" [ngClass]="{ selected: topic.selected }">
                    <p>{{ topic.name }}</p>
                </li>
            }
        </ul>
    </div>
</div>
