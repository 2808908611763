import { TrackingService } from "./TrackingService";
import { ModelService } from "../model/ModelService";
import { Component } from "@angular/core";
import { StateService } from "@geolib/geolib-client";
import { NotificationService } from "@geolib/geolib-client";


@Component({
    selector: "dataset-checkin",
    templateUrl: "./dataset-checkin.html",
})
export default class DatasetCheckinController {

    private datasetFile: File;
    public modelName: string;
    public checkinNote: string;
    public loading: boolean;

    public constructor(
        private readonly stateService: StateService,
        private readonly trackingService: TrackingService,
        private readonly modelService: ModelService,
        private readonly notificationService: NotificationService,
    ) {
        this.trackingService.findOne(this.trackingSpecificationId).subscribe((trackingSpecification) => {
            this.modelService.findOne(trackingSpecification.modelId!).subscribe((model) => {
                this.modelName = model.name;
            });
        });

        this.onFileSelect = this.onFileSelect.bind(this);
    }

    private get trackingSpecificationId(): string {
        return this.stateService.getParameter("trackingSpecificationId");
    }

    public save(): void {
        if (!this.isFileValid(this.datasetFile)) {
            this.notificationService.warn("Dateiformat ungültig");
            return;
        }

        this.loading = true;

        this.trackingService.checkin(this.trackingSpecificationId, this.stateService.getParameter("id"), this.datasetFile, this.checkinNote)
            .subscribe({
                next: () => {
                    this.abort();
                },
                error: () => {
                    this.notificationService.error("Checkin fehlgeschlagen");
                    this.loading = false;
                },
            });
    }

    public abort(): void {
        this.stateService.go("app.trackings.datasets", { trackingSpecificationId: this.trackingSpecificationId });
    }

    public onFileSelect(files: File[]): void {
        this.datasetFile = files[0];
    }

    private isFileValid(datasetFile: File): boolean {
        let fileType = "";
        if (datasetFile && datasetFile.name) {
            fileType = datasetFile.name.match(/\.([^.]+)$/)?.[1] ?? "";
        }
        return ["zip", "zipx"].includes(fileType);
    }

}
