import { ErrorHandler, Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { LoggingService } from "./LoggingService";

@Injectable({
    providedIn: "root",
})
export class GlobalErrorHandler implements ErrorHandler {
    public constructor(
        private readonly loggingService: LoggingService,
    ) {
    }

    public handleError(error: Error | HttpErrorResponse): void {
        let message: string;
        let stack: string;

        if (error instanceof HttpErrorResponse) {
            message = error.message;
            stack = error.statusText;
        } else {
            message = error.message ? error.message : error.toString();
            stack = error.stack ? error.stack : "";
        }

        this.loggingService.logError({ message, stack });

        throw error;
    }
}
