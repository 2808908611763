import { TrackingService } from "./TrackingService";
import IDatasetTrackingProtocol from "../tracking/IDatasetTrackingProtocol";
import { ChangeDetectorRef, Component, ElementRef, OnInit } from "@angular/core";
import DataSource from "devextreme/data/data_source";
import CustomStore from "devextreme/data/custom_store";
import { StateService } from "@geolib/geolib-client";
import { TranslateService } from "@ngx-translate/core";
import { IGridColumn } from "../../../common/IGridColumn";
import GridController from "../common/GridController";
import GridService from "../common/GridService";
import { UserSettingsService } from "@geolib/geoappbase-client";
import { NGXLogger } from "ngx-logger";
import { ContentReadyEvent } from "devextreme/ui/data_grid";
import { IdentityService } from "../account/IdentityService";
import { lastValueFrom } from "rxjs";


@Component({
    selector: "geobus-protocol",
    templateUrl: "./protocol.html",
})
export default class ProtocolController extends GridController implements OnInit {

    private protocol: IDatasetTrackingProtocol[];
    public dataSource: DataSource<IDatasetTrackingProtocol, string>;
    public dataGridColumns: IGridColumn[] = [];
    protected hasGridControls: boolean = false;

    public constructor(
        private readonly stateService: StateService,
        private readonly trackingService: TrackingService,
        private readonly translateService: TranslateService,
        elementRef: ElementRef<HTMLElement>,
        gridService: GridService,
        userSettingsService: UserSettingsService,
        logger: NGXLogger,
        changeDetectorRef: ChangeDetectorRef,
        identityService: IdentityService,
    ) {
        super(elementRef.nativeElement, gridService, userSettingsService, logger, changeDetectorRef, identityService);
    }

    protected get settingsKey(): string {
        return "geobus.protocol.grid";
    }

    public ngOnInit(): void {
        this.dataGridColumns = this.getColumns();
        this.dataSource = new DataSource<IDatasetTrackingProtocol, string>({
            store: new CustomStore({
                key: "tasknumber",
                load: async (): Promise<IDatasetTrackingProtocol[]> => {
                    const protocol = await lastValueFrom(this.trackingService.getTrackingProtocol(this.trackingSpecificationId));
                    this.protocol = protocol;
                    this.afterGridDataLoaded();
                    return this.protocol;
                },
            }),
        });
    }

    public onContentReady(event: ContentReadyEvent): void {
        if (!event.component.getDataSource()) {
            event.component.option("dataSource", this.dataSource);
        }
    }

    private get trackingSpecificationId(): string {
        return this.stateService.getParameter("trackingSpecificationId");
    }

    public back(): void {
        this.stateService.go("app.trackings.datasets", { trackingSpecificationId: this.trackingSpecificationId });
    }

    public getTime(rowData: IDatasetTrackingProtocol): string {
        return new Intl.DateTimeFormat("de-CH", { hour: "2-digit", minute: "2-digit" }).format(new Date(rowData.date));
    }

    private getColumns(): IGridColumn[] {
        return [
            {
                dataField: "tasknumber",
                dataType: "number",
                caption: this.translateService.instant("PROTOCOL.GRID.TASK_NR"),
                sortOrder: "desc",
            }, {
                dataField: "task",
                dataType: "string",
                caption: this.translateService.instant("PROTOCOL.GRID.TASK"),
            }, {
                dataField: "username",
                dataType: "string",
                caption: this.translateService.instant("PROTOCOL.GRID.USER"),
            }, {
                dataField: "date",
                dataType: "date",
                caption: this.translateService.instant("PROTOCOL.GRID.DATE"),
                format: "dd.MM.yyyy",
            }, {
                calculateCellValue: this.getTime,
                caption: this.translateService.instant("PROTOCOL.GRID.TIME"),
                dataType: "date",
                format: "HH:mm",
            }, {
                dataField: "result",
                dataType: "string",
                caption: this.translateService.instant("PROTOCOL.GRID.RESULT"),
            }, {
                dataField: "status",
                dataType: "string",
                caption: this.translateService.instant("PROTOCOL.GRID.STATUS"),
            }, {
                dataField: "note",
                dataType: "string",
                caption: this.translateService.instant("PROTOCOL.GRID.NOTE"),
            },
        ];
    }

}
