import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { isTruthy } from "@geolib/geolib-types/src/common/PredicateChecks";
import { ButtonType, SelectionChangedEvent as ButtonSelectionChangedEvent } from "devextreme/ui/button_group";
import { FtpServerService } from "./FtpServerService";
import { DirtyNavigationWarningFactory, IDirtyNavigationWarning, NotificationService, StateService } from "@geolib/geolib-client";
import DataSource from "devextreme/data/data_source";
import IFtpServer from "./IFtpServer";
import { NgForm } from "@angular/forms";
import { UIRouterGlobals } from "@uirouter/angular";
import { TranslateService } from "@ngx-translate/core";
import { isNumber } from "../common/IsNumber";


@Component({
    selector: "ftp-server-edit",
    templateUrl: "ftp-server-edit.component.html",
})

export default class FtpServerEditComponent implements OnInit, OnDestroy {

    @ViewChild(NgForm) public readonly ftpEditForm: NgForm;

    public dataSource?: DataSource<IFtpServer, number>;
    public ftpServer: IFtpServer | null;
    public ftpSshButtons: Array<{ type?: ButtonType; text?: string; icon?: string; key: string }>;
    public ftpType: Array<{ key: string; value: string; disabled?: boolean }> = [];
    public errorMessage: string = "";
    private readonly dirtyNavigationWarning: IDirtyNavigationWarning;

    public constructor(
        private readonly ftpServerService: FtpServerService,
        private readonly notificationService: NotificationService,
        private readonly stateService: StateService,
        private readonly uiRouterGlobals: UIRouterGlobals,
        private readonly translateService: TranslateService,
        dirtyNavigationWarningFactory: DirtyNavigationWarningFactory,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {
        this.ftpSshButtons = [
            { type: "danger", text: "FTP", key: "false" },
            { type: "danger", text: "SFTP", key: "true" },
        ];
        this.ftpType = this.ftpServerService.serverTypeDefinition;
        this.dirtyNavigationWarning = dirtyNavigationWarningFactory.create("ftpEditForm", this.uiRouterGlobals.current.name, this);
    }

    public ngOnInit(): void {
        const id = this.stateService.getParameter("id");
        this.ftpServerService.findOne(id ? id : "create").subscribe((ftpServer) => {
            this.ftpServer = ftpServer;
            this.changeDetectorRef.detectChanges();
        }, () => {
            this.errorMessage = "FTP_SERVER.MESSAGE.LOADING_FAILED";
        });
    }

    public getSelectedSshKeys(): string[] {
        return this.ftpServer ? [this.ftpServer.ssh?.toString()].filter(isTruthy) : [];
    }

    public sshChanged(event: ButtonSelectionChangedEvent): void {
        if (this.ftpServer) {
            this.ftpServer.ssh = event.addedItems[0].key === "true";
        }
    }

    public save(): void {
        if (!this.ftpServer) {
            return;
        }

        if (isNumber(this.ftpServer.id)) {
            delete this.ftpServer.id;
        }

        this.ftpServerService.save(this.ftpServer).subscribe({
            next: () => {
                this.ftpServer = null;
                this.notificationService.notify(this.translateService.instant("FTP_SERVER_EDIT.MESSAGE.SAVE_SUCCESS"));
                this.dirtyNavigationWarning.disable();
                this.abort();
            },
            error: () => {
                this.notificationService.error(this.translateService.instant("FTP_SERVER_EDIT.MESSAGE.SAVE_FAILURE"));
            },
        });
    }

    public abort(): void {
        this.stateService.go("app.ftpServer.list");
    }

    public ngOnDestroy(): void {
        this.dirtyNavigationWarning.destroy();
    }

}
