import { Injectable } from "@angular/core";
import IUser from "../user/IUser";
import { RoleName } from "../user/IRole";
import { Subject } from "rxjs";
import { first, shareReplay } from "rxjs/operators";


@Injectable({
    providedIn: "root",
})
export class IdentityService {

    private _isReady = new Subject<void>();
    public isReady = this._isReady.pipe(shareReplay(1));

    private _currentUser: IUser | undefined;
    private readonly onlogout: Array<() => unknown>;

    public constructor() {
        this.onlogout = [];
        this.isReady.pipe(first()).subscribe(() => undefined); // dummy subscription to make shareReplay work
    }

    public get currentUser(): IUser | undefined {
        return this._currentUser;
    }

    public set currentUser(user: IUser | undefined) {
        this._currentUser = user;
        this._isReady.next();
    }

    public isAuthenticated(): boolean {
        return !!this.currentUser;
    }

    public isAuthorized(...roles: RoleName[]): boolean {
        const currentUserRoles = this.currentUser ? this.currentUser.roles?.map((role) => role.name) ?? [] : [];
        const allRoles = [roles, currentUserRoles];
        return this.isAuthenticated() && allRoles.reduce((a, b) => a.filter((c) => b.includes(c))).length > 0;
    }

    public onLogout(listener?: () => unknown): void {
        if (listener) {
            this.onlogout.push(listener);
        } else {
            this.onlogout.forEach(this.callListener);
        }
    }

    private callListener(listener: () => unknown): void {
        listener();
    }
}
