import { UserService } from "./UserService";
import { PasswordPolicy } from "../common/PasswordPolicy";
import { Component, ViewChild } from "@angular/core";
import IUser from "./IUser";
import { FormControl } from "@angular/forms";
import { NotificationService } from "@geolib/geolib-client";


@Component({
    selector: "user-profile",
    templateUrl: "./user-profile.html",
})
export default class UserProfileController {

    @ViewChild("prmNewPassword") public readonly prmNewPassword: FormControl;
    @ViewChild("prmEmail") public readonly prmEmail: FormControl;

    public user: IUser;
    public errorPassword: undefined | null | string;
    public validPassword: undefined | boolean;
    public password: string;
    public salutationOptions: Array<{ key: string; value: string }> = [];

    public constructor(
        private readonly userService: UserService,
        private readonly passwordPolicy: PasswordPolicy,
        private readonly notificationService: NotificationService,
    ) {
        this.errorPassword = null;
        this.validPassword = true;
        this.password = "";
        this.salutationOptions = this.userService.salutationOptions;

        this.user = this.userService.createOne();
        this.userService.findCurrentUser().subscribe((user) => {
            this.user = user;
        });
    }

    public validPwdCheck(): void {
        if (!this.prmNewPassword.dirty || !this.user.newPassword) {
            this.errorPassword = "";
            this.validPassword = true;
            return;
        }

        const password = this.passwordPolicy.validate(this.user.newPassword);
        this.errorPassword = password.message;
        this.validPassword = password.valid;
    }

    public save(): void {
        this.userService.checkIfUniqueUsername(this.user).subscribe((uniqueCheck) => {
            if (!uniqueCheck.usernameIsUnique) {
                this.notificationService.error("SYSTEM.DEFAULT_SAVE_ERROR");
                return;
            }

            this.userService.save(this.user).subscribe(() => {
                this.notificationService.notify("SYSTEM.DEFAULT_SAVE_SUCCESS");
                this.abort();
            }, () => {
                this.notificationService.error("SYSTEM.DEFAULT_SAVE_ERROR");
            });
        });
    }

    public abort(): void {
        window.history.back();
    }

    public isNewPasswordValid(): boolean {
        return Boolean(!this.user.newPassword || (this.user.newPassword && this.validPassword));
    }

}
