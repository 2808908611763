import { NotificationService } from "@geolib/geolib-client";
import { EventSubscriptionService } from "./EventSubscriptionService";
import { RoleName } from "./IRole";
import { IdentityService } from "../account/IdentityService";
import { Component } from "@angular/core";
import IEventSubscription from "../tracking/IEventSubscription";


@Component({
    selector: "event-subscription",
    templateUrl: "./eventsubscription-edit.html",
})
export default class EventSubscriptionController {

    public subscriptEvents: IEvent[];
    private readonly subscriptEventsTemplate: IEvent[];

    public constructor(
        private readonly eventSubscriptionService: EventSubscriptionService,
        private readonly notificationService: NotificationService,
        private readonly identityService: IdentityService,
    ) {

        this.subscriptEvents = [];
        this.subscriptEventsTemplate = [
            { value: "geobus.checkedout", label: "Datensatz ausgecheckt", checked: false },
            { value: "geobus.dataset.approved", label: "Datensatz fachlich überprüft", checked: false },
            { value: "geobus.checkinoverdue", label: "Datensatz überfällig", checked: false },
            { value: "geobus.checkindue", label: "Datensatz fällig", checked: false },
            { value: "geobus.technicalcheck", label: "Datensatz technische Prüfung", checked: false },
            { value: "geobus.extendedtechnicalcheck", label: "Datensatz erweiterte technische Prüfung", checked: false },
            { value: "geobus.dataset.available", label: "Datensatz für die Nachführung verfügbar", checked: false },
            { value: "geobus.integration.integrated", label: "Datensatz integriert", checked: false },
            { value: "geobus.integration.updated", label: "Datensatz visualisierbar", checked: false },
            { value: "geobus.geoportal.ftp", label: "Geoportal FTP-Upload", checked: false },
            { value: "geobus.geoportal.publication", label: "Geoportal publizieren", checked: false },
            { value: "geobus.oereb.share", label: "Freigabe ÖREB-Publikation", checked: false },
            { value: "geobus.oereb.publication", label: "Publikation ÖREB-Portal", checked: false },
            { value: "geobus.ftp.kkgeo", label: "FTP-Upload KKGEO", checked: false, isFs: true },
            { value: "geobus.ftp.gis", label: "FTP-Upload GIS-Fachstelle", checked: false, isFs: true },
        ];

        this.fetchData();
    }

    private fetchData(): void {
        this.eventSubscriptionService.findAll().subscribe((subscriptions) => {
            subscriptions.forEach((subscription) => {
                const event = this.subscriptEventsTemplate.find((event) => event.value === subscription.event);
                if (event) {
                    event.checked = true;
                }
            });
            this.subscriptEvents = this.subscriptEventsTemplate;
        });
    }

    private setEvent(subscription: IEventSubscription, value: boolean): void {
        const event = this.subscriptEvents.find((event) => event.value === subscription.event);

        if (event) {
            event.checked = value;
        }
    }

    public eventChanged(event: IEvent): void {
        if (event.checked) {
            this.subscribe(event.value);
        } else {
            this.unsubscribe(event.value);
        }
    }

    private subscribe(event: string): void {
        const subscription = this.eventSubscriptionService.createEventSubscription(event);
        this.eventSubscriptionService.subscribeEvent(subscription).subscribe({
            next: () => {
                this.notificationService.notify("Benachrichtigung wurde hinzugefügt");
            },
            error: () => {
                this.notificationService.error("Benachrichtigung konnte nicht hinzugefügt werden");
                this.setEvent(subscription, false);
            },
        });
    }

    private unsubscribe(event: string): void {
        const subscription = this.eventSubscriptionService.createEventSubscription(event);
        this.eventSubscriptionService.unsubscribeEvent(subscription).subscribe({
            next: () => {
                this.notificationService.notify("Benachrichtigung wurde entfernt");
            },
            error: () => {
                this.notificationService.error("Benachrichtigung konnte nicht entfernt werden");
                this.setEvent(subscription, true);
            },
        });
    }

    public back(): void {
        window.history.back();
    }

    public hasUserFsPrivilege(event: IEvent): boolean {
        if (event.isFs) {
            return this.identityService.isAuthorized(RoleName.FS);
        } else {
            return true;
        }
    }

}

export interface IEvent {
    value: string;
    label: string;
    checked: boolean;
    isFs?: boolean;
}
