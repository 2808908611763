import { BackendService } from "@geolib/geolib-client";
import ITopic from "./ITopic";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";


@Injectable({ providedIn: "root" })
export class TopicService extends BackendService<ITopic, string> {

    public constructor(
        http: HttpClient,
    ) {
        super("/api/topic", http);
    }

    public findAllNotifications(): Observable<{ availableTopics: ITopic[]; selectedTopics: ITopic[] }> {
        return this.http.get<{ availableTopics: ITopic[]; selectedTopics: ITopic[] }>("/api/users/topics");
    }

    public subscribeNotifications(topic: ITopic): Observable<void> {
        return this.http.post<void>(`/api/users/topics/${topic.id}`, topic);
    }

    public unsubscribeNotifications(topicId: string): Observable<void> {
        return this.http.delete<void>(`/api/users/topics/${topicId}`);
    }

    public createOne(): ITopic {
        return {
            id: Math.random().toString(),
        };
    }

}
