import { StateObject, TransitionService } from "@uirouter/angular";
import { Injectable } from "@angular/core";
import { StateService } from "@geolib/geolib-client";
import { PrimaryAreaService } from "./primaryArea/primary-area.service";
import { AuthenticationService } from "./account/AuthenticationService";
import { IdentityService } from "./account/IdentityService";
import { lastValueFrom, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import IUser from "./user/IUser";


@Injectable({ providedIn: "root" })
export default class AppInitializer {

    public constructor(
        private readonly transitionService: TransitionService,
        private readonly authenticationService: AuthenticationService,
        private readonly stateService: StateService,
        private readonly primaryAreaService: PrimaryAreaService,
        private readonly identityService: IdentityService,
    ) {
        this.transitionService.onBefore({ to: (state: StateObject) => !state.data?.public }, (transition) => {
            return lastValueFrom(this.getUser()).then((user) => {
                if (user) {
                    const params = transition.params();

                    return this.primaryAreaService.getCanton().subscribe(({ canton }) => {
                        if (!params?.primaryArea || params.primaryArea !== canton) {
                            transition.abort();

                            const toState = transition.to();

                            if (toState.name) {
                                return this.stateService.go(toState.name, { ...params, primaryArea: canton });
                            } else {
                                return this.stateService.go("login");
                            }
                        }
                    });
                } else {
                    transition.abort();
                    return this.stateService.go("login");
                }
            }).catch(() => this.stateService.go("login")).then(() => undefined);
        });
    }

    private getUser(): Observable<IUser> {
        if (this.identityService.currentUser) {
            return of(this.identityService.currentUser);
        } else {
            return this.authenticationService.getUser().pipe(
                map((response) => {
                    return response.user;
                }),
            );
        }
    }

}
