<div class="gp-page-header">
    <div class="gp-container-normal">
        <breadcrumb></breadcrumb>
        <div class="gp-page-header-container">
            <h1 class="gp-page-header-title">{{ "USER_EDIT.TITLE_CURRENT" | translate }}</h1>
            <div class="gp-page-header-actions">
                <div class="btn-group">
                    <button class="btn btn-default btn-back" (click)="abort()">
                        {{ "NAVIGATION.ABORT" | translate }}
                    </button>
                    <gp-button-primary
                        (click)="save()"
                        [isDisabled]="!userProfileForm.valid || !isNewPasswordValid()"
                        translation="NAVIGATION.SAVE"
                    ></gp-button-primary>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="gp-container-normal">
    <form class="panel panel-default" #userProfileForm="ngForm" (ngSubmit)="save()">
        <div class="row">
            <div class="col-md-6">
                <div class="col-form-label">{{ "USER_EDIT.LABEL.SALUTATION" | translate }}</div>
                <dx-select-box
                    [dataSource]="salutationOptions"
                    [(ngModel)]="user.salutation"
                    displayExpr="value"
                    valueExpr="key"
                    name="salutation"
                    autofocus="true"
                    [required]="true"
                    [searchEnabled]="true"
                ></dx-select-box>
            </div>
            <label class="col-md-6" [class.has-error]="errorPassword">
                <div class="col-form-label">Neues Passwort</div>
                <input
                    class="form-control"
                    [(ngModel)]="user.newPassword"
                    name="newPassword"
                    type="password"
                    (keyup)="validPwdCheck()"
                    #prmNewPassword="ngModel"
                />
                @if (errorPassword) {
                    <ul class="gp-form-control-error">
                        @if (errorPassword) {
                            <li>{{ errorPassword }}</li>
                        }
                    </ul>
                }
            </label>
        </div>
        <div class="row">
            <label class="col-md-6">
                <div class="col-form-label">{{ "USER_EDIT.LABEL.FIRSTNAME" | translate }}</div>
                <input class="form-control" [required]="true" [(ngModel)]="user.firstname" name="firstname" />
            </label>
        </div>

        <div class="row">
            <label class="col-md-6">
                <div class="col-form-label">{{ "USER_EDIT.LABEL.LASTNAME" | translate }}</div>
                <input class="form-control" [required]="true" [(ngModel)]="user.lastname" name="lastname" />
            </label>
        </div>

        <div class="row">
            <label class="col-md-6" [class.has-error]="prmEmail.invalid">
                <div class="col-form-label">{{ "USER_EDIT.LABEL.EMAIL" | translate }}</div>
                <input
                    class="form-control"
                    #prmEmail="ngModel"
                    type="email"
                    [required]="true"
                    [(ngModel)]="user.email"
                    name="email"
                    autocomplete="email"
                    pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                />
                @if (prmEmail.invalid) {
                    <ul class="gp-form-control-error">
                        <li>{{ "VALIDATION.EMAIL_NOTVALID" | translate }}</li>
                    </ul>
                }
            </label>
        </div>
    </form>
</div>
