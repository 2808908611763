import { UserService } from "../user/UserService";
import { Component, OnInit, ViewChild } from "@angular/core";
import { StateService } from "@geolib/geolib-client";
import { NotificationService } from "@geolib/geolib-client";
import { PasswordPolicy } from "../common/PasswordPolicy";
import IUser from "../user/IUser";
import { finalize } from "rxjs/operators";
import { NgForm } from "@angular/forms";


@Component({
    selector: "password-reset",
    templateUrl: "./password-reset.html",
})
export default class PasswordResetController implements OnInit {

    @ViewChild(NgForm) public readonly passwordResetForm: NgForm;

    private token: string;
    public isTokenValid: boolean = false;
    public user: null | IUser = null;
    public password?: string = undefined;
    public passwordcopy?: string = undefined;
    public errorPassword?: string = undefined;
    public validPassword: boolean = false;
    public equalPassword: boolean = true;
    public loading: boolean = false;

    public constructor(
        private readonly stateService: StateService,
        private readonly notificationService: NotificationService,
        private readonly passwordPolicy: PasswordPolicy,
        private readonly userService: UserService,
    ) {
    }

    public ngOnInit(): void {
        this.token = this.stateService.getParameter("token");

        if (this.token && this.token.length > 0) {
            this.loading = true;
            this.userService.getUserByPasswordRecoveryToken(this.token).pipe(
                finalize(() => {
                    this.loading = false;
                })).subscribe({
                next: (user: IUser) => {
                    this.isTokenValid = true;
                    this.user = user;
                },
                error: () => {
                    this.isTokenValid = false;
                },
            });
        }
    }

    public validPwdCheck(): void {
        if (this.password) {
            const password = this.passwordPolicy.validate(this.password);
            this.errorPassword = password.message;
            this.validPassword = password.valid;
        }
        this.equalPassword = !(this.password !== this.passwordcopy && this.passwordcopy);
    }

    public checkPassword(): boolean {
        return (this.password !== this.passwordcopy) || !this.validPassword;
    }

    public resetPassword(): void {
        if (!this.password) {
            this.notificationService.error("Ihr Passwort konnte nicht gesetzt werden.");
            return;
        }

        this.userService.setPasswordWithRecoveryToken(this.token, this.password).subscribe({
            next: () => {
                this.notificationService.notify("Password erfolgreich gesetzt.");
                this.abort();
            },
            error: () => {
                this.notificationService.error("Ihr Passwort konnte nicht gesetzt werden.");
            },
        });
    }

    public abort(): void {
        this.stateService.go("login");
    }

}
