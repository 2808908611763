import { Component } from "@angular/core";
import { MessageBox } from "@geolib/geolib-client";


@Component({
    selector: "about",
    templateUrl: "./about.html",
})
export default class AboutController {

    public constructor(
        private readonly messageBox: MessageBox,
    ) {

    }

    public close(): void {
        this.messageBox.hideAll();
    }
}
