import IDataset from "../tracking/IDataset";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";


@Injectable({ providedIn: "root" })
export class PublicationService {

    public constructor(
        private readonly httpClient: HttpClient,
    ) {
    }

    public publishOereb(datasettrackingId: number): Observable<IDataset> {
        return this.httpClient.post<IDataset>("/api/oereb/publication", { datasettrackingId });
    }

}
