<form name="formApproveReject">
    <div class="row">
        <div class="col-md-12">{{ "DATASET_DIALOG.CHOOSE" | translate }}</div>
    </div>
    <div class="row">
        <div class="col-md-12">&nbsp;</div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <label>
                <input type="radio" [(ngModel)]="techApproveAction" value="techApprove" name="techApproveAction" />
                {{ "DATASET_DIALOG.LABEL.OKAY" | translate }}
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <label>
                <input type="radio" [(ngModel)]="techApproveAction" value="techReject" name="techApproveAction" />
                {{ "DATASET_DIALOG.LABEL.NOT_OKAY" | translate }}
            </label>
        </div>
    </div>
    <div class="row">
        @if (techApproveAction === "techApprove") {
            <div class="col-md-12 gp-margin-bottom-medium">
                {{ "DATASET_DIALOG.TECH.OKAY.TEXT" | translate }}
            </div>
        }
        @if (techApproveAction === "techReject") {
            <div class="col-md-12 gp-margin-bottom-medium">
                {{ "DATASET_DIALOG.TECH.NOT_OKAY.TEXT" | translate }}
            </div>
        }
    </div>
    <div class="row">
        <div class="col-md-12">
            <textarea class="rejectcomment form-control" [(ngModel)]="dataset.manualApproveNote" name="manualApproveNote"></textarea><br />
            @if (techApproveAction === "techApprove" && !dataset.manualApproveNote) {
                <div class="alert alert-danger">{{ "DATASET_DIALOG.TECH.NOT_OKAY.COMMENT" | translate }}</div>
            }
        </div>
    </div>
    <div class="form-submit dialog-form-submit text-right">
        <div class="btn-group">
            <div class="btn-group">
                <button class="btn btn-default" type="button" (click)="cancel()">{{ "NAVIGATION.ABORT" | translate }}</button>
            </div>
            @if (techApproveAction === "techApprove") {
                <div class="btn-group">
                    <button class="btn btn-primary" type="button" (click)="approve()" [disabled]="!dataset.manualApproveNote">
                        {{ "DATASET_DIALOG.BUTTON.OKAY" | translate }}
                    </button>
                </div>
            }
            @if (techApproveAction === "techReject") {
                <div class="btn-group">
                    <button class="btn btn-primary" type="button" (click)="reject()">{{ "DATASET_DIALOG.BUTTON.NOT_OKAY" | translate }}</button>
                </div>
            }
        </div>
    </div>
</form>
