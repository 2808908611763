import { TrackingService } from "./TrackingService";
import { UserService } from "../user/UserService";
import { GemeindeService } from "../gemeinde/GemeindeService";
import { ModelService } from "../model/ModelService";
import { GeobusAppService } from "./GeobusAppService";
import { IdentityService } from "../account/IdentityService";
import StatusMapping from "../common/StatusMapping";
import { RoleName } from "../user/IRole";
import IDataset from "./IDataset";
import ITrackingSpecification from "./ITrackingSpecification";
import IModelDto from "../model/IModelDto";
import IGemeinde from "../gemeinde/IGemeinde";
import IUser from "../user/IUser";
import { GpMessageBoxComponent, MessageBox, NotificationService, StateService } from "@geolib/geolib-client";
import { PublicationService } from "../publication/PublicationService";
import { finalize } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import TechRejectDialog from "./dialogs/techRejectDialog/TechRejectDialog";
import ExtendedTechRejectDialog from "./dialogs/extendedTechRejectDialog/ExtendedTechRejectDialog";
import ProRejectDialog from "./dialogs/proRejectDialog/ProRejectDialog";
import { saveAs } from "file-saver";
import moment from "moment";
import { b64DecodeUnicode } from "../common/Base64";
import { Observable } from "rxjs";


moment.locale("de-CH");

@Component({
    selector: "dataset-edit",
    templateUrl: "./dataset-edit.html",
    styleUrls: ["./dataset-edit.component.scss"],
})
export default class DatasetEditController implements OnInit {

    public dataset: IDataset;
    public odataset: IDataset;
    private trackingSpecification: ITrackingSpecification;
    private model: IModelDto;
    private gemeinde: IGemeinde;
    private approveAction: "approve" | "reject";
    public feedbackRequired: boolean;
    private datasetFile: File;
    public extendedTechnicalCheckStatus: string;
    public extendedUserName?: string;
    public extStatus: string;
    public modelName: string;
    public extendedTechnicalCheckDate: string;
    public extendedTechnicalCheckNote: string;
    public nameCheckProtocol: string;
    public techcheckNote: string;
    public expertcheckNote: string;
    public approvedDate: string;
    public approver?: IUser;
    public expertcheck: string;
    public ocheckoutUserName?: string;
    public ospecificationId: string;
    public ocheckoutDate: string;
    public ocheckinDate: string;
    public ocheckinNote: string;
    public ocheckinFileName: string;
    public otechnicalcheck: string;
    public ocheckinUserName?: string;
    public omanualApproveDate: string;
    public omanualApproveUser: IUser | null;
    public omanualApproveNote: string;
    public geoportalFtpUploadDate: string;
    public geoportalPublishDate: string;
    public oerebShareDate: string;
    public oerebPublishDate: string;
    public geoportalFtpUploadSucceeded: string;
    public geoportalPublishSucceeded: boolean;
    public publicateOerebIsLoading: boolean = false;

    // eslint-disable-next-line max-params
    public constructor(
        private readonly stateService: StateService,
        private readonly userService: UserService,
        private readonly trackingService: TrackingService,
        private readonly identityService: IdentityService,
        private readonly notificationService: NotificationService,
        private readonly statusMapping: StatusMapping,
        private readonly geobusAppService: GeobusAppService,
        private readonly modelService: ModelService,
        private readonly gemeindeService: GemeindeService,
        private readonly httpClient: HttpClient,
        private readonly messageBox: MessageBox,
        private readonly publicationService: PublicationService,
    ) {
        this.onFileSelect = this.onFileSelect.bind(this);
    }

    public ngOnInit(): void {
        this.findDataset().subscribe((dataset) => {
            this.initDatasetUi(dataset);
        });
    }

    private findDataset(): Observable<IDataset> {
        return this.trackingService.findDataset(this.trackingSpecificationId, +this.stateService.getParameter("id"), {
            exclude: [
                "checkoutUser.eventSubscriptions*",
                "checkinUser.eventSubscriptions*",
            ].join(","),
        });
    }

    // eslint-disable-next-line max-statements
    private initDatasetUi(dataset: IDataset): void {
        this.odataset = dataset;
        this.getTracking(dataset);
        this.getExtendTechnicalUser(dataset);
        this.getExtendTechnicalStatus(dataset);
        this.ospecificationId = dataset.specificationId;
        this.ocheckoutDate = this.formatDateTime(dataset.checkoutDate);
        this.ocheckinDate = this.formatDateTime(dataset.checkinDate);
        this.modelName = dataset.modelName;
        if (dataset.checkoutUserId) {
            this.userService.findOne(dataset.checkoutUserId).subscribe((user) => {
                this.ocheckoutUserName = user.username;
                if (dataset.approved === null && user.organisationId !== this.identityService.currentUser?.organisationId) {
                    setTimeout(() => {
                        this.notificationService.notify("Die Daten befinden sich bei der Nachführungsstelle.");
                    }, 500);
                }
            });
        } else {
            this.ocheckoutUserName = "";
        }

        if (dataset.extendedTechnicalCheckStatus) {
            this.statusMapping.extendedStatusName(dataset.status, dataset.fileId, dataset.hasbeenrejected, dataset.approved,
                dataset.extendedTechnicalCheckStatus, dataset);
        } else {
            this.extStatus = this.statusMapping.statusName(dataset);
        }

        if (dataset.checkinUserId) {
            this.userService.findOne(dataset.checkinUserId).subscribe((user) => this.ocheckinUserName = user.username);
        } else {
            this.ocheckinUserName = "";
        }

        this.otechnicalcheck = this.statusMapping.technicalStatusName(dataset.status);
        this.ocheckinFileName = dataset.file ? dataset.file.name : "";
        this.ocheckinNote = dataset.checkinNote;
        this.addManualAttributesToScope(dataset);
        this.addExtendedTechnicalTestAttributesToScope(dataset);
        this.addPublicationAttributesToScope(dataset);
        this.updateGUI(dataset);
    }

    public updateFeedback(): void {
        this.feedbackRequired = this.approveAction === "reject" && !this.dataset.approvedNote;
    }

    private get trackingSpecificationId(): string {
        return this.stateService.getParameter("trackingSpecificationId");
    }

    public proApprove(): void {
        this.dataset.approved = true;
        this.trackingService.approveDataset(this.dataset).subscribe(() => {
            this.notificationService.notify("Der Datensatz wurde fachlich angenommen.");
            this.findDataset().subscribe((dataset) => {
                this.dataset = dataset;
                setTimeout(() => this.updateGUI(dataset));
            });
        });
    }

    public techApprove(): void {
        this.dataset.status = "invalidAndAccepted";
        this.trackingService.technicallyApproveDataset(this.dataset).subscribe(() => {
            this.notificationService.notify("Der Datensatz wurde manuell technisch angenommen.");
            this.findDataset().subscribe((dataset) => {
                this.dataset = dataset;
                setTimeout(() => this.updateGUI(dataset));
            });
        });
    }

    public extendedTechApprove(): void {
        if (!this.isFileValid(this.datasetFile)) {
            this.notificationService.error("Dateiformat ungültig oder Keine Datei vorhanden");
            return;
        }

        this.dataset.extendedTechnicalCheckStatus = "valid";
        this.trackingService.extendedTechnicallyApproveDataset(this.dataset, this.datasetFile).pipe(
            finalize(() => {
                this.findDataset().subscribe((dataset) => {
                    setTimeout(() => this.updateGUI(dataset));
                });
            })).subscribe({
            next: () => {
                this.notificationService.notify("Der Datensatz wurde in der erweiterten technischen Prüfung angenommen.");
            },
            error: () => {
                this.notificationService.warn("Der Datensatz konnte nicht erweiterten technischen Prüfung angenommen werden.");
            },
        });
    }

    public showTestProtocol(): void {
        if (this.dataset.extendedTestProtocolFileId) {
            const urlDownload = `/api/tracking/testprotocol/${this.dataset.extendedTestProtocolFileId}`;
            return window.location.assign(urlDownload);
        }
    }

    public proReject(): void {
        this.trackingService.rejectDataset(this.dataset).subscribe(() => {
            this.notificationService.error("Der Datensatz wurde fachlich abgelehnt.");
            this.findDataset().subscribe((dataset) => {
                this.dataset = dataset;
                setTimeout(() => this.updateGUI(dataset));
            });
        });
    }

    public techReject(): void {
        this.dataset.status = "invalidAndRejected";
        this.trackingService.rejectDataset(this.dataset).subscribe(() => {
            this.notificationService.error("Der Datensatz wurde manuell technisch abgelehnt.");
            this.findDataset().subscribe((dataset) => {
                this.dataset = dataset;
                setTimeout(() => this.updateGUI(dataset));
            });
        });
    }

    public extendedTechReject(): void {
        if (!this.isFileValid(this.datasetFile)) {
            this.notificationService.warn("Dateiformat ungültig oder Keine Datei vorhanden");
            return;
        }

        this.dataset.extendedTechnicalCheckStatus = "invalidAndRejected";
        this.trackingService.extendedTechnicallyRejectDataset(this.dataset, this.datasetFile).pipe(
            finalize(() => {
                this.findDataset().subscribe((dataset) => {
                    setTimeout(() => this.updateGUI(dataset));
                });
            })).subscribe({
            next: () => {
                this.notificationService.error("Der Datensatz wurde in der erweiterten technischen Prüfung abgelehnt.");
            },
            error: () => {
                this.notificationService.warn("Der Datensatz konnte nicht erweiterten technischen Prüfung abgelehnt werden.");
            },
        });
    }

    public abort(): void {
        if (this.ospecificationId) {
            this.stateService.go("app.trackings.datasets", { trackingSpecificationId: this.ospecificationId });

        } else {
            this.stateService.go("app.trackings");
        }

    }

    public hasManualTech(): boolean {
        return !!(this.dataset && this.dataset.manualApproveDate);
    }

    public hasExtendendTechnicalCheck(): boolean {
        return this.model && this.model.hasExtendedTechnicalCheck;
    }

    // eslint-disable-next-line complexity
    public hasVisualization(): boolean {
        if (this.trackingSpecification && this.model) {
            const model = this.model;
            const gemeinde = this.gemeinde;
            return !!(model?.hasVisualization && model?.mapId && gemeinde?.bfsnr && gemeinde?.primaryArea);
        } else {
            return false;
        }
    }

    public isVisualizationable(): boolean {
        if (this.dataset && this.model) {
            return Boolean(this.dataset.importStatus === "updated" && this.model.hasVisualization);
        } else {
            return false;
        }
    }

    // eslint-disable-next-line complexity
    public getTotalCount(): string {
        const dataset = this.dataset;

        if (!dataset) {
            return "0 Objekte";
        }

        return `${(dataset.countNew ?? 0) + (dataset.countEditedGeom ?? 0) + (dataset.countEditedData ?? 0) +
        (dataset.countRemoved ?? 0)} Objekt/e`;
    }

    public downloadIntegrationProtocol(): void {
        const datasetTrackingId = this.dataset.id;
        this.httpClient.get(`/api/datasetTrackingProtocol/${datasetTrackingId}/export`, {
            responseType: "blob",
            observe: "response",
        }).subscribe((response) => {
            const BOM = "\uFEFF";
            const bomBlob = new Blob([BOM], { type: "text/plain" });
            const file = new Blob([bomBlob, response.body!], { type: "text/csv;charset=UTF-8" });
            const filename = b64DecodeUnicode(response.headers.get("Content-disposition")!);
            saveAs(file, filename);
        });
    }

    public showDatasetInGeobusApp(): void {
        const mapId = this.model.mapId?.toString();
        const datasetTrackingId = this.dataset.id ? this.dataset.id.toString() : undefined;
        const modelName = this.dataset.modelName;
        const bfsnr = this.gemeinde.bfsnr;
        const importTime = this.dataset.importTime.toString();
        const params = { bfsnr, mapId, datasetTrackingId, importTime, modelName };
        this.geobusAppService.getToken(params).subscribe({
            next: (token) => {
                const redirectUrl = `${token.geobusAppUrl}/${this.gemeinde.primaryArea}/map/${mapId}?token=${token.token}`;
                window.open(redirectUrl);
            },
            error: () => {
                this.notificationService.error("Beim Anzeigen der Daten in der GEOBUS Applikation trat ein Fehler auf.");
            },
        });
    }

    private onFileSelect(files: File[]): void {
        this.datasetFile = files[0];
    }

    private updateGUI(dataset: IDataset): void {
        this.trackingService.findOne(dataset.specificationId).subscribe((trackingSpecification) => {
            this.trackingSpecification = trackingSpecification;
            this.modelService.findOne(trackingSpecification.modelId!).subscribe((model) => {
                this.model = model;
                this.otechnicalcheck = this.statusMapping.technicalStatusName(dataset.status);
                this.dataset = dataset;
                this.approvedDate = this.formatDateTime(dataset.approvedDate);
                if (dataset.approvedUserId) {
                    this.userService.findOne(dataset.approvedUserId).subscribe((user) => {
                        this.approver = user;
                    });
                } else {
                    this.approver = undefined;
                }
                this.expertcheck = this.statusMapping.expertCheckStatusName(dataset.hasbeenrejected, dataset.approved,
                    dataset.extendedTechnicalCheckStatus);
                this.expertcheckNote = dataset.approvedNote;
                this.techcheckNote = dataset.techApprovedNote;
                this.addManualAttributesToScope(dataset);
                this.addExtendedTechnicalTestAttributesToScope(dataset);
                this.getExtendTechnicalUser(dataset);
                this.getExtendTechnicalStatus(dataset);
                this.addPublicationAttributesToScope(dataset);
                if (this.model.hasExtendedTechnicalCheck) {
                    this.extStatus = this.statusMapping.extendedStatusName(dataset.status, dataset.fileId, dataset.hasbeenrejected,
                        dataset.approved, dataset.extendedTechnicalCheckStatus, dataset);
                } else {
                    this.extStatus = this.statusMapping.statusName(dataset);
                }
                this.initializeVisualizationParameters();
            });
        });
    }

    // eslint-disable-next-line complexity
    private initializeVisualizationParameters(): void {
        if (this.model.hasVisualization) {
            this.dataset.countNew ??= 0;
            this.dataset.countEditedGeom ??= 0;
            this.dataset.countEditedData ??= 0;
            this.dataset.countRemoved ??= 0;
        }
    }

    private isFileValid(datasetFile: File): boolean {
        let fileType = "";

        if (datasetFile && datasetFile.name) {
            fileType = datasetFile.name?.match(/\.([^.]+)$/)?.[1] ?? "";
        }

        return ["pdf"].includes(fileType);
    }

    // eslint-disable-next-line complexity
    public isTechExtendedApproveSelectedItemLocked(): boolean {
        const dataset = this.dataset;
        return Boolean((dataset && dataset.hasbeenrejected === null) ||
            (dataset && dataset.extendedTechnicalCheckStatus !== null) ||
            dataset === undefined ||
            dataset && !dataset.status || dataset && dataset.status === "invalid" ||
            dataset && dataset.status === "invalidAndRejected" ||
            dataset && dataset.approved);
    }

    public isTechApproveSelectedItemLocked(): boolean {
        return !this.dataset || !this.dataset.status;
    }

    public isExtendedTechApproveAllowed(): boolean {
        return this.identityService.isAuthorized(RoleName.FS, RoleName.ZS, RoleName.TP) || this.isSameOrganisation(this.trackingSpecification);
    }

    public isApproveSelectedItemLocked(): boolean {
        return this.model.hasExtendedTechnicalCheck ?
            this.approvedSelectedItemWithExtendedTechCheckLocked() :
            this.approvedSelectedItemLocked();
    }

    private approvedSelectedItemWithExtendedTechCheckLocked(): boolean {
        const dataset = this.dataset;

        if (!dataset) {
            return false;
        }

        return dataset.approved || !dataset.fileId || dataset.extendedTechnicalCheckStatus === null || dataset.approved === false;
    }

    // eslint-disable-next-line complexity
    private approvedSelectedItemLocked(): boolean {
        const dataset = this.dataset;

        if (!dataset) {
            return false;
        }

        return dataset.approved || !dataset.fileId || !dataset.status || dataset.status && dataset.status === "invalid" || dataset.approved === false;
    }

    public isTechApproveAllowed(): boolean {
        const isAuthorized = this.identityService.isAuthorized(RoleName.FS, RoleName.ZS, RoleName.TP) ||
            this.isSameOrganisation(this.trackingSpecification);

        if (!this.dataset || !isAuthorized) {
            return false;
        }

        return !["valid", "invalidAndAccepted", "invalidAndRejected"].includes(this.dataset.status as string) && this.dataset.approved === null;
    }

    public isApproveAllowed(): boolean {
        const isAuthorized = this.identityService.isAuthorized(RoleName.FS, RoleName.ZS, RoleName.TP) ||
            this.isSameOrganisation(this.trackingSpecification);

        return isAuthorized && !!this.dataset;
    }

    private addManualAttributesToScope(dataset: IDataset): void {
        this.omanualApproveDate = this.formatDateTime(dataset.manualApproveDate);
        this.omanualApproveNote = dataset.manualApproveNote ? dataset.manualApproveNote : "";
        if (dataset.manualApproveUserId) {
            this.userService.findOne(dataset.manualApproveUserId).subscribe((user) => {
                this.omanualApproveUser = user;
            });
        } else {
            this.omanualApproveUser = null;
        }
    }

    private addExtendedTechnicalTestAttributesToScope(dataset: IDataset): void {
        this.extendedTechnicalCheckDate = this.formatDateTime(dataset.extendedTechnicalCheckDate);
        this.extendedTechnicalCheckNote = dataset.extendedTestProtocolFileId ? dataset.extendedTechnicalCheckNote : "";
        this.nameCheckProtocol = dataset.nameCheckProtocol;
    }

    private addPublicationAttributesToScope(dataset: IDataset): void {
        this.geoportalFtpUploadDate = this.formatDateTime(dataset.geoportalFtpUploadDate);
        this.geoportalPublishDate = this.formatDateTime(dataset.geoportalPublishDate);
        this.oerebShareDate = this.formatDateTime(dataset.oerebShareDate);
        this.oerebPublishDate = this.formatDateTime(dataset.oerebPublishDate);
        this.transformPublicationText(dataset, "geoportalFtpUploadSucceeded", "Upload");
        this.transformPublicationText(dataset, "geoportalPublishSucceeded", "Publikation");

    }

    private transformPublicationText(dataset: IDataset, attr: string, label: string): void {
        if (dataset[attr] === true) {
            this[attr] = `${label} erfolgreich`;
        } else if (dataset[attr] === false) {
            this[attr] = `${label} fehlgeschlagen`;
        } else {
            this[attr] = "";
        }
    }

    private formatDateTime(date: Date): string {
        return date ? moment(date).format("DD.MM.yyyy HH:mm") : "";
    }

    private getExtendTechnicalUser(dataset: IDataset): void {
        if (dataset.extendedTechnicalCheckUserId) {
            this.userService.findOne(dataset.extendedTechnicalCheckUserId).subscribe((user) => {
                this.extendedUserName = user === null ? "" : user.username;
            });
        }
    }

    private getExtendTechnicalStatus(dataset: IDataset): void {
        const translations = [
            {
                status: "valid",
                translation: "In Ordnung",
            },
            {
                status: "invalid",
                translation: "Nicht in Ordnung",
            },
            {
                status: "invalidAndRejected",
                translation: "Nicht in Ordnung",
            },
            {
                status: "invalidAndAccepted",
                translation: "In Ordnung",
            },
        ];

        if (dataset.extendedTechnicalCheckStatus) {
            translations.find((translate) => {
                if (translate.status === dataset.extendedTechnicalCheckStatus) {
                    this.extendedTechnicalCheckStatus = translate.translation;
                }
            });
        }
    }

    private isSameOrganisation(trackingSpecification: ITrackingSpecification): boolean {
        const zs = trackingSpecification && trackingSpecification.zs;
        const currentUser = this.identityService.currentUser;
        return !!(zs && currentUser && trackingSpecification?.zs?.organisationId === currentUser.organisation?.id);
    }

    private getTracking(dataset: IDataset): void {
        this.trackingService.findOne(dataset.specificationId).subscribe((trackingSpecification) => {
            this.trackingSpecification = trackingSpecification;
            this.getModel(trackingSpecification.modelId!);
            this.getGemeinde(trackingSpecification.gemeindeId!);
        });
    }

    private getModel(id: string): void {
        this.modelService.findOne(id).subscribe((model) => {
            this.model = model;
        });
    }

    private getGemeinde(id: string): void {
        this.gemeindeService.findOne(id).subscribe((gemeinde) => {
            this.gemeinde = gemeinde;
        });
    }

    public showReport(): void {
        const html = `<pre class="large-text-display">${this.dataset.report}</pre>`;
        const messageBox = this.messageBox.custom(GpMessageBoxComponent, {
            width: "900px",
            maxHeight: "90vh",
            title: "MESSAGEBOX.ERROR_REPORT",
        }, {
            message: html,
            buttons: [{
                label: "MESSAGEBOX.CLOSE",
                class: "btn-primary",
                callback: () => {
                    this.messageBox.hideInstance(messageBox);
                },
                gpTestId: "gb-report-ok-button",
            }],
        });
    }

    public hasGeoportalPublication(): boolean {
        if (this.model && this.dataset) {
            return this.model.hasGeoportalPublication && this.dataset.approved || false;
        }

        return false;

    }

    public hasOerebPublication(): boolean {
        if (this.model && this.dataset) {
            return this.model.hasOerebPublication || false;
        }

        return false;
    }

    public canBePublicatedToOereb(): boolean {
        if (this.dataset) {
            return Boolean(this.dataset.geoportalPublishSucceeded && !this.dataset.oerebShareDate);
        }

        return false;
    }

    public publicateOereb(): void {
        if (this.dataset.id && this.canBePublicatedToOereb()) {
            this.publicateOerebIsLoading = true;
            this.publicationService.publishOereb(this.dataset.id).subscribe({
                next: (dataset: IDataset) => {
                    this.updateGUI(dataset);
                    this.notificationService.notify("Freigabe ÖREB-Publikation war erfolgreich.");
                    this.publicateOerebIsLoading = false;
                },
                error: () => {
                    this.notificationService.error("Freigabe ÖREB-Publikation war nicht erfolgreich.");
                    this.publicateOerebIsLoading = false;
                },
            });

        }
    }

    public showTechRejectModal(): void {
        this.messageBox.custom(TechRejectDialog, { title: "Technische Prüfung" }, {
            dataset: this.dataset,
            techApprove: () => this.techApprove(),
            techReject: () => this.techReject(),
        });
    }

    public showExtendedTechRejectModal(): void {
        this.messageBox.custom(ExtendedTechRejectDialog, { title: "Erweiterte technische Prüfung" }, {
            dataset: this.dataset,
            onFileSelect: (files: File[]) => this.onFileSelect(files),
            extendedTechApprove: () => this.extendedTechApprove(),
            extendedTechReject: () => this.extendedTechReject(),
        });
    }

    public showProRejectModal(): void {
        this.messageBox.custom(ProRejectDialog, { title: "Fachliche Prüfung" }, {
            dataset: this.dataset,
            updateFeedback: () => this.updateFeedback(),
            proApprove: () => this.proApprove(),
            proReject: () => this.proReject(),
            feedbackRequired: this.feedbackRequired,
        });
    }

}
