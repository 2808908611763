import { Injectable } from "@angular/core";
import GeobusNavigationExtension from "./geobus-navigation.extension";


@Injectable({ providedIn: "root" })
export default class GeobusTopicExtension extends GeobusNavigationExtension {

    public get category(): string {
        return "data";
    }

    public get state(): { name: string } {
        return { name: "app.topics" };
    }

    public get label(): string {
        return "SIDE_MENU.DATA.TOPICS";
    }

    public get icon(): string {
        return "list";
    }

}
