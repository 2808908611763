import { Directive, ElementRef, Input, OnInit } from "@angular/core";


@Directive({
    selector: "[autofocus]",
})
export class AutofocusDirective implements OnInit {

    @Input()
    public set autofocus(condition: boolean | string) {
        this.focus = Boolean(condition);
    }

    private focus = true;

    public constructor(
        private elementRef: ElementRef<HTMLElement>,
    ) {
    }

    private get htmlElement(): HTMLElement {
        return this.elementRef.nativeElement;
    }

    public ngOnInit(): void {
        if (this.focus) {
            window.setTimeout(() => {
                this.htmlElement.focus();
            });
        }
    }

}
