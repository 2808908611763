import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import IDistribution from "../tracking/IDistribution";
import IFtpInfo, { IFtpJson } from "./IFtpInfo";
import { IGridColumn } from "../../../common/IGridColumn";
import { Item as DxButtonGroupItem, ItemClickEvent, SelectionChangedEvent as ButtonSelectionChangedEvent } from "devextreme/ui/button_group";
import IFtpServerType from "../ftpServer/IFtpServerType";
import DataSource from "devextreme/data/data_source";
import { IFormat, IInterlisFormat } from "./IDistribution";
import ITrackingSpecification from "../tracking/ITrackingSpecification";
import { MessageBox, NotificationService, StateService } from "@geolib/geolib-client";
import { DxButtonGroupComponent, DxDataGridComponent, DxDateBoxComponent } from "devextreme-angular";
import { TrackingService } from "../tracking/TrackingService";
import { FtpServerService } from "../ftpServer/FtpServerService";
import { TranslateService } from "@ngx-translate/core";
import { isNumber } from "../common/IsNumber";


@Component({
    selector: "distribution-edit",
    templateUrl: "./distribution-edit.html",
})
export default class DistributionEditComponent implements OnInit, AfterViewInit {

    @ViewChild(NgForm) public readonly distributionEditForm: NgForm;
    @ViewChild(DxDataGridComponent) public dxDataGrid: DxDataGridComponent;
    @ViewChild(DxDateBoxComponent) public readonly dateBox: DxDateBoxComponent;
    @ViewChild("intervalTypeButtonGroup") public readonly intervalTypeButtonGroup: DxButtonGroupComponent;

    @Input() private trackingSpecificationId: string;
    @Input() public manualDownload: boolean;
    @Input() public selectionChanged: (selectedItem: IDistribution | undefined) => void;

    @Output() public form = new EventEmitter<NgForm>();

    private trackingSpecification: ITrackingSpecification;
    public ftpinfo: Partial<IFtpInfo>;
    public distribution: IDistribution | undefined;
    public dataGridColumns: IGridColumn[] = [];
    public lang: "de";
    public distributionTypeOptions: Array<DxButtonGroupItem & { key: string }>;
    public intervalTypeOptions: Array<DxButtonGroupItem & { key: string }>;
    public bootstrapSuffix: "default";
    public ftpServerTypes: IFtpServerType[] = [];
    public formats: IFormat[];
    public interlisFormats: IInterlisFormat[];
    public dataSource: DataSource<IDistribution, number>;
    public editTitle: string;
    private intervalTypeInitialized: boolean = false;
    private preventResetIntervalType: boolean = false;

    // eslint-disable-next-line max-params
    public constructor(
        private readonly messageBox: MessageBox,
        private readonly stateService: StateService,
        private readonly trackingService: TrackingService,
        private readonly notificationService: NotificationService,
        private readonly ftpServerService: FtpServerService,
        private readonly translateService: TranslateService,
        private readonly changeDetectorRef: ChangeDetectorRef,
    ) {
        this.bootstrapSuffix = "default";
        this.lang = "de";
        this.editTitle = "";
        this.ftpinfo = {};
        this.distribution = {} as Required<IDistribution>;

        this.distributionTypChanged = this.distributionTypChanged.bind(this);
    }

    public ngOnInit(): void {
        this.distributionTypeOptions = [
            { key: "mail", text: "E-Mail" },
            { key: "ftp", text: "FTP" },
            { key: "sftp", text: "SFTP" },
        ];

        if (this.manualDownload) {
            this.distributionTypeOptions.push({ key: "direct", text: "Direkt" });
        }

        this.intervalTypeOptions = [{ key: "day", text: "Tage" }, { key: "month", text: "Monate" }];

        for (const item of [...this.distributionTypeOptions, ...this.intervalTypeOptions]) {
            item.type = "danger";
        }

        this.ftpServerService.findAllTypes().subscribe((ftpServerTypes) => {
            this.ftpServerTypes = ftpServerTypes;
            this.ftpServerTypes.forEach(
                (ftpServerType) => ftpServerType.name = this.translateService.instant(`FTP_SERVER.TYPES.${ftpServerType.type.toUpperCase()}`),
            );
            this.ftpServerTypes.push({ type: "custom", name: "Benutzerdefiniert", directory: "", ssh: false, id: Math.random().toString() });
        });

        this.trackingService.findOne(this.id).subscribe((trackingSpecification) => {
            this.trackingSpecification = trackingSpecification;
            this.editTitle = `${trackingSpecification.topic?.name} in ${trackingSpecification.gemeinde?.name}`;

            if (!this.manualDownload) {
                this.distribution =
                    trackingSpecification.distributions?.find(
                        (distribution) => distribution.id === this.stateService.getParameter("distributionId"),
                    ) ?? {} as Required<IDistribution>;
            }

            this.distributionChanged();
            this.parseAvailableExportFormats();
        });
    }

    private get id(): string {
        return this.trackingSpecificationId || this.stateService.getParameter("id");
    }

    public ngAfterViewInit(): void {
        this.form.emit(this.distributionEditForm);
    }

    private distributionChanged(): void {
        if (this.isDefined(this.distribution) && this.isFtpOrSftp()) {
            const json = this.getDataFromTarget();
            this.ftpinfo.ftpServerType = this.getFtpServerType(json.ftpServerType || null);

            if (this.ftpinfo.ftpServerType) {
                this.ftpinfo.ftpdirectory = this.ftpinfo.ftpServerType.directory;
            }

            this.deleteFtpData();
            this.parseFtpData(json);
        } else {
            this.ftpinfo = {};
        }
    }

    private getDataFromTarget(): IFtpJson {
        return JSON.parse(this.distribution?.target || "{}");
    }

    private getFtpServerType(type: string | null): IFtpServerType | undefined {
        return this.ftpServerTypes.find((ftpServerType) => ftpServerType.type === type);
    }

    public parseFtpData(json: IFtpJson): void {
        if (this.ftpinfo.ftpServerType && this.ftpinfo.ftpServerType.type === "custom") {
            this.ftpinfo.ftpserver = json.server;
            this.ftpinfo.ftpport = json.port;
            this.ftpinfo.ftpuser = json.username;
            this.ftpinfo.ftppassword = json.password;
        }

        this.ftpinfo.format = json.format;
        this.ftpinfo.interlisFormat = json.interlisFormat;
        this.ftpinfo.ssh = json.ssh;
        this.ftpinfo.ftpdirectory = json.directory;
    }

    private deleteFtpData(): void {
        delete this.ftpinfo.ftpserver;
        delete this.ftpinfo.ftpport;
        delete this.ftpinfo.ftpuser;
        delete this.ftpinfo.ftppassword;
        delete this.ftpinfo.ftpdirectory;
        delete this.ftpinfo.format;
        delete this.ftpinfo.interlisFormat;
        delete this.ftpinfo.ssh;
    }

    public formatChanged(): void {
        if (!this.ftpinfo.format || (this.ftpinfo.format && !this.ftpinfo.format.includes("interlis"))) {
            this.ftpinfo.interlisFormat = null;
        }
    }

    public isFtpOrSftp(): boolean {
        return this.isDefined(this.distribution) && (this.distribution.type === "ftp" || this.distribution.type === "sftp");
    }

    public isEmail(): boolean {
        return this.isDefined(this.distribution) && this.distribution.type === "mail";
    }

    public isDirect(): boolean {
        return this.isDefined(this.distribution) && this.distribution.type === "direct";
    }

    public isCustomFtpServer(): boolean {
        return this.ftpinfo.ftpServerType?.type === "custom";
    }

    private isDefined<T>(object: T | null | undefined): object is T {
        return !!object;
    }

    public intervalChanged(): void {
        if (this.isDefined(this.distribution) && !this.distribution.interval) {
            this.distribution.nextExecution = null;
        }
    }

    public intervalTypeChanged(event: ButtonSelectionChangedEvent): void {
        if (this.isDefined(this.distribution)) {
            this.distribution.intervalType = event.addedItems[0]?.key ?? null;
            this.preventResetIntervalType = this.intervalTypeInitialized;

            if (!this.distribution.intervalType) {
                this.distribution.interval = null;
                this.distribution.nextExecution = null;
            }
        }

        this.intervalTypeInitialized = true;
    }

    public intervalTypeClick(event: ItemClickEvent): void {
        if (this.isDefined(this.distribution) && event.itemData.key === this.distribution.intervalType && !this.preventResetIntervalType) {
            this.intervalTypeButtonGroup.selectedItems = [];
            this.changeDetectorRef.detectChanges();
            this.intervalTypeButtonGroup.instance.repaint();
        }

        this.preventResetIntervalType = false;
    }

    private handleSelectionChangedEvent(): void {
        if (!this.manualDownload && typeof this.selectionChanged === "function") {
            this.selectionChanged(this.dxDataGrid.instance.getSelectedRowsData()[0]);
        } else if (this.manualDownload) {
            this.selectionChanged(this.distribution);
        }
    }

    public distributionTypChanged(event: ButtonSelectionChangedEvent): void {
        if (this.distribution) {
            this.distribution.target = "";
            this.distribution.type = event.addedItems[0].key;
        }

        this.resetTarget();

        if (this.manualDownload) {
            this.handleSelectionChangedEvent();
        }
    }

    private resetTarget(): void {
        if (this.isDefined(this.distribution)) {
            if (this.isFtpOrSftp()) {
                this.distributionTypChangedToFtp();
            } else if (this.isEmail()) {
                delete this.ftpinfo.ftpServerType;
                this.deleteFtpData();
            } else if (this.isDirect()) {
                this.distribution.target = "direct";
            }
        }
    }

    public distributionTypChangedToFtp(): void {
        this.ftpinfo.ssh = this.distribution && this.distribution.type === "sftp" || false;

        if (this.manualDownload) {
            this.saveTemporary();
        }
    }

    public ftpServerTypeChanged(): void {
        this.deleteFtpData();

        if (this.ftpinfo.ftpServerType && this.ftpinfo.ftpServerType.type !== "custom") {
            const ftpServerType = this.getFtpServerType(this.ftpinfo.ftpServerType.type);

            if (ftpServerType) {
                this.ftpinfo.ftpdirectory = ftpServerType.directory;
            }
        }
        this.getDefaultFormat();
        this.saveTemporary();
    }

    private getDefaultFormat(): void {
        this.ftpinfo.format = this.trackingSpecification.model?.format;
        this.ftpinfo.interlisFormat = this.trackingSpecification.model?.interlisFormat;
        this.ftpinfo.ssh = this.distribution && this.distribution.type === "sftp" || false;
    }

    public save(): void {
        if (!this.distribution) {
            return;
        }

        const nextExecution = this.checkNextExecution();
        if (nextExecution) {
            this.saveDistribution();
        } else {
            this.messageBox.warn(this.translateService.instant("DISTRIBUTION_EDIT.MESSAGE.DATE_INVALID"));
        }
        this.abort();
    }

    private checkNextExecution(): boolean {
        return !this.distribution!.nextExecution && this.distributionEditForm.controls.nextexec?.value ? false : true;
    }

    public saveTemporary(): void {
        if (this.manualDownload && this.isDefined(this.distribution) && this.isFtpOrSftp()) {
            const ftpJson = this.getFtpJson();
            this.distribution.target = JSON.stringify(ftpJson);
        }
    }

    private getFtpJson(): IFtpJson {
        const ftpServerType = (this.ftpinfo.ftpServerType && this.ftpinfo.ftpServerType.type);

        const ftpJson: IFtpJson = {
            directory: this.ftpinfo.ftpdirectory,
            format: this.ftpinfo.format,
            interlisFormat: this.ftpinfo.interlisFormat,
            ssh: this.ftpinfo.ssh,
            ftpServerType,
        };

        if (ftpServerType === "custom") {
            ftpJson.server = this.ftpinfo.ftpserver;
            ftpJson.port = this.ftpinfo.ftpport;
            ftpJson.username = this.ftpinfo.ftpuser;
            ftpJson.password = this.ftpinfo.ftppassword;
        }

        return ftpJson;
    }

    private saveDistribution(): void {
        if (!this.distribution) {
            return;
        }

        this.parseDistributionData();
        this.trackingSpecification.distributions?.push(this.distribution);

        for (const distribution of (this.trackingSpecification.distributions ?? [])) {
            if (isNumber(distribution.id)) {
                delete distribution.id;
            }
        }

        this.trackingService.save(this.trackingSpecification as Required<ITrackingSpecification>)
            .subscribe(() => {
                this.notificationService.notify(this.translateService.instant("DISTRIBUTION_EDIT.MESSAGE.SAVE_SUCCESS"));
            });
    }

    private parseDistributionData(): void {
        if (this.isDefined(this.distribution) && this.isFtpOrSftp()) {
            const ftpJson = this.getFtpJson();
            this.distribution.target = JSON.stringify(ftpJson);
        }

        this.parseNextExecution();
    }

    private parseNextExecution(): void {
        if (this.distribution && !this.distribution.interval) {
            this.distribution.nextExecution = null;
        }

        if (this.distribution?.nextExecution) {
            this.distribution.nextExecution = new Date(this.distribution.nextExecution);
            this.distribution.nextExecution.setHours(12);
            this.distribution.nextExecution.setMinutes(0);
        }
    }

    private parseAvailableExportFormats(): void {
        this.formats = [
            { value: "interlis1", label: "Interlis1 (.itf)", condition: (format?: string | null) => format === "interlis1" },
            { value: "interlis2", label: "Interlis2 (.xtf)", condition: (format?: string | null) => format === "interlis2" },
            { value: "geopackage", label: "Geopackage (.gpkg)", condition: (format?: string | null) => format !== "other" },
            { value: "other", label: "Andere", condition: (format?: string | null) => format === "other" },
        ].filter((format) => format.condition(this.trackingSpecification.model?.format));

        this.interlisFormats = [
            {
                value: "canton",
                label: "Kantonales Modell",
                condition: (interlisFormat?: string | null, format?: string | null) => Boolean(format?.includes("interlis") && interlisFormat &&
                    interlisFormat === "canton"),
            },
            {
                value: "federal",
                label: "Bundesmodell",
                condition: (_interlisFormat?: string | null, format?: string | null) => Boolean(format?.includes("interlis")),
            },
        ].filter((format) => format.condition(this.trackingSpecification.model?.interlisFormat, this.trackingSpecification.model?.format));

        this.parseOriginalData(this.formats, this.trackingSpecification.model?.format, this.trackingSpecification.model?.interlisFormat);
        this.parseOriginalData(this.interlisFormats, this.trackingSpecification.model?.interlisFormat, this.trackingSpecification.model?.format);
    }

    private parseOriginalData(formats: IFormat[] | IInterlisFormat[], modelFormat?: string | null, optionalFormat?: string | null): void {
        const format = formats.find((format) => format.value === modelFormat);

        this.parseInterlisLabel(format, optionalFormat);
        this.parseGeopackageLabel(format, modelFormat);

        if (format && format.value === "other" && modelFormat === "other") {
            format.label += " (mit allfälligen Zusatzdokumente)";
        }
    }

    // eslint-disable-next-line complexity
    private parseInterlisLabel(format?: IInterlisFormat | null, optionalFormat?: string | null): void {
        if (!format) {
            return;
        }

        if (format.value === "canton" || format.value === "federal") {
            if (optionalFormat === "interlis1") {
                format.label += " (Original-.itf)";
            } else if (optionalFormat === "interlis2") {
                format.label += " (Original-.xtf)";
            }
        }
    }

    private parseGeopackageLabel(format?: IInterlisFormat, modelFormat?: string | null): void {
        if (format && format.value === "geopackage" && modelFormat === "geopackage") {
            format.label += " (Original-.gpkg)";
        }
    }

    public get isDistributionValid(): boolean {
        return Boolean(this.distribution?.type && this.distribution.type !== "new");
    }

    public get isFormValid(): boolean {
        return Boolean(this.distributionEditForm?.valid && (!this.dateBox || this.dateBox.isValid));
    }

    public abort(): void {
        this.stateService.go("app.trackings.distribution", { id: this.stateService.getParameter("id") });
    }

}
