<div class="gp-page-header">
    <div class="gp-container-normal">
        <breadcrumb></breadcrumb>
        @if (user) {
            <div class="gp-page-header-container">
                @if (user.firstname && user.lastname) {
                    <h1 class="gp-page-header-title">{{ user.firstname }} {{ user.lastname }}</h1>
                }
                @if (!user.firstname || !user.lastname) {
                    <h1 class="gp-page-header-title">{{ "USER_EDIT.TITLE_NEW" | translate }}</h1>
                }
                <div class="gp-page-header-actions">
                    <div class="btn-group">
                        <button class="btn btn-default btn-back" (click)="abort()">
                            {{ "NAVIGATION.BACK" | translate }}
                        </button>
                        <gp-button-primary (click)="save()" [isDisabled]="!isFormValid()" translation="NAVIGATION.SAVE"></gp-button-primary>
                    </div>
                </div>
            </div>
        }
    </div>
</div>
<div class="gp-container-normal">
    <form #userEditForm="ngForm" (ngSubmit)="save()">
        <div class="form-group">
            @if (user) {
                <div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="col-form-label">{{ "USER_EDIT.LABEL.SALUTATION" | translate }}</div>
                            <dx-select-box
                                [dataSource]="salutationOptions"
                                [(ngModel)]="user.salutation"
                                displayExpr="value"
                                valueExpr="key"
                                name="salutation"
                                autofocus="true"
                                [required]="true"
                                [searchEnabled]="true"
                            ></dx-select-box>
                        </div>
                        <label class="col-md-6" [class.has-error]="userAlreadyExist">
                            <div class="col-form-label">{{ "USER_EDIT.LABEL.USERNAME" | translate }}</div>
                            <input class="form-control" [required]="true" [(ngModel)]="user.username" name="username" />
                            @if (userAlreadyExist) {
                                <ul class="gp-form-control-error">
                                    <li>{{ "USER_EDIT.ERROR_MESSAGE.USERNAME" | translate }}</li>
                                </ul>
                            }
                        </label>
                    </div>
                    <div class="row">
                        <label class="col-md-6">
                            <div class="col-form-label">{{ "USER_EDIT.LABEL.FIRSTNAME" | translate }}</div>
                            <input class="form-control" [required]="true" [(ngModel)]="user.firstname" name="firstname" />
                        </label>
                        <label class="col-md-6" [class.has-error]="errorPassword || (isNewUser() && !user.newPassword)">
                            <div class="col-form-label">{{ "USER_EDIT.LABEL.PASSWORD" | translate }}</div>
                            <input
                                class="form-control"
                                [required]="isNewUser()"
                                [(ngModel)]="user.newPassword"
                                name="newPassword"
                                type="password"
                                (keyup)="validPwdCheck()"
                                #password
                                autocomplete="new-password"
                            />
                            @if (errorPassword || (isNewUser() && !user.newPassword)) {
                                <ul class="gp-form-control-error">
                                    @if (errorPassword) {
                                        <li>{{ errorPassword }}</li>
                                    }
                                    @if (isNewUser() && !user.newPassword) {
                                        <li>{{ "USER_EDIT.ERROR_MESSAGE.PASSWORD" | translate }}</li>
                                    }
                                </ul>
                            }
                        </label>
                    </div>
                    <div class="row">
                        <label class="col-md-6">
                            <div class="col-form-label">{{ "USER_EDIT.LABEL.LASTNAME" | translate }}</div>
                            <input class="form-control" [required]="true" [(ngModel)]="user.lastname" name="lastname" />
                        </label>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="col-form-label">{{ "USER_EDIT.LABEL.ORGANISATION" | translate }}</div>
                            <dx-select-box
                                [dataSource]="organisations"
                                [(ngModel)]="user.organisationId"
                                displayExpr="name"
                                valueExpr="id"
                                name="organisation"
                                [required]="true"
                                (ngModelChange)="organisationChanged()"
                                [searchEnabled]="true"
                            ></dx-select-box>
                        </div>
                    </div>
                    <div class="row">
                        <label class="col-md-6" [class.has-error]="prmEmail.invalid">
                            <div class="col-form-label">{{ "USER_EDIT.LABEL.EMAIL" | translate }}</div>
                            <input
                                class="form-control"
                                #prmEmail="ngModel"
                                type="email"
                                [required]="true"
                                [(ngModel)]="user.email"
                                name="email"
                                autocomplete="email"
                                pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                            />
                            @if (prmEmail.invalid) {
                                <ul class="gp-form-control-error">
                                    <li>{{ "VALIDATION.EMAIL_NOTVALID" | translate }}</li>
                                </ul>
                            }
                        </label>
                    </div>
                    <div class="row">
                        <label class="col-md-6">
                            <div class="col-form-label">{{ "USER_EDIT.LABEL.TELEFON" | translate }}</div>
                            <input class="form-control" [(ngModel)]="user.telephone" name="telephone" />
                        </label>
                    </div>
                </div>
            }
        </div>
    </form>
</div>
