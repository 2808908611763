<div class="gp-startpage">
    <div class="gp-app-title"><img src="/images/logo/GEOBUS.svg" /></div>
    <div class="gp-startpage-scrollpanel">
        <div class="gp-container gp-container-extra-large">
            <div class="gp-page-header">
                <div class="gp-container gp-container-extra-large">
                    <div class="gp-page-header-container">
                        <h1 class="gp-page-header-title">{{ "MODEL.DOWNLOAD_MODELS" | translate }}</h1>
                        <div class="gp-page-header-actions">
                            <div class="btn-group">
                                <button class="btn btn-default btn-back" (click)="goToStartPage()">
                                    {{ "DOWNLOAD_PAGE.STARTPAGE" | translate }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="gp-container gp-container-extra-large">
                <dx-data-grid
                    [dataSource]="models"
                    [showBorders]="true"
                    [columnAutoWidth]="true"
                    [allowColumnResizing]="true"
                    columnResizingMode="widget"
                    [rowAlternationEnabled]="true"
                >
                    <dxo-search-panel [visible]="true"></dxo-search-panel>
                    <dxo-header-filter [visible]="true"></dxo-header-filter>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxo-sorting mode="multiple" [showSortIndexes]="true"></dxo-sorting>
                    <dxo-filter-row [visible]="true"></dxo-filter-row>
                    <dxo-scrolling mode="virtual" showScrollbar="always"></dxo-scrolling>
                    @for (column of dataGridColumns; track column) {
                        <dxi-column
                            [allowResizing]="true"
                            [allowSorting]="true"
                            [dataField]="column.dataField"
                            [caption]="column.caption"
                            [visible]="column.visible"
                            [width]="column.width"
                            [dataType]="column.dataType"
                            [format]="column.format"
                            [sortOrder]="column.sortOrder"
                            [sortIndex]="column.sortIndex"
                            [cellTemplate]="column.cellTemplate"
                            [alignment]="column.alignment"
                            [calculateCellValue]="column.calculateCellValue"
                            [calculateDisplayValue]="column.calculateDisplayValue"
                        ></dxi-column>
                    }
                    <div *dxTemplate="let cell of 'modelTemplate'">
                        @if (cell.data.modelFileId) {
                            <div class="cell-overflow">
                                <a target="_self" style="cursor: pointer" href="/download/filelist/{{ cell.data.modelFileId }}">{{
                                    "MODEL.DOWNLOAD_MODEL" | translate
                                }}</a>
                            </div>
                        }
                    </div>
                    <div *dxTemplate="let cell of 'guidelineTemplate'">
                        @if (cell.data.guidelinesFileId) {
                            <div class="cell-overflow">
                                <a target="_self" style="cursor: pointer" href="/download/filelist/{{ cell.data.guidelinesFileId }}">{{
                                    "MODEL.DOWNLOAD_GUIDELINE" | translate
                                }}</a>
                            </div>
                        }
                    </div>
                </dx-data-grid>
            </div>
        </div>
    </div>
    <footer></footer>
</div>
