import { TrackingService } from "../tracking/TrackingService";
import { GemeindeService } from "../gemeinde/GemeindeService";
import { OrganisationService } from "../organisation/OrganisationService";
import { IdentityService } from "../account/IdentityService";
import { RoleName } from "../user/IRole";
import ITrackingSpecification from "../tracking/ITrackingSpecification";
import IGemeinde from "../gemeinde/IGemeinde";
import ITopic from "../topic/ITopic";
import IOrganisation from "../organisation/IOrganisation";
import IDistribution from "../tracking/IDistribution";
import { TopicService } from "../topic/TopicService";
import { MessageBox, NotificationService, StateService } from "@geolib/geolib-client";
import IFtpInfo, { IFtpJson } from "./IFtpInfo";
import { forkJoin } from "rxjs";
import { finalize } from "rxjs/operators";
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from "@angular/core";
import DataSource from "devextreme/data/data_source";
import { NgForm } from "@angular/forms";
import ArrayStore from "devextreme/data/array_store";
import { NGXLogger } from "ngx-logger";
import { TranslateService } from "@ngx-translate/core";
import { IGridColumn } from "../../../common/IGridColumn";
import { IFormat } from "./IDistribution";
import GridController from "../common/GridController";
import GridService from "../common/GridService";
import { UserSettingsService } from "@geolib/geoappbase-client";

@Component({
    selector: "distribution",
    templateUrl: "./distribution.component.html",
})
export default class DistributionComponent extends GridController implements OnInit {

    @Input() private trackingSpecificationId: string;
    @Input() public selectionChanged: (selectedItem: IDistribution | undefined) => void;
    @Output() public form = new EventEmitter<NgForm>();

    private trackingSpecification: ITrackingSpecification;
    public ftpinfo: Partial<IFtpInfo>;
    private topics: ITopic[];
    public distribution: IDistribution | undefined;
    private gemeinden: IGemeinde[];
    public dataGridColumns: IGridColumn[] = [];
    public organisations: IOrganisation[];
    public editTitle: string;
    public formats: IFormat[];
    public dataSource: DataSource<IDistribution, number>;
    private previousError?: Error;

    // eslint-disable-next-line max-params
    public constructor(
        private readonly trackingService: TrackingService,
        private readonly organisationService: OrganisationService,
        private readonly gemeindeService: GemeindeService,
        private readonly stateService: StateService,
        private readonly messageBox: MessageBox,
        private readonly notificationService: NotificationService,
        private readonly topicService: TopicService,
        private readonly translateService: TranslateService,
        elementRef: ElementRef<HTMLElement>,
        gridService: GridService,
        userSettingsService: UserSettingsService,
        logger: NGXLogger,
        changeDetectorRef: ChangeDetectorRef,
        identityService: IdentityService,
    ) {
        super(elementRef.nativeElement, gridService, userSettingsService, logger, changeDetectorRef, identityService);

        this.editTitle = "";
        this.ftpinfo = {};
        this.distribution = {} as Required<IDistribution>;
    }

    protected get settingsKey(): string {
        return "geobus.distribution.grid";
    }

    public ngOnInit(): void {
        this.fetchData();
        this.dataGridColumns = this.getColumns();
    }

    public get id(): string {
        return this.trackingSpecificationId || this.stateService.getParameter("id");
    }

    public getTargetValue(distribution: IDistribution): string {
        try {
            if (!distribution?.target || typeof distribution.target !== "string") {
                return "";
            }

            if (distribution.type === "mail") {
                return distribution.target;
            }

            return this.getFormattedFtpTarget(distribution);
        } catch (error) {
            this.logError(error);
            return "Unknown";
        }
    }

    private logError(error: Error): void {
        if (this.previousError?.message !== error.message && this.previousError?.stack !== error.stack) {
            this.logger.error(error);
        }

        this.previousError = error;
    }

    public edit(): void {
        const selectedItem = this.getSelectedItem();

        if (!selectedItem) {
            return;
        }
        this.stateService.go("app.trackings.distribution.edit", { id: this.id, distributionId: selectedItem.id });
    }

    public onRowDblClick(): void {
        this.edit();
    }

    private getFormattedFtpTarget(distribution: IDistribution): string {
        const target = JSON.parse(distribution.target) as IFtpJson;

        if (target.ftpServerType === "custom") {
            return `[${this.translateService.instant(
                `FTP_SERVER.TYPES.${target.ftpServerType.toUpperCase()}`)}] ${target.server}: ${target.directory ?? ""}`;
        } else {
            return `[${this.translateService.instant(`FTP_SERVER.TYPES.${target.ftpServerType?.toUpperCase()}`)}] ${target.directory ?? ""}`;
        }
    }

    public hasSelectedItem(): boolean {
        return !!this.getSelectedItem();
    }

    private getSelectedItem(): IDistribution | undefined {
        return this.dxDataGrid?.instance.getSelectedRowsData()[0];
    }

    public create(): void {
        this.stateService.go("app.trackings.distribution.edit", { id: this.id, distributionId: "create" });
    }

    public delete(): void {
        this.messageBox.confirmDelete().subscribe(() => {
            if (Array.isArray(this.dxDataGrid.selectedRowKeys) && this.dxDataGrid.selectedRowKeys.length > 0) {
                this.removeDistribution();
                this.trackingService.save(this.trackingSpecification as Required<ITrackingSpecification>).pipe(
                    finalize(() => {
                        if (Array.isArray(this.dxDataGrid.selectedRowKeys)) {
                            this.dxDataGrid.instance.deselectAll();
                        }
                    })).subscribe({
                    next: () => {
                        this.notificationService.notify(this.translateService.instant("DISTRIBUTION.MESSAGE.SAVE_SUCCESS"));
                    },
                    error: () => {
                        this.notificationService.error(this.translateService.instant("DISTRIBUTION.MESSAGE.SAVE_FAILURE"));
                    },
                });
            }
        });
    }

    private removeDistribution(item?: IDistribution): void {
        const selectedItem = item || this.getSelectedItem();

        if (!selectedItem) {
            return;
        }

        this.dxDataGrid.instance.getDataSource().store().remove(selectedItem.id).then(() => {
            this.dxDataGrid.instance.refresh();
        });
    }

    public get isChangeDistributionAllowed(): boolean {
        return this.identityService.isAuthorized(RoleName.FS, RoleName.ZS, RoleName.TP);
    }

    private updateEditTitle(): void {
        if (this.topics && this.gemeinden && this.trackingSpecification) {
            this.setEditTitle();
        }
    }

    private setEditTitle(): void {
        const topic = this.topics.filter((t) => t.id === this.trackingSpecification.topicId).pop();
        const gemeinde = this.gemeinden.filter((g) => g.id === this.trackingSpecification.gemeindeId).pop();

        if (topic?.name && gemeinde?.name) {
            this.editTitle = `${topic.name} in ${gemeinde.name}`;
        }
    }

    private fetchData(): void {

        forkJoin([
            this.topicService.findAll(),
            this.gemeindeService.findAll(),
            this.trackingService.findOne(this.id),
            this.organisationService.findAll(),
        ]).subscribe(([topics, gemeinden, trackingSpecification, organisations]) => {
            this.topics = topics;
            this.gemeinden = gemeinden;
            this.trackingSpecification = trackingSpecification;
            this.organisations = organisations;
            this.trackingSpecification.distributions = this.trackingSpecification.distributions?.map((distribution) => {
                distribution.nextExecution = distribution.nextExecution ? new Date(distribution.nextExecution) : null;
                return distribution;
            });

            this.updateEditTitle();

            this.dataSource = new DataSource<IDistribution, number>({
                store: new ArrayStore({
                    key: "id",
                    data: this.trackingSpecification.distributions,
                }),
            });
        });
    }

    public abort(): void {
        this.stateService.go("app.trackings");
    }

    private getColumns(): IGridColumn[] {
        return [
            {
                dataField: "type",
                caption: this.translateService.instant("DISTRIBUTION.GRID.TYPE"),
                groupIndex: 0,
                sortOrder: "asc",
                cellTemplate: "typeTemplate",
            }, {
                dataField: "target",
                caption: this.translateService.instant("DISTRIBUTION.GRID.TARGET"),
                groupIndex: 1,
                cellTemplate: "targetTemplate",
            }, {
                dataField: "nextExecution",
                caption: this.translateService.instant("DISTRIBUTION.GRID.NEXT_EXECUTION"),
                groupIndex: 2,
                sortOrder: "asc",
                format: "dd.MM.yyyy",
            },
        ];
    }
}
