<div class="gp-page-header">
    <div class="gp-container-normal">
        <breadcrumb></breadcrumb>
        @if (ftpServer && ftpEditForm) {
            <div class="gp-page-header-container">
                @if (ftpServer.server) {
                    <h1 class="gp-page-header-title">{{ ftpServer.server }}</h1>
                }
                @if (!ftpServer.server) {
                    <h1 class="gp-page-header-title">{{ "FTP_SERVER_EDIT.TITLE" | translate }}</h1>
                }
                <div class="gp-page-header-actions">
                    <div class="btn-group">
                        <button class="btn btn-default btn-back" (click)="abort()">
                            {{ "NAVIGATION.BACK" | translate }}
                        </button>
                        <gp-button-primary
                            (click)="save()"
                            [isDisabled]="!ftpEditForm.valid || ftpServer.ssh === null"
                            translation="NAVIGATION.SAVE"
                        ></gp-button-primary>
                    </div>
                </div>
            </div>
        }
    </div>
</div>
<div class="gp-container-normal">
    @if (!errorMessage && !ftpServer) {
        <gp-spinner [visible]="true"></gp-spinner>
    }
    @if (errorMessage) {
        <div class="alert alert-danger">{{ errorMessage | translate }}</div>
    }
    @if (ftpServer) {
        <form #ftpEditForm="ngForm">
            <div class="row">
                <div class="col">
                    <label class="col-md-12">
                        <div class="col-form-label">{{ "FTP_SERVER_EDIT.TYPE" | translate }}</div>
                        <dx-button-group
                            [items]="ftpSshButtons"
                            keyExpr="key"
                            stylingMode="text"
                            [selectedItemKeys]="getSelectedSshKeys()"
                            (onSelectionChanged)="sshChanged($event)"
                        ></dx-button-group>
                    </label>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="col-form-label">{{ "FTP_SERVER_EDIT.SERVER_TYPE" | translate }}</div>
                        <dx-select-box
                            [dataSource]="ftpType"
                            [(ngModel)]="ftpServer.type"
                            displayExpr="value"
                            valueExpr="key"
                            name="serverType"
                            autofocus="true"
                            [required]="true"
                            [searchEnabled]="true"
                        ></dx-select-box>
                    </div>
                    <label class="col-md-6">
                        <div class="col-form-label">{{ "FTP_SERVER_EDIT.SERVER" | translate }}</div>
                        <input class="form-control" [required]="true" [(ngModel)]="ftpServer.server" name="server" />
                    </label>
                </div>
                <div class="row">
                    <label class="col-md-6">
                        <div class="col-form-label">{{ "FTP_SERVER_EDIT.PORT" | translate }}</div>
                        <input class="form-control" [required]="true" [(ngModel)]="ftpServer.port" type="number" name="port" />
                    </label>
                    <label class="col-md-6">
                        <div class="col-form-label">{{ "FTP_SERVER_EDIT.USER_NAME" | translate }}</div>
                        <input class="form-control" [required]="true" [(ngModel)]="ftpServer.username" name="username" autocomplete="new-username" />
                    </label>
                </div>
                <div class="row">
                    <label class="col-md-6">
                        <div class="col-form-label">{{ "FTP_SERVER_EDIT.PASSWORD" | translate }}</div>
                        <input
                            class="form-control"
                            [required]="true"
                            [(ngModel)]="ftpServer.password"
                            type="password"
                            name="password"
                            autocomplete="new-password"
                        />
                    </label>
                    <label class="col-md-6">
                        <div class="col-form-label">{{ "FTP_SERVER_EDIT.DIRECTORY" | translate }}</div>
                        <input class="form-control" [(ngModel)]="ftpServer.directory" name="directory" />
                    </label>
                </div>
            </div>
        </form>
    }
</div>
