<p>
    Der GEOBUS ist ein Tresor für Originaldaten mit integriertem Task- und Message-System für alle zuständigen und nachführenden Stellen, die
    GIS-Fachstellen, den Bund, die Kantonen sowie Geoportal-Betreiber.
</p>
<p>Der Tresor übernimmt auch die Funktion einer Plattform für den Transfer von Originaldaten zwischen all diesen Akteuren.</p>
<p>
    Die Datenhaltung in einer zentralen Geodateninfrastruktur gemäss ÖREB gewährleistet nicht nur die permanente Verfügbarkeit und Sicherheit der
    Daten, sondern sorgt auch für Transparenz und eine grössere Unabhängigkeit der zuständigen Stelle bei der Auswahl des Dienstleisters für die Daten
    Nachführung.
</p>
<div class="form-submit dialog-form-submit text-right">
    <div class="btn-group">
        <button class="btn btn-primary" (click)="close()">Schliessen</button>
    </div>
</div>
