<div class="gp-page-header">
    <div class="gp-container-normal">
        <breadcrumb></breadcrumb>
        @if (topic) {
            <div class="gp-page-header-container">
                @if (topic.name) {
                    <h1 class="gp-page-header-title">{{ topic.name }}</h1>
                }
                @if (!topic.name) {
                    <h1 class="gp-page-header-title">{{ "TOPIC_EDIT.TITLE" | translate }}</h1>
                }
                <div class="gp-page-header-actions">
                    <div class="btn-group">
                        <button class="btn btn-default btn-back" (click)="abort()">
                            {{ "NAVIGATION.BACK" | translate }}
                        </button>
                        <gp-button-primary (click)="save()" [isDisabled]="!topic.name" translation="NAVIGATION.SAVE"></gp-button-primary>
                    </div>
                </div>
            </div>
        }
    </div>
</div>
<div class="gp-container-normal">
    @if (!errorMessage && !topic) {
        <gp-spinner [visible]="true"></gp-spinner>
    }
    @if (errorMessage) {
        <div class="alert alert-danger">{{ errorMessage | translate }}</div>
    }

    @if (topic) {
        <div class="row">
            <form class="panel panel-default" (submit)="save()">
                <div class="row">
                    <label class="col-md-6">
                        <div class="col-form-label">{{ "TOPIC_EDIT.TOPIC" | translate }}</div>
                        <input class="form-control" type="text" [required]="true" [(ngModel)]="topic.name" autofocus="true" name="topicName" />
                    </label>
                </div>
            </form>
        </div>
    }
</div>
