import IDataset from "./IDataset";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import IDistribution from "./IDistribution";


@Injectable({ providedIn: "root" })
export class GeopackageService {

    public constructor(
        private readonly httpClient: HttpClient,
    ) {
    }

    public cantonMerge(dataset: IDataset, distribution: IDistribution): Observable<IDataset> {
        return this.httpClient.post<IDataset>("/api/geopackage/merge", { dataset, distribution });
    }

}
