import { Injectable } from "@angular/core";
import { HeaderExtension } from "@geolib/geoappbase-client";
import { LoginPanelComponent } from "./login-panel.component";

@Injectable({ providedIn: "root" })
export default class LoginPanelGeobusExtension extends HeaderExtension<LoginPanelComponent> {

    public isEnabled() {
        return true;
    }

    public get component(): typeof LoginPanelComponent {
        return LoginPanelComponent;
    }

}
