import IDataset from "../tracking/IDataset";
import { ITrackingDashboard } from "../../../common/tracking/ITrackingSpecificationList";
import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import CustomStore from "devextreme/data/custom_store";
import { LoadOptions } from "devextreme/data";
import { ArrayFilter } from "@geolib/geolib-client";
import { Options as DataSourceOptions } from "devextreme/data/data_source";


@Injectable({
    providedIn: "root",
})
export default class StatusMapping {

    public constructor(
        private readonly translateService: TranslateService,
    ) {
    }

    private getFieldName(prefix: string, name: string): string {
        return prefix ? `${prefix}.${name}` : name;
    }

    public getWorkflows(prefix: string = "") {
        return Object.freeze([{
            condition: (dataset: IDataset | ITrackingDashboard): boolean => Boolean(dataset.geoportalFtpUploadSucceeded === false),
            statusText: "Fehler beim FTP-Upload",
            filterExpression: [[this.getFieldName(prefix, "geoportalFtpUploadSucceeded"), "=", false]],
        }, {
            condition: (dataset: IDataset | ITrackingDashboard): boolean => Boolean(dataset.geoportalFtpUploadSucceeded === true),
            statusText: "File auf FTP hochgeladen",
            filterExpression: [[this.getFieldName(prefix, "geoportalFtpUploadSucceeded"), "=", true]],
        }, {
            condition: (dataset: IDataset | ITrackingDashboard): boolean => Boolean(dataset.approved === true),
            statusText: "Nachführung erfolgreich abgeschlossen",
            filterExpression: [[this.getFieldName(prefix, "approved"), "=", true]],
        }, {
            condition: (dataset: IDataset | ITrackingDashboard): boolean => Boolean(dataset.status === "invalidAndRejected"),
            statusText: "Nachführung abgebrochen: neue Nachführung notwendig",
            filterExpression: [
                [this.getFieldName(prefix, "status"), "=", "invalidAndRejected"],
                "or", [this.getFieldName(prefix, "approved"), "=", false],
                "or", [
                    [this.getFieldName(prefix, "approved"), "=", false],
                    "and", [this.getFieldName(prefix, "hasbeenrejected"), "=", false],
                ],
                "or", [
                    [this.getFieldName(prefix, "status"), "=", null],
                    "and", [this.getFieldName(prefix, "fileId"), "<>", null],
                    "and", [this.getFieldName(prefix, "fileId"), "<>", "undefined"],
                    "and", [this.getFieldName(prefix, "fileId"), "<>", ""],
                    "and", [this.getFieldName(prefix, "hasbeenrejected"), "=", true],
                ],
            ],
        }, {
            condition: (dataset: IDataset | ITrackingDashboard): boolean => Boolean(dataset.approved === false),
            statusText: "Nachführung abgebrochen: neue Nachführung notwendig",
        }, {
            condition: (dataset: IDataset | ITrackingDashboard): boolean => Boolean(dataset.approved === false && dataset.hasbeenrejected === false),
            statusText: "Nachführung abgebrochen: neue Nachführung notwendig",
        }, {
            condition: (dataset: IDataset | ITrackingDashboard): boolean => Boolean(dataset.status === "valid"),
            statusText: "Wartend auf fachliche Prüfung",
            filterExpression: [
                [
                    [this.getFieldName(prefix, "status"), "=", "valid"],
                    "or", [this.getFieldName(prefix, "status"), "=", "invalidAndAccepted"],
                ],
                "and", [
                    [this.getFieldName(prefix, "expertcheck"), "=", null],
                    "or", [this.getFieldName(prefix, "expertcheck"), "=", "undefined"],
                    "or", [this.getFieldName(prefix, "expertcheck"), "=", ""],
                ],
            ],
        }, {
            condition: (dataset: IDataset | ITrackingDashboard): boolean => Boolean(dataset.status === "invalid"),
            statusText: "Datei fehlerhaft: Wartend auf manuelle technische Prüfung",
            filterExpression: [
                [this.getFieldName(prefix, "status"), "=", "invalid"],
                "and", [
                    [this.getFieldName(prefix, "manualApproveDate"), "=", null],
                    "or", [this.getFieldName(prefix, "manualApproveDate"), "=", "undefined"],
                    "or", [this.getFieldName(prefix, "manualApproveDate"), "=", ""],
                ],
                "and", [
                    [this.getFieldName(prefix, "expertcheck"), "=", null],
                    "or", [this.getFieldName(prefix, "expertcheck"), "=", "undefined"],
                    "or", [this.getFieldName(prefix, "expertcheck"), "=", ""],
                ],
            ],
        }, {
            condition: (dataset: IDataset | ITrackingDashboard): boolean => Boolean(dataset.status === "invalidAndAccepted"),
            statusText: "Wartend auf fachliche Prüfung",
        }, {
            condition: (dataset: IDataset | ITrackingDashboard): boolean => Boolean(dataset.status === null && !dataset.fileId),
            statusText: "Checkin fällig",
            filterExpression: [
                [this.getFieldName(prefix, "status"), "=", null],
                "and", [
                    [this.getFieldName(prefix, "fileId"), "=", null],
                    "or", [this.getFieldName(prefix, "fileId"), "=", "undefined"],
                    "or", [this.getFieldName(prefix, "fileId"), "=", ""],
                ],
            ],
        }, {
            condition: (dataset: IDataset | ITrackingDashboard): boolean => Boolean(dataset.status === null && dataset.fileId),
            statusText: "Technische Überprüfung",
            filterExpression: [
                [this.getFieldName(prefix, "status"), "=", null],
                "and", [this.getFieldName(prefix, "fileId"), "<>", null],
                "and", [this.getFieldName(prefix, "fileId"), "<>", "undefined"],
                "and", [this.getFieldName(prefix, "fileId"), "<>", ""],
            ],
        }, {
            condition: (dataset: IDataset | ITrackingDashboard): boolean => Boolean(
                dataset.status === null && dataset.fileId && dataset.hasbeenrejected === true),
            statusText: "Nachführung abgebrochen: neue Nachführung notwendig",
        }]);
    }

    public statusName(dataset: IDataset | ITrackingDashboard): string {
        const workflows = this.getWorkflows().map((workflow) => {
            return {
                ...workflow,
                status: workflow.condition(dataset),
            };
        });

        return this.getWorkflowStatusText(workflows);
    }

    // eslint-disable-next-line complexity
    public extendedStatusName(status: string | null, fileId: string, hasBeenRejected: boolean | null, approved: boolean | null,
        extendedTechnicalStatus: string | null, dataset: IDataset): string {

        const workflows = [{
            status: Boolean(dataset.geoportalFtpUploadSucceeded === false),
            statusText: "Fehler beim FTP-Upload",
        }, {
            status: Boolean(dataset.geoportalFtpUploadSucceeded === true),
            statusText: "File auf FTP hochgeladen",
        }, {
            status: Boolean(status === "valid" && extendedTechnicalStatus === "invalidAndRejected"),
            statusText: "Nachführung abgebrochen: neue Nachführung notwendig",
        }, {
            status: Boolean(status === "valid" && extendedTechnicalStatus === null),
            statusText: "Wartend auf erweiterte technische Prüfung",
        }, {
            status: Boolean(status === "invalidAndAccepted" && extendedTechnicalStatus === null),
            statusText: "Wartend auf erweiterte technische Prüfung",
        }, {
            status: Boolean(status === "invalidAndAccepted" && extendedTechnicalStatus !== null && approved === null),
            statusText: "Wartend auf fachliche Prüfung",
        }, {
            status: Boolean(status === "valid" && extendedTechnicalStatus !== null && approved === true),
            statusText: "Nachführung erfolgreich abgeschlossen",
        }, {
            status: Boolean(status === "valid" && extendedTechnicalStatus !== null && approved === null),
            statusText: "Wartend auf fachliche Prüfung",
        }, {
            status: Boolean(status === null && fileId && approved === null && hasBeenRejected === false),
            statusText: "Technische Überprüfung",
        }, {
            status: Boolean(status === null && fileId && approved === null && hasBeenRejected === true),
            statusText: "Technische Überprüfung",
        }, {
            status: Boolean(status === "invalid" && fileId),
            statusText: "Datei fehlerhaft: Wartend auf manuelle technische Prüfung",
        }, {
            status: Boolean(status === "invalidAndAccepted" && fileId && approved === true && extendedTechnicalStatus === "valid"),
            statusText: "Nachführung erfolgreich abgeschlossen",
        }, {
            status: Boolean(status === "invalidAndRejected" && fileId && approved === false && extendedTechnicalStatus === null),
            statusText: "Nachführung abgebrochen: neue Nachführung notwendig",
        }, {
            status: Boolean(status === "invalidAndAccepted" && fileId && approved === false && extendedTechnicalStatus === "invalidAndRejected"),
            statusText: "Nachführung abgebrochen: neue Nachführung notwendig",
        }, {
            status: Boolean(status === "invalidAndAccepted" && fileId && approved === false && extendedTechnicalStatus === "valid" &&
                hasBeenRejected === true),
            statusText: "Nachführung abgebrochen: neue Nachführung notwendig",
        }, {
            status: Boolean(!status && !fileId && !approved && !extendedTechnicalStatus &&
                hasBeenRejected === true),
            statusText: "Checkin fällig",
        },
        ];

        return this.getWorkflowStatusText(workflows);
    }

    public technicalStatusName(status: string | null): string {
        const workflows = [{
            status: status === "valid",
            statusText: "Datei fehlerfrei",
        }, {
            status: status === "invalid",
            statusText: "Datei fehlerhaft",
        }, {
            status: status === null,
            statusText: "",
        }, {
            status: status === "invalidAndRejected",
            statusText: "Datei fehlerhaft: manuell abgelehnt",
        }, {
            status: status === "invalidAndAccepted",
            statusText: "Datei fehlerhaft: manuell angenommen",
        },
        ];

        return this.getWorkflowStatusText(workflows);
    }

    public expertCheckStatusName(hasBeenRejected: boolean | null, approved: boolean | null, extendedTechnicalStatus: string): string {
        const workflows = [{
            status: approved === true,
            statusText: "In Ordnung",
        }, {
            status: approved === null,
            statusText: "",
        }, {
            status: approved === false,
            statusText: "Nicht in Ordnung",
        }, {
            status: extendedTechnicalStatus === "invalidAndRejected",
            statusText: "Nachführung abgebrochen: neue Nachführung notwendig",
        }, {
            status: hasBeenRejected === true && approved !== null || extendedTechnicalStatus === "invalidAndRejected",
            statusText: "Nachführung abgebrochen: neue Nachführung notwendig",
        },
        ];

        return this.getWorkflowStatusText(workflows);
    }

    private getWorkflowStatusText(workflows: Array<{ status: boolean; statusText: string }>): string {
        const workflow = workflows.find((workflow) => workflow.status);
        return workflow ? workflow.statusText : "Nachführung abgebrochen: neue Nachführung notwendig";
    }

    public evaluateStyleClasses(data: IDataset): string {
        if (data.isValid) {
            return `${data.cellClass ?? ""} dx-icon-isnotblank status`;
        } else {
            return `${data.cellClass ?? ""} dx-icon-isblank status`;
        }
    }

    public addCustomTooltip(data: IDataset): string {
        const mapCellClass = {
            ["current-checkedout-dataset"]: this.translateService.instant("DATASET_LIST.TOOLTIP.CHECKED_OUT"),
            ["current-valid-dataset"]: this.translateService.instant("DATASET_LIST.TOOLTIP.VALID"),
            ["geoportal-publish-succeeded"]: this.translateService.instant("DATASET_LIST.TOOLTIP.GEOPORTAL"),
            ["oereb-publish-succeeded"]: this.translateService.instant("DATASET_LIST.TOOLTIP.ÖREB"),
        };

        const mapExpertCheck = {
            ["In Ordnung"]: this.translateService.instant("DATASET_LIST.TOOLTIP.FP_OKAY"),
            ["Nicht in Ordnung"]: this.translateService.instant("DATASET_LIST.TOOLTIP.FP_NOT_OKAY"),
        };

        return mapCellClass[data.cellClass] ?? mapExpertCheck[data.expertcheck] ?? this.translateService.instant("DATASET_LIST.TOOLTIP.UNKNOWN");
    }

    public getStatusDataSource(prefix: string = ""): DataSourceOptions {
        return {
            store: new CustomStore({
                load: (options: LoadOptions) => {
                    const status = this.getWorkflows(prefix).filter((status) => status.filterExpression)
                        .map((status) => ({ text: status.statusText, value: status.filterExpression }));
                    return status.filter((item) => ArrayFilter.searchInProperties(["text"], item, options.searchValue));
                },
                byKey: (item: { text: string }) => {
                    const foundItem = this.getWorkflows(prefix).find((workflow) => workflow.statusText === item.text);

                    if (foundItem) {
                        return Promise.resolve({ text: foundItem.statusText, value: foundItem.filterExpression });
                    }

                    throw new Error("Item not found");
                },
            }),
            sort: "text",
        };
    }

}
