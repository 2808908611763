import { ClientSessionStorage, IUser, LocalStorage, LoginService } from "@geolib/geoappbase-client";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { AuthenticationService } from "./AuthenticationService";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";


export type EventEmitterCallback = (...args: unknown[]) => void;

@Injectable({ providedIn: "root" })
export class GeobusLoginService extends LoginService {

    public constructor(
        httpClient: HttpClient,
        clientSessionStorage: ClientSessionStorage,
        localStorage: LocalStorage,
        private readonly authenticationService: AuthenticationService,
    ) {
        super(httpClient, clientSessionStorage, localStorage);
    }

    public getCurrentUser(): Observable<IUser | undefined> {
        return this.authenticationService.getUser().pipe(
            map((response) => {
                return {
                    id: response.user.id as unknown as number,
                    username: response.user.username,
                    passwordNeverExpires: true,
                } as IUser;
            }),
        );
    }

    /**
     * @deprecated Use `observeIsLoggedIn` instead
     */
    public onLogin(listener: EventEmitterCallback): void {
        this.authenticationService.onLogin(listener);
    }

    /**
     * @deprecated Use `observeIsLoggedIn` instead
     */
    public onLogout(listener: EventEmitterCallback): void {
        this.authenticationService.onLogout(listener);
    }

}
